import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {getFileFromBinaryData} from '../../../../utils/utils';
import PreviewImageSelect from './previewImageSelect/previewImageSelect';
import {getRandomDefaultImage} from '../../shared/blogShared';

class BlogSaveDialog extends React.Component {
    title = 'Article content';

    constructor(props) {
        super(props);
        const isTitleExist = !!props.article.title;
        const isCategoryExist = props.article.contentMetadata && props.article.contentMetadata.category;
        const isPreviewTextExist = !!props.article.previewText;
        let targetPreviewImage = this.getPreviewImage(props.article);
        this.state = {
            title: isTitleExist ? props.article.title : '',
            category: isCategoryExist ? props.article.contentMetadata.category : '',
            previewImage: targetPreviewImage ? targetPreviewImage : null,
            previewText: isPreviewTextExist ? props.article.previewText : '',
        };
    }

    getPreviewImage = (rawImage) => {
        if (rawImage.contentBinaryData && rawImage.contentBinaryData.data) {
            if (rawImage.contentBinaryData.data.name && typeof rawImage.contentBinaryData.data.name == 'string') {
                return rawImage.contentBinaryData.data;
            } else {
                return getFileFromBinaryData(rawImage.contentBinaryData);
            }
        }
        return null;
    };

    onChangeElement = (name, value) => this.setState({[name]: value});

    onSave = () => {
        const {previewImage} = this.state;
        if (!previewImage) {
            getRandomDefaultImage((image) => {
                this.savePost(image);
            });
            return;
        }
        this.savePost(previewImage);
    };

    savePost = (previewImage) => {
        const {title, category, previewText} = this.state;
        const {onClose, onSave} = this.props;
        onClose();
        onSave({title, category, previewImage, previewText});
    }

    render() {
        const {show, onClose} = this.props;
        return (
            <Dialog
                open={show}
                onClose={onClose}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
            >
                <DialogTitle id='alert-dialog-title'>
                    <p className='text-table-head'>{this.title}</p>
                </DialogTitle>
                {this.renderContent()}
                {this.renderActions()}
            </Dialog>
        );
    }

    renderContent = () => {
        const {
            title,
            category,
            previewText,
            previewImage,
        } = this.state;
        return (
            <DialogContent>
                <p className='text-24 mt-3 mb-1'>Title</p>
                <input
                    className='input-2'
                    type='text'
                    value={title}
                    onChange={event => this.onChangeElement('title', event.target.value)}
                />
                <p className='text-24 mt-3 mb-1'>Category</p>
                <input
                    className='input-2'
                    type='text'
                    value={category}
                    onChange={event => this.onChangeElement('category', event.target.value)}
                />
                <p className='text-24 mt-3 mb-1'>Preview Image</p>
                <PreviewImageSelect
                    image={previewImage}
                    onChangeElement={this.onChangeElement}
                />
                <p className='text-24 mt-4 mb-1'>Preview Text</p>
                <textarea
                    className='text-area-3'
                    value={previewText}
                    onChange={event => this.onChangeElement('previewText', event.target.value)}
                />
            </DialogContent>
        );
    };

    renderActions = () => {
        return (
            <DialogActions>
                <Button onClick={this.props.onClose} color='inherit'>
                    <p className='text-24'>Cancel</p>
                </Button>
                <Button onClick={this.onSave} color='inherit'>
                    <p className='text-24'>Save</p>
                </Button>
            </DialogActions>
        );
    };
}

export default BlogSaveDialog;