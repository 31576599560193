module.exports = {
    web: {
        api: {
            url: process.env.API_URL || window.env.API_URL,
            clientTimeout: process.env.CLIENT_TIMEOUT || window.env.CLIENT_TIMEOUT || 60000,  // [millis] default: 1 min.
            blogAutoSave: process.env.BLOG_AUTOSAVE || window.env.BLOG_AUTOSAVE || 120, // [seconds] default: 2 min.
        },
        env: {
            name: process.env.ENV_NAME || window.env.ENV_NAME || 'default',
        },
        googleAnalytics: {
            measurementId: process.env.MEASUREMENT_ID || window.env.MEASUREMENT_ID || null,
        },
        recaptchaV3: {
            siteKey: process.env.RECAPTCHA_SITE_KEY || window.env.RECAPTCHA_SITE_KEY || null,
        },
    },
};
