import FacebookLogo from '../assets/Social Network-Facebook.png';
import FacebookLogoYellow from '../assets/Social Network-Facebook ON.png';
import TwitterLogo from '../assets/Social Network-Twitter.png';
import TwitterLogoYellow from '../assets/Social Network-Twitter ON.png';
import LinkedInLogo from '../assets/Social Network-Linkedin.png';
import LinkedInLogoYellow from '../assets/Social Network-Linkedin ON.png';
import InstagramLogo from '../assets/Social Network-Instagram.png';
import InstagramLogoYellow from '../assets/Social Network-Instagram ON.png';


export const companyInfo = {
    links: [
        {
            show: true,
            link: 'https://www.linkedin.com/company/srm-cz/',
            icon: LinkedInLogo,
            iconHover: LinkedInLogoYellow,
        },
        {
            show: false,
            link: 'https://www.facebook.com',
            icon: FacebookLogo,
            iconHover: FacebookLogoYellow,
        },
        {
            show: false,
            link: 'https://www.twitter.com',
            icon: TwitterLogo,
            iconHover: TwitterLogoYellow,
        },
        {
            show: false,
            link: 'https://www.instagram.com',
            icon: InstagramLogo,
            iconHover: InstagramLogoYellow,
        },
    ],
};
