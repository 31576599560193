import React from 'react';
import Grid from '@material-ui/core/Grid';
import FirstBlock from './firstBlock/firstBlock';
import SecondBlock from './secondBlock/secondBlock';
import Divider from '../divider/divider';
import FooterButtons from './footerButtons/footerButtons';
import {sendEmail} from '../../utils/email/sendEmail';
import i18n from 'i18next';

// INFO:
// simple footer component, that represent main information about website
//
// Properties:
// texts - object that contain all texts in footer
// logo - set the company logo
// links - company socialMedia
class Footer extends React.Component {
    dividerBreakpoint = 1280;
    mobileBreakpoint = 600;

    state = {
        screenWidth: window.innerWidth,
    };

    isMobileWidth = () => this.state.screenWidth < this.mobileBreakpoint;

    showDivider = () => this.state.screenWidth >= this.dividerBreakpoint;

    onSendNewsletter = (email, cb) => {
        const {texts} = this.props;
        const text = texts.newsletter;
        const formData = {
            title: text.title,
            name: email,
            email: email,
            message: `${text.emailMessage}, Language: ${i18n.language}`,
        };
        sendEmail(formData, cb);
    };

    render() {
        const {texts, links} = this.props;
        const isDividerVisible = this.showDivider();
        const newsletterText = texts.newsletter;
        return (
            <footer className='footer-block p-4'>
                <Grid container>
                    <FirstBlock
                        isDividerVisible={isDividerVisible}
                        title={newsletterText.title}
                        subTitle={newsletterText.subTitle}
                        successMessage={newsletterText.success}
                        unsuccessfulMessage={newsletterText.unsuccessful}
                        badEmailMessage={newsletterText.badEmail}
                        onSendNewsletter={this.onSendNewsletter}
                        bottomLinks={this.renderBottomLinks}
                        {...this.props}
                    />
                    {isDividerVisible && this.renderDivider()}
                    <SecondBlock
                        isMobileWidth={this.isMobileWidth()}
                        isDividerVisible={isDividerVisible}
                        companyNameText={texts.address.companyName}
                        whoWeAreText={texts.whoWeAre}
                        addressText={texts.address}
                        icText={texts.IC}
                        dicText={texts.DIC}
                        contactsText={texts.contacts}
                        links={links}
                    />
                </Grid>
            </footer>
        );
    }

    renderDivider = () => (
        <Grid container item justify='center' lg={2}>
            <Divider longer/>
        </Grid>
    );

    renderBottomLinks = () => {
        const {texts} = this.props;
        const isDividerVisible = this.showDivider();
        return (
            <Grid container className='footer-buttons'>
                <FooterButtons showStatic={isDividerVisible} buttons={texts.buttons}/>
            </Grid>
        );
    };

    componentDidMount() {
        window.addEventListener('resize',
            () => this.setState({screenWidth: window.innerWidth}));
    }
}

export default Footer;
