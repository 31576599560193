import React from 'react';

class ReferenceUI extends React.Component {
    render() {
        const {src} = this.props;
        return (
            <div className='text-center'>
                <img style={{width: '70%', height: '70%'}} src={src} alt=''/>
            </div>
        );
    }
}

export default ReferenceUI;