import React from 'react';
import {Grid} from '@material-ui/core';

// INFO:
// responsive slider,which calculates the width according to the total value and current
//
// Properties:
// total - total of slides
// current - current slide
class InquirySlider extends React.Component {

    get activeWidth() {
        const {total, current} = this.props;
        return (current / total) * 100;
    }

    get noActiveWidth() {
        return 100 - this.activeWidth;
    }

    render() {
        const targetActiveOpacity = {
            width: `${this.activeWidth}%`,
            height: '5px',
            backgroundColor: '#304659',
        };
        const targetNoActiveOpacity = {
            width: `${this.noActiveWidth}%`,
            height: '5px',
            opacity: '0.35',
            backgroundColor: '#b4b9d9',
        };
        return (
            <Grid container item xs={12} direction='row'>
                <Grid item style={targetActiveOpacity}/>
                <Grid item style={targetNoActiveOpacity}/>
            </Grid>
        );
    }
}

export default InquirySlider;