import React from 'react';
import {formatUTCDate} from '../../../../utils/utils';

class InfoText extends React.Component {
    render() {
        const {article} = this.props;
        const title = article.title ? article.title : 'Unnamed';
        const fontSize = '14px';
        const showLastUpdated = article.contentMetadata && article.contentMetadata.lastUpdated;
        return (
            <div className='row flex mb-n3'>
                <p style={{fontWeight: 600, fontSize}}>Title:</p>
                <p className='ml-2' style={{fontSize}}>{title}</p>
                {showLastUpdated && <React.Fragment>
                    <p style={{fontWeight: 600, fontSize, marginLeft: '20px'}}>Last updated:</p>
                    <p className='ml-2' style={{fontSize}}>{formatUTCDate(article.contentMetadata.lastUpdated)}</p>
                </React.Fragment>}
            </div>
        );
    }
}

export default InfoText;