import React from 'react';
import {useNode, useEditor} from '@craftjs/core';
import {ImageSettings} from './imageSettings';
import Default from '../../../../../assets/default.png';
import {Resizer} from "../resizer/resizer";

const defaultProps = {
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    image: Default,
    fillSpace: 'no',
    padding: ['0', '0', '0', '0'],
    margin: ['0', '0', '0', '0'],
    background: 'transparent',
    shadow: 0,
    radius: 0,
    width: 'auto',
    height: 'auto',
};

export const Image = (props: any) => {
    props = {
        ...defaultProps,
        ...props,
    };
    const {
        image,
        flexDirection,
        alignItems,
        justifyContent,
        fillSpace,
        background,
        padding,
        margin,
        shadow,
        radius,
    } = props;
    const {
        connectors: {connect, drag},
    } = useNode();
    const {enabled} = useEditor((state) => ({
        enabled: state.options.enabled,
    }));
    return (
        <Resizer
            propKey={{width: 'width', height: 'height'}}
            style={{
                justifyContent,
                flexDirection,
                alignItems,
                background: `rgba(${Object.values(background)})`,
                padding: `${padding[0]}px ${padding[1]}px ${padding[2]}px ${padding[3]}px`,
                margin: `${margin[0]}px ${margin[1]}px ${margin[2]}px ${margin[3]}px`,
                boxShadow:
                    shadow === 0
                        ? 'none'
                        : `0px 3px 100px ${shadow}px rgba(0, 0, 0, 0.13)`,
                borderRadius: `${radius}px`,
                flex: fillSpace === 'yes' ? 1 : 'unset',
                width: '100px',
                height: '100px'
            }}
        >
            <img
                ref={dom => connect(drag(dom))}
                contentEditable={enabled}
                src={image}
                className='img-fluid'
                alt=''
            />
        </Resizer>
    );
};

Image.craft = {
    displayName: 'Image',
    props: {
        ...defaultProps,
    },
    rules: {
        canDrag: () => false,
    },
    related: {
        toolbar: ImageSettings,
    },
};
