import React from 'react';
import CookieConsent from 'react-cookie-consent';
import {Redirect} from 'react-router';
import {withTranslation} from 'react-i18next';
import {config} from '../../config/config';

class CookiesConsentBannerInternal extends React.Component {

    state = {
        redirect: false,
    };

    cookiesPath = '/cookies';

    onDecline = () => {
        this.setState({redirect: true});
    };

    render() {
        const {t} = this.props;
        if (this.state.redirect) {
            return <Redirect to={this.cookiesPath}/>;
        }
        const expires = config.cookieConsentExpireDays;
        return (
            <CookieConsent
                cookieName='cookieStatus'
                containerClasses='cookies-banner'
                contentClasses='text-cookie'
                buttonText={t('cookies.acceptButton')}
                buttonClasses='cookie-accept-button'
                declineButtonText={t('cookies.moreInfoButton')}
                declineButtonClasses='cookie-decline-button'
                onDecline={this.onDecline}
                setDeclineCookie={false}
                expires={expires}
                enableDeclineButton
                disableButtonStyles
                disableStyles
            >
                {t('cookies.text')}
            </CookieConsent>
        );
    }
}

export const CookiesConsentBanner = withTranslation('global')(CookiesConsentBannerInternal);