import React from 'react';
import {getFileFromBinaryData} from '../../../../utils/utils';
import {Dialog, DialogTitle} from '@material-ui/core';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import LanguageSelector from '../../../languageSelector/languageSelector';

class ReferenceSaveDialog extends React.Component {
    constructor(props) {
        super(props);
        const reference = props.reference;
        const isLanguageExist = reference && reference.language;

        const isCompanyNameExist = reference && reference.companyName;
        const isCompanyWebUrlExist = reference && reference.companyWebUrl;
        const isClientNameExist = reference && reference.clientName;
        const isClientRoleExist = reference && reference.clientRole;
        const isReferenceTextExist = reference && reference.referenceText;

        const isCompanyLogoExist = reference && reference.referenceCompanyLogo;
        let targetCompanyLogo;
        if (isCompanyLogoExist && reference.referenceCompanyLogo.data) {
            const logo = reference.referenceCompanyLogo;
            if (logo.data.name && typeof logo.data.name === 'string') {
                targetCompanyLogo = logo.data;
            } else {
                const logoFromBinary = getFileFromBinaryData(logo);
                targetCompanyLogo = logoFromBinary ? logoFromBinary : null;
            }
        }
        this.state = {
            language: isLanguageExist
                ? reference.language
                : props.languages[0],
            companyName: isCompanyNameExist
                ? reference.companyName
                : '',
            companyWebUrl: isCompanyWebUrlExist
                ? reference.companyWebUrl
                : '',
            clientName: isClientNameExist
                ? reference.clientName
                : '',
            clientRole: isClientRoleExist
                ? reference.clientRole
                : '',
            referenceText: isReferenceTextExist
                ? reference.referenceText
                : '',
            companyLogo: isCompanyLogoExist
                ? targetCompanyLogo
                : null,
        };
    }

    title = 'Reference';

    onChangeElement = (name, value) => this.setState({[name]: value});

    onSave = async () => {
        const {language, companyName, companyWebUrl, clientName, clientRole, referenceText, companyLogo} = this.state;
        this.props.onClose && this.props.onClose();
        const saveData = {
            language,
            companyName,
            companyWebUrl,
            clientName,
            clientRole,
            referenceText,
            companyLogo,
        };
        this.props.onSave && await this.props.onSave(saveData);
    };

    render() {
        const {show, onClose} = this.props;
        return (
            <Dialog
                open={show}
                onClose={onClose}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
            >
                <DialogTitle id='alert-dialog-title'>
                    <p className='text-table-head'>{this.title}</p>
                </DialogTitle>
                {this.renderDialogContent()}
                {this.renderDialogActions()}
            </Dialog>
        );
    }

    renderDialogContent = () => {
        const {language, companyName, companyWebUrl, clientName, clientRole, referenceText, companyLogo} = this.state;
        const {reference} = this.props;
        const logoSize = {
            width: '7em',
        };
        const showLanguageSelect = (reference && !reference.language) || !reference;
        return (
            <DialogContent>
                {showLanguageSelect
                && <div className='flex justify-between'>
                    <p className='text-24 mt-3 mb-1'>Language</p>
                    <LanguageSelector
                        selectedLanguage={language}
                        languages={this.props.languages.filter((lng) => lng !== language)}
                        onChange={(lng) => this.onChangeElement('language', lng)}
                        style={{marginTop: '15px', marginBottom: '15px'}}
                        dropdownMenuStyle={{transform: 'none', top: 'unset'}}
                    />
                </div>}
                {showLanguageSelect && <div className='border-bottom border-black'/>}
                <p className='text-24 mt-3 mb-1'>Company Name</p>
                <input
                    className='input-4'
                    type='text'
                    value={companyName}
                    onChange={event => this.onChangeElement('companyName', event.target.value)}
                />
                <p className='text-24 mt-3 mb-1'>Company Web Url</p>
                <input
                    className='input-4'
                    type='text'
                    value={companyWebUrl}
                    onChange={event => this.onChangeElement('companyWebUrl', event.target.value)}
                />
                <p className='text-24 mt-3 mb-1'>Client Name</p>
                <input
                    className='input-4'
                    type='text'
                    value={clientName}
                    onChange={event => this.onChangeElement('clientName', event.target.value)}
                />
                <p className='text-24 mt-3 mb-1'>Client Role</p>
                <input
                    className='input-4'
                    type='text'
                    value={clientRole}
                    onChange={event => this.onChangeElement('clientRole', event.target.value)}
                />
                <p className='text-24 mt-3 mb-1'>Reference Text</p>
                <textarea
                    className='text-area-4'
                    value={referenceText}
                    onChange={event => this.onChangeElement('referenceText', event.target.value)}
                />
                <p className='text-24 mt-3 mb-1'>Company Logo</p>
                <input
                    className='input-4 mb-2'
                    type='file'
                    onChange={event => this.onChangeElement('companyLogo', event.target.files[0])}
                    style={{paddingTop: '8px'}}
                    accept='.png, .jpeg, .jpg, .gif, .svg, .mp4'
                />
                {companyLogo &&
                <img src={URL.createObjectURL(companyLogo)} alt='' style={logoSize}/>}
            </DialogContent>
        );
    };

    renderDialogActions = () => {
        return (
            <DialogActions>
                <Button onClick={() => this.props.onClose && this.props.onClose()} color='inherit'>
                    <p className='text-24'>Cancel</p>
                </Button>
                <Button onClick={this.onSave} color='inherit'>
                    <p className='text-24'>Save</p>
                </Button>
            </DialogActions>
        );
    };
}

export default ReferenceSaveDialog;