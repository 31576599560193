import React from 'react';
import SliderRight from '../../../assets/ikona-slider-right.png';

class RightArrow extends React.Component {
    render() {
        const {onClick} = this.props;
        return (
            <div className='reference-arrow reference-arrow-right'>
                <img src={SliderRight} alt='right' onClick={onClick}/>
            </div>
        );
    }
}

export default RightArrow;