import React from 'react';
import {Grid} from '@material-ui/core';
import {ContactForm} from '../../../components/contactForm/contactForm';
import {withTranslation} from 'react-i18next';
import {sendEmail} from '../../../utils/email/sendEmail';

class FifthBlockInternal extends React.Component {

    onSubmitContactForm = (formData, cb) => {
        const newFormData = {
            title: 'Contact Form',
            ...formData,
        };
        sendEmail(newFormData, cb);
    };

    render() {
        return (
            <Grid container item className='background-2 p-4' justify='center'>
                {this.renderContactForm()}
            </Grid>
        );
    }

    renderContactForm = () => {
        const {t} = this.props;
        return (
            <ContactForm
                title={t('fifthBlock.title')}
                subTitle={t('fifthBlock.subTitle')}
                submitLabel={t('fifthBlock.submitLabel')}
                onSubmit={this.onSubmitContactForm}
                className='block-3'
            />
        );
    };
}

export const FifthBlock = withTranslation('ourService')(FifthBlockInternal);