import React from 'react';
import icon from '../../../assets/plus.svg';

class InquiryInput extends React.Component {

    onChange = (event) => this.props.onChange && this.props.onChange(event.target);

    render() {
        if (this.props.showIcon) {
            return this.renderIcon();
        }
        return (
            <input
                className={this.props.className || 'inquiry-input'}
                onChange={this.onChange}
                placeholder={this.props.placeholder}
                value={this.props.value}
                type={this.props.type || 'text'}
                required={this.props.required}
            />
        );
    }

    renderIcon = () => {
        return (
            <div className='inquiry-input-2-icon-wrapper'>
                <input
                    id='input-2'
                    className='input-2'
                    onChange={this.onChange}
                    value={this.props.value}
                    placeholder={this.props.placeholder}
                    type={this.props.type || 'text'}
                    required={this.props.required}
                />
                <img
                    src={icon}
                    className='inquiry-input-2-icon'
                    onClick={this.props.onIconClick}
                    onChange={this.onChange}
                    alt=''
                />
            </div>
        );
    };
}

export default InquiryInput;