import React from 'react';
import forwardOn from '../../../assets/form-forward.png';
import forwardOff from '../../../assets/form-forward-OFF.png';
import backwardOn from '../../../assets/form-backward.png';
import backwardOff from '../../../assets/form-backward-OFF.png';

// INFO:
// simple buttons (previous and next) which controls inquiry form
//
// Properties:
// onPreviousClick - function, trigger when click on previous button
// onNextClick - function, trigger when click on previous button
class InquiryNavigationButtons extends React.Component {
    render() {
        return (
            <div className='row inquiry-navigation-buttons-wrapper'>
                {this.renderPrevious()}
                {this.renderNext()}
            </div>
        );
    }

    renderPrevious = () => {
        const {onPreviousClick} = this.props;
        return (
            <button
                className='navigation-button mr-3'
                onClick={onPreviousClick}
            >
                <img
                    src={onPreviousClick ? backwardOn : backwardOff}
                    alt='backward'
                />
            </button>
        );
    };

    renderNext = () => {
        const {onNextClick} = this.props;
        return (
            <button
                className='navigation-button ml-3'
                type='submit'
            >
                <img
                    src={onNextClick ? forwardOn : forwardOff}
                    alt='forward'
                />
            </button>
        );
    };
}

export default InquiryNavigationButtons;