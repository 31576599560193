import React from 'react';
import ShareIcon from '../../../../assets/share-button.png';
import FacebookIcon from '../../../../assets/Social Network-Facebook@2x.png';
import TwitterIcon from '../../../../assets/Social Network-Twitter@2x.png';
import LinkedinIcon from '../../../../assets/Social Network-Linkedin@2x.png';
import SocialLink from './socialLink';

const exampleUrl = 'https://www.srm-clouds.cz/blog/article/pet-nejdulezitejsich-trendu-ve-svete-cloudu-(2021)';
const url = window.location.href;

class ShareButton extends React.Component {

    // INFO:
    // in local environment,
    // will be path url like this http://localhost:3000/blog/article/pet-nejdulezitejsich-trendu-ve-svete-cloudu-(2021)
    // and it does not work, sharing will work only in production environment
    useExampleUrl = true;

    get hrefUrl() {
        if (this.useExampleUrl) {
            return exampleUrl;
        }
        return url;
    }

    render() {
        return (
            <div className='dropdown mt-n4'>
                <button id='shareDropdown' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'>
                    <img src={ShareIcon} alt=''/>
                </button>
                {this.renderMenu()}
            </div>
        );
    }

    renderMenu = () => {
        const {title} = this.props;
        const url = this.hrefUrl;
        return (
            <div className='dropdown-menu share-dropdown shadow' aria-labelledby='shareDropdown'>
                <a
                    className='text-decoration-none dropdown-item share-dropdown-item'
                    href={`https://www.facebook.com/sharer/sharer.php?u=${url}&quote=${title}`}
                    target='_blank'
                    rel='noreferrer'
                >
                    <SocialLink src={FacebookIcon} alt='facebook-icon' name='Facebook'/>
                </a>
                <a
                    className='text-decoration-none dropdown-item share-dropdown-item'
                    href={`https://twitter.com/intent/tweet?url=${url}&text=${title}`}
                    target='_blank'
                    rel='noreferrer'
                >
                    <SocialLink src={TwitterIcon} alt='twitter-icon' name='Twitter'/>
                </a>
                <a
                    className='text-decoration-none dropdown-item share-dropdown-item'
                    href={`https://www.linkedin.com/shareArticle?url=${url}&title=${title}`}
                    target='_blank'
                    rel='noreferrer'
                >
                    <SocialLink src={LinkedinIcon} alt='linkedin-icon' name='Linkedin'/>
                </a>
            </div>
        );
    };
}

export default ShareButton;
