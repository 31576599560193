import React from 'react';
import {Dialog, DialogContent, DialogTitle} from '@material-ui/core';
import {getFileFromBinaryData} from '../../../../utils/utils';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

class CaseStudyRemoveDialog extends React.Component {
    title = 'Remove Case Study';

    getPreviewImage = () => {
        const caseStudy = this.props.selectedCaseStudy;
        const isPreviewImageExist = caseStudy && caseStudy.caseStudyPreviewImage;
        let targetPreviewImage;
        if (isPreviewImageExist && caseStudy.caseStudyPreviewImage.data) {
            const logo = caseStudy.caseStudyPreviewImage;
            if (logo.data.name && typeof logo.data.name === 'string') {
                targetPreviewImage = logo.data;
            } else {
                const logoFromBinary = getFileFromBinaryData(logo);
                if (logoFromBinary) {
                    targetPreviewImage = logoFromBinary;
                }
            }
        }
        return targetPreviewImage;
    };

    render() {
        const {show, onDisagree} = this.props;
        return (
            <Dialog
                open={show}
                onClose={onDisagree}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
            >
                <DialogTitle id='alert-dialog-title'>
                    <p className='text-table-head'>{this.title}</p>
                </DialogTitle>
                {this.renderDialogContent()}
                {this.renderDialogAction()}
            </Dialog>
        );
    }

    renderDialogContent = () => {
        const caseStudy = this.props.selectedCaseStudy;
        const previewImage = this.getPreviewImage();
        if (caseStudy && caseStudy.id) {
            return (
                <DialogContent>
                    <DialogContentText id='alert-dialog-description'>
                        {previewImage &&
                        <div className='mt-3'>
                            <p className='text-23'>Preview Image:</p>
                            <div className='company-logo-container'>
                                <img alt='' src={URL.createObjectURL(previewImage)} className='company-logo-img'/>
                            </div>
                        </div>}
                        {caseStudy.blogArticleUrl &&
                        <div className='mt-3'>
                            <p className='text-23'>Blog Article Url:</p>
                            <p className='text-24'>{caseStudy.blogArticleUrl}</p>
                        </div>}

                        {caseStudy.inquiryType &&
                        <div className='mt-3'>
                            <p className='text-23'>Inquiry Type:</p>
                            <p className='text-24'>{caseStudy.inquiryType}</p>
                        </div>}
                    </DialogContentText>
                </DialogContent>
            );
        }
        return null;
    };

    renderDialogAction = () => {
        const {onDisagree, onAgree} = this.props;
        return (
            <DialogActions>
                <Button onClick={onDisagree} autoFocus>
                    <p className='text-24'>Disagree</p>
                </Button>
                <Button onClick={onAgree}>
                    <p className='text-24'>Agree</p>
                </Button>
            </DialogActions>
        );
    };
}

export default CaseStudyRemoveDialog;