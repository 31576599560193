import React from 'react';

class SocialMedia extends React.Component {

    state = {
        isHover: false,
    };

    onMouseEnter = () => this.setState({isHover: true});

    onMouseLeave = () => this.setState({isHover: false});

    render() {
        const {isHover} = this.state;
        const {href, alt, icon, iconHover, style} = this.props;
        return (
            <a
                href={href}
                className={style}
                target='_blank'
                rel='noreferrer'
                onMouseEnter={this.onMouseEnter}
                onMouseLeave={this.onMouseLeave}
            >
                <img src={isHover ? iconHover : icon} alt={alt}/>
            </a>
        );
    }
}

export default SocialMedia;