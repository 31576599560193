import React from 'react';
import {withTranslation} from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import CookiesCS from './cookiesCS/cookiesCS';
import CookiesEN from './cookiesEN/cookiesEN';
import i18n from '../../i18n';
import Title from '../../components/title/title';

class CookiesInternal extends React.Component {
    render() {
        const language = i18n.language;
        return (
            <Grid container spacing={0} className='p-5' style={{marginBottom: '150px'}}>
                <Title text={this.props.t('welcome.title')}/>
                {language === 'cs' ? <CookiesCS {...this.props}/> : <CookiesEN {...this.props}/>}
            </Grid>
        );
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }
}

export const Cookies = withTranslation('cookies')(CookiesInternal);
