import React from 'react';
import Grid from '@material-ui/core/Grid';
import OurService from '../../../components/ourService/ourService';
import FirstPicture from '../../../assets/Ilustrace A1.png';
import SecondPicture from '../../../assets/Ilustrace A2.png';
import ThirdPicture from '../../../assets/Ilustrace A3.png';
import FourthPicture from '../../../assets/Ilustrace A4.png';
import OptimizationIcon from '../../../assets/ikona-optimalizace.png';
import MigrationIcon from '../../../assets/ikona-migrace.png';
import ProposalIcon from '../../../assets/ikona-navrh.png';
import AdviceIcon from '../../../assets/ikona-poradenstvi.png';
import {withTranslation} from 'react-i18next';

class ThirdBlockInternal extends React.Component {

    render() {
        return (
            <Grid container item className='background-1 p-4' xs={12}>
                {this.renderTitle()}
                {this.renderServices()}
            </Grid>
        );
    }

    renderTitle = () => {
        const {t} = this.props;
        return (
            <Grid container item xs={12} className='pb-5 mt-5'>
                <Grid item xs={12} className='pb-5'>
                    <p className='title-2 m-auto'>{t('thirdBlock.title')}</p>
                </Grid>
                <Grid item xs={12}>
                    <p className='sub-title m-auto'>{t('thirdBlock.subTitle')}</p>
                </Grid>
            </Grid>
        );
    };

    renderServices = () => {
        const {t} = this.props;
        const startWay = 'thirdBlock.texts.';
        return (
            <Grid container item xs={12}>
                <OurService
                    t={t}
                    image={FirstPicture}
                    icon={OptimizationIcon}
                    title={t(startWay + 'first.title')}
                    text={t(startWay + 'first.text')}
                    redirect='optimization'
                />
                <OurService
                    t={t}
                    image={SecondPicture}
                    icon={MigrationIcon}
                    title={t(startWay + 'second.title')}
                    text={t(startWay + 'second.text')}
                    redirect='migration'
                    reverse
                />
                <OurService
                    t={t}
                    image={ThirdPicture}
                    icon={ProposalIcon}
                    title={t(startWay + 'third.title')}
                    text={t(startWay + 'third.text')}
                    redirect='design'
                />
                <OurService
                    t={t}
                    image={FourthPicture}
                    icon={AdviceIcon}
                    title={t(startWay + 'fourth.title')}
                    text={t(startWay + 'fourth.text')}
                    redirect='advice'
                    reverse
                />
            </Grid>
        );
    };
}

export const ThirdBlock = withTranslation('ourService')(ThirdBlockInternal);