import React from 'react';
import ArticleBlock from '../articleBlock/articleBlock';

class BlogTableBody extends React.Component {
    render() {
        const {data} = this.props;
        return (
            <tbody>
            {data.map(this.renderArticleBlock).filter((item) => !!item)}
            </tbody>
        );
    }

    renderArticleBlock = (item, index) => {
        return (
            <ArticleBlock
                key={index.toString()}
                articles={item.articles}
                publicId={item.publicId}
                onAddNewLanguage={this.props.onAddNewLanguage}
                onRemoveArticle={this.props.onRemoveArticle}
                editArticleUrl={this.props.editArticleUrl}
                previewArticleUrl={this.props.previewArticleUrl}
            />
        );
    };
}

export default BlogTableBody;