import React from 'react';
import {Grid} from '@material-ui/core';
import InquirySlider from '../slider/slider';
import InquiryNavigationButtons from '../navigationButtons/navigationButtons';
import {withTranslation} from 'react-i18next';

// INFO:
// component that provides all the basic features for an inquiry form
// greetingText, introductoryText, questionTitle, slider, navigation buttons
//
// Properties:
// title - add title to form
// sliderSettings - object that contain, total and current values
// children - all other content in actual inquiry form
class InquiryFormInternal extends React.Component {
    widthBreakpoint = 750;

    state = {
        screenWidth: window.innerWidth,
    };

    get isScreenSmall() {
        return this.state.screenWidth < this.widthBreakpoint;
    }

    onSubmit = (event) => {
        event.preventDefault();
        this.props.onNextClick && this.props.onNextClick();
    };

    render() {
        const inquiryFormStyle = this.isScreenSmall
            ? 'inquiry-form inquiry-form-smaller'
            : 'inquiry-form';
        return (
            <Grid
                container
                item
                className={inquiryFormStyle}
            >
                <form onSubmit={this.onSubmit}>
                    {this.renderIntroductoryText()}
                    {this.props.children}
                    {this.renderSlider()}
                    {this.renderNavigationButtons()}
                </form>
            </Grid>
        );
    }

    renderIntroductoryText = () => {
        const {t} = this.props;
        const greetingTextStyle = this.isScreenSmall
            ? 'text-37 text-37-smaller'
            : 'text-37';
        const introductoryTextStyle = this.isScreenSmall
            ? 'text-38 text-38-smaller'
            : 'text-38';
        return (
            <Grid
                container
                item
                xs={12}
                direction='column'
            >
                <p className={greetingTextStyle}>{t('inquiryForm.greeting')}</p>
                <p className={introductoryTextStyle}>{t('inquiryForm.introductory')}</p>
                {this.renderQuestionTitle()}
            </Grid>
        );
    };

    renderQuestionTitle = () => {
        const questionTitleStyle = this.isScreenSmall
            ? 'text-39 text-39-smaller'
            : 'text-39';
        return <p className={questionTitleStyle}>{this.props.title}</p>;
    };

    renderSlider = () => {
        const {sliderSettings} = this.props;
        return <InquirySlider total={sliderSettings.total} current={sliderSettings.current}/>;
    };

    renderNavigationButtons = () => {
        return (
            <InquiryNavigationButtons {...this.props}/>
        );
    };

    componentDidMount() {
        window.addEventListener('resize',
            () => this.setState({screenWidth: window.innerWidth}));
    }

    componentWillUnmount() {
        window.removeEventListener('resize',
            () => this.setState({screenWidth: window.innerWidth}));
    }
}

export const InquiryForm = withTranslation('inquiry')(InquiryFormInternal);