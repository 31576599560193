import React from 'react';
import {useEditor} from '@craftjs/core';

export const Toolbar = () => {
    const {active, related} = useEditor((state) => ({
        active: state.events.selected,
        related:
            state.events.selected && state.nodes[state.events.selected].related,
    }));
    return (
        <div className='py-1 h-full'>
            {active && related.toolbar && React.createElement(related.toolbar)}
            {!active && (
                <div
                    className='px-5 py-2 flex flex-col items-center h-full justify-center text-center'
                    style={{color: 'rgba(0, 0, 0, 0.5607843137254902)'}}
                >
                    <h2 className='pb-1' style={{fontSize: '11px'}}>Click on a component to start editing.</h2>
                    <h2 style={{fontSize: '11px'}}>
                        You could also double click on the layers below to edit their names, like in Photoshop
                    </h2>
                </div>
            )}
        </div>
    );
};
