import MirekImage from '../assets/mirek.png';
import SandraImage from '../assets/sandra.png';
import RadimImage from '../assets/radim.png';

export const mirek = {
    image: MirekImage,
    socialMedia: [
        {
            href: 'https://www.linkedin.com/in/mirekingr/',
            type: 'linkedin',
        },
        {
            href: 'https://twitter.com/mirek_ingr',
            type: 'twitter',
        },
    ],

};
export const sandra = {
    image: SandraImage,
    socialMedia: [
        {
            href: 'https://www.linkedin.com/in/sandra-tilkeridisova-b0ba7b188/',
            type: 'linkedin',
        },
    ],
};

export const radim = {
    image: RadimImage,
    socialMedia: [
        {
            href: 'https://www.linkedin.com/in/radim-ciz-30359223/?originalSubdomain=cz',
            type: 'linkedin',
        },
    ],
};
