import React from 'react';
import BlueArrow from '../../assets/button-arrow-blue.png';
import {Link} from 'react-router-dom';

// INFO:
//when you click on the content trigger event
//
// Types:
// blue - button with blue background and white text
// gold - button with white background and blue text
// clear - button with white background and blue text and arrow, without border
//
// Properties:
// text - add text to button
// showArrow - when true, show arrow on right
class Button extends React.Component {

    render() {
        const {type} = this.props;
        if (type === 'blue') {
            return this.renderBlueButton();
        }
        if (type === 'gold') {
            return this.renderGoldButton();
        }
        if (type === 'clear') {
            return this.renderClearButton();
        }
        return null;
    }

    renderBlueButton = () => {
        const {bigger, className} = this.props;
        const style = !bigger ? 'blue-button' : 'blue-button blue-button-bigger';
        return (
            <button type='button' className={`${style} ${className}`} onClick={this.props.onClick}>
                <p className='blue-button-text m-auto'>{this.props.text}</p>
            </button>
        );
    };

    renderGoldButton = () => {
        const {showArrow, text, className, to} = this.props;
        if (showArrow) {
            return (
                <Link
                    to={to || ''}
                    className={`gold-button-2 ${className}`}
                    onMouseEnter={this.onMouseEnter}
                    onMouseLeave={this.onMouseLeave}
                    onClick={this.props.onClick}
                >
                    {text}
                    <img src={BlueArrow} alt='blue_arrow' className='gold-button-img'/>
                </Link>
            );
        }
        return (
            <Link to={to || ''}>
                <button {...this.props} type='button' className='gold-button'>
                    <p className='gold-button-text m-auto'>{text}</p>
                </button>
            </Link>
        );
    };

    renderClearButton = () => {
        const {showArrow, text} = this.props;
        if (showArrow) {
            return (
                <button type='button' className='clear-button' onClick={this.props.onClick}>
                    <p className='clear-button-text'>
                        {text}
                        <img src={BlueArrow} alt='clear_arrow' className='ml-2 clear-button-arrow'/>
                    </p>
                </button>
            );
        }
        return (
            <button type='button' className='clear-button' onClick={this.props.onClick}>
                <p className='gold-button-text m-auto'>{text}</p>
            </button>
        );
    };
}

export default Button;