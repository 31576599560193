import React, {Fragment} from 'react';
import ShareButton from './shareButton/shareButton';

class ArticleHead extends React.Component {
    render() {
        const {title} = this.props;
        return (
            <Fragment>
                {title && <h2 className='article-head-title'>{title}</h2>}
                <div className='w-100 d-flex align-items-center justify-content-center'>
                    {this.renderDateReadTime()}
                </div>
                <div className='d-flex align-items-center justify-content-center mb-5'>
                    <ShareButton title={title}/>
                </div>
            </Fragment>
        );
    }

    renderDateReadTime = () => {
        const {date, readTime} = this.props;
        if (date && readTime) {
            return <h2 className='article-head-date-read-time-text'>{`${date} • ${readTime}`}</h2>;
        }
        if (date) {
            return <h2 className='article-head-date-read-time-text'>{date}</h2>;
        }
        if (readTime) {
            return <h2 className='article-head-date-read-time-text'>{readTime}</h2>;
        }
        return null;
    };
}

export default ArticleHead;
