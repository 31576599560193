// INFO:
// // environment variables are loaded in config/web.js
export const config = {
    // default language is first
    allowedReferenceLanguages: ['CZ', 'EN'],
    allowedBlogLanguages: ['CZ', 'EN'],
    cookieConsentExpireDays: 365,
    inputLength: {
        name: 64,
        email: 128,
        message: 1000,
    },
    features: {
        ENTranslation: {
            allow: true,
        },
        blog: {
            allow: true,
            autoSaveSeconds: 30,
        },
        references: {
            allow: true,
        },
        legalDocuments: {
            privacyPolicy: {
                allow: true,
            },
            termsAndConditions: {
                allow: true,
            },
            certification: {
                allow: false,
            },
            cookiesUse: {
                allow: true,
            },
        },
    },
};