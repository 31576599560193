import React from 'react';
import Grid from '@material-ui/core/Grid';

class FooterButtons extends React.Component {
    render() {
        const {showStatic} = this.props;
        if (showStatic) {
            return this.renderStaticButtons();
        }
        return this.renderDynamicButtons();
    }

    renderDynamicButtons = () => {
        const {buttons} = this.props;
        return (
            <React.Fragment>
                {buttons.sort().map((button, index) => {
                    if (index === 0) {
                        return (
                            <Grid
                                key={index.toString()}
                                container
                                item
                                justify='center'
                                xs={12}
                                sm={6}
                                lg={2}
                            >
                                <a className='text-20 mt-2 text-center' href={button.href}>{button.text}</a>
                            </Grid>
                        );
                    }
                    return (
                        <Grid
                            key={index.toString()}
                            container
                            item
                            justify='center'
                            xs={12}
                            sm={6}
                            lg={1}
                        >
                            <a className='text-20 mt-2 text-center' href={button.href}>{button.text}</a>
                        </Grid>
                    );
                })}
            </React.Fragment>
        );
    };

    renderStaticButtons = () => {
        const {buttons} = this.props;
        const targetButtons = buttons.sort();
        return (
            <React.Fragment>
                {targetButtons.map((button, index) => {
                    const marginRight = index !== targetButtons.length - 1
                        ? 'mr-5'
                        : '';
                    return (
                        <a
                            key={index.toString()}
                            className={`text-20 ${marginRight}`}
                            href={button.href}
                        >
                            {button.text}
                        </a>
                    );
                })}
            </React.Fragment>
        );
    };
}

export default FooterButtons;
