import React from 'react';

class CategoryUI extends React.Component {
    render() {
        const {primary, text, onClick} = this.props;
        const divStyle = primary ? 'category-item-2' : 'category-item';
        const textStyle = primary ? 'text-57' : 'text-56';
        return (
            <button className={divStyle} onClick={onClick}>
                <p className={textStyle}>{text}</p>
            </button>
        );
    }
}

export default CategoryUI;