import React, {Suspense} from 'react';
import {
    fetchAllReferences,
    createReference,
    updateReference,
    removeReference,
} from '../../../api/reference/referenceApi';
import ReferenceAdmin from '../../../screens/reference/admin/referenceAdmin';
import {getFileFromBinaryData} from '../../../utils/utils';
import LoadingScreen from '../../../components/emptyScreen/loadingScreen';
import {removeCaseStudy} from '../../../api/caseStudy/caseStudyApi';

class ReferenceAdminProvider extends React.Component {

    state = {
        fetched: false,
        references: [],
        apiError: {},
    };

    // INFO:
    // stylize JSON data to formData
    getFormData = (rawData) => {
        const data = new FormData();
        rawData.reference && data.append('reference', rawData.reference);
        rawData.language && data.append('language', rawData.language);
        const logo = rawData.referenceCompanyLogo;
        if (logo && logo.data) {
            let logoSource;
            if (logo.data.name && typeof logo.data.name === 'string') {
                logoSource = logo.data;
            } else {
                logoSource = getFileFromBinaryData(logo);
            }
            data.append('companyLogo', logoSource);
        }
        rawData.companyName && data.append('companyName', rawData.companyName);
        rawData.companyWebUrl && data.append('companyWebUrl', rawData.companyWebUrl);
        rawData.clientName && data.append('clientName', rawData.clientName);
        rawData.clientRole && data.append('clientRole', rawData.clientRole);
        rawData.referenceText && data.append('referenceText', rawData.referenceText);
        return data;
    };

    // INFO:
    // create reference api endpoint
    onCreateReference = async (rawData) => {
        const data = this.getFormData(rawData);
        await createReference(data)
            .then(async (response) => {
                if (response) {
                    if (response.success) {
                        window.location.reload();
                    }
                    if (!response.success || response.error) {
                        this.setState({
                            apiError: {
                                error: true,
                                message: response.message.includes('ERROR')
                                    ? response.message
                                    : `ERROR: ${response.message}`,
                            },
                        });
                    }
                }
            });
    };

    // INFO:
    // update reference api endpoint
    onUpdateReference = async (rawData) => {
        const data = this.getFormData(rawData);
        await updateReference(rawData.id, data)
            .then(async (response) => {
                if (response) {
                    if (response.success) {
                        window.location.reload();
                    }
                    if (!response.success || response.error) {
                        this.setState({
                            apiError: {
                                error: true,
                                message: response.message.includes('ERROR')
                                    ? response.message
                                    : `ERROR: ${response.message}`,
                            },
                        });
                    }
                }
            });
    };

    // INFO:
    // remove reference api endpoint
    onRemoveReference = async (id) => {
        await removeReference(id)
            .then((response) => {
                if (response) {
                    if (response.success) {
                        window.location.reload();
                    }
                    if (!response.success || response.error) {
                        this.setState({
                            apiError: {
                                error: true,
                                message: response.message.includes('ERROR')
                                    ? response.message
                                    : `ERROR: ${response.message}`,
                            },
                        });
                    }
                }
            });
    };

    onRemoveCaseStudy = async (referenceId) => {
        await removeCaseStudy(referenceId)
            .then((response) => {
                if (response) {
                    if (response.success) {
                        window.location.reload();
                    }
                    if (!response.success || response.error) {
                        this.setState({
                            apiError: {
                                error: true,
                                message: response.message.includes('ERROR')
                                    ? response.message
                                    : `ERROR: ${response.message}`,
                            },
                        });
                    }
                }
            });
    };

    render() {
        const {fetched, apiError} = this.state;
        if (fetched) {
            return (
                <Suspense fallback={<LoadingScreen/>}>
                    <ReferenceAdmin
                        references={this.state.references}
                        renderApiError={this.renderApiError()}
                        isApiEnabled={!apiError.fatalError}
                        onCreate={this.onCreateReference}
                        onUpdate={this.onUpdateReference}
                        onRemove={this.onRemoveReference}
                        onRemoveCaseStudy={this.onRemoveCaseStudy}
                        {...this.props}
                    />
                </Suspense>
            );
        }
        return <LoadingScreen/>;
    }

    renderApiError = () => {
        const {apiError} = this.state;
        if (apiError && apiError.error && apiError.message) {
            return <p className='text-error'>{apiError.message}</p>;
        }
        return null;
    };

    async componentDidMount() {
        await this.setState({fetched: false}, async () => {
            const {location} = this.props;
            const response = await fetchAllReferences();
            let references = [];
            let apiError = {};
            if (response && response.data && response.data.length > 0) {
                references = response.data;
            }
            if (location && location.state) {
                apiError = location.state.apiError;
            }
            if (response && response.error) {
                apiError = response;
            }
            this.setState({references, apiError, fetched: true});
        });
    }
}

export default ReferenceAdminProvider;
