import React from 'react';
import Grid from '@material-ui/core/Grid';
import {InquiryForm} from '../../../components/inquiry/form/inquiryForm';
import Dropdown from '../../../components/dropdown/Dropdown';
import ContinueButton from '../../../components/inquiry/continueButton/continueButton';
import {withTranslation} from 'react-i18next';

class IsReadyForMigrationInternal extends React.Component {
    title = `5. ${this.props.t('isReadyForMigration.title')}`;
    migrationReadyStatuses = [
        {
            key: 'smooth running',
            name: this.props.t('isReadyForMigration.migrationReadyStatuses.smoothRunning'),
        },
        {
            key: 'necessity',
            name: this.props.t('isReadyForMigration.migrationReadyStatuses.necessity'),
        },
        {
            key: 'dont know',
            name: this.props.t('isReadyForMigration.migrationReadyStatuses.dontKnow'),
        },
    ];

    constructor(props) {
        super(props);
        this.state = {
            selectedMigrationReadyStatus: props.selectedMigrationReadyStatus || this.migrationReadyStatuses[0],
        };
    }

    onChangeMigrationReadyStatus = (selectedMigrationReadyStatus) => this.setState({selectedMigrationReadyStatus});

    render() {
        const {sliderSettings, onNextClick, onPreviousClick, t} = this.props;
        return (
            <InquiryForm
                title={this.title}
                sliderSettings={sliderSettings}
                onNextClick={onNextClick}
                onPreviousClick={onPreviousClick}
            >
                <Grid
                    container
                    item
                    className='my-4'
                    xs={12}
                >
                    <Dropdown
                        selectedItem={this.state.selectedMigrationReadyStatus}
                        items={this.migrationReadyStatuses}
                        onChange={this.onChangeMigrationReadyStatus}
                    />
                    <Grid item xs={12} lg={6} className='mt-3 mt-xl-0 ml-xl-3'>
                        <ContinueButton t={t} onClick={onNextClick}/>
                    </Grid>
                </Grid>
            </InquiryForm>
        );
    }

    componentWillUnmount() {
        this.props.saveSelectedMigrationReadyStatus(this.state.selectedMigrationReadyStatus);
    }
}

export const IsReadyForMigration = withTranslation('inquiry')(IsReadyForMigrationInternal);