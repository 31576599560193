const darkBlue = '#283269';

export const stylesXL = {
    // front
    first: {
        width: '25%',
        height: '5px',
        backgroundColor: darkBlue,
    },
    second: {
        width: '35%',
        height: '5px',
        backgroundColor: darkBlue,
    },
    third: {
        width: '30%',
        height: '5px',
        backgroundColor: darkBlue,
    },
};
