import React from 'react';
import BlogTableHeader from './header/blogTableHeader';
import BlogTableBody from './body/blogTableBody';

class BlogTable extends React.Component {
    columns = [
        {
            name: 'Title',
        },
        {
            name: 'Language',
        },
        {
            name: 'Category',
        },
        {
            name: 'Created at',
        },
        {
            name: 'Last updated',
        },
        {
            name: '#',
        },
    ];


    render() {
        return (
            <table className='table table-striped w-100 border shadow'>
                <BlogTableHeader columns={this.columns}/>
                <BlogTableBody {...this.props}/>
            </table>
        );
    }
}

export default BlogTable;