import React from 'react';
import Grid from '@material-ui/core/Grid';
import {ContactFormWithAddress} from './contactForm/contactFormWithAddress';
import ContactCards from './contactCards/contactCards';
import {withTranslation} from 'react-i18next';
import Title from '../../components/title/title';

class ContactsInternal extends React.Component {
    render() {
        const {t} = this.props;
        return (
            <Grid container spacing={0}>
                <Title text={this.props.t('welcome.title')}/>
                <ContactFormWithAddress
                    contactFormTitle={t('contactForm.title')}
                    submitLabel={t('contactForm.submitLabel')}
                    contactFormSubTitle={t('contactForm.subTitle')}
                />
                <ContactCards t={t}/>
            </Grid>
        );
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }
}

export const Contacts = withTranslation('contacts')(ContactsInternal);
