import React from 'react';
import Grid from '@material-ui/core/Grid';
import ServiceSelectionManager from './serviceSelectionManager/serviceSelectionManager';
import {withTranslation} from 'react-i18next';
import {sendEmail} from '../../utils/email/sendEmail';
import Title from '../../components/title/title';

class InquiryInternal extends React.Component {

    onSubmit = (data) => {
        const newFormData = {
            title: `Inquiry Form, ${data.service}`,
            name: data.company,
            email: data.email,
            ...data.formData,
        };
        sendEmail(newFormData);
    };

    render() {
        return (
            <Grid
                container
                spacing={0}
                justify='center'
            >
                <Title text={this.props.t('welcome.title')}/>
                <ServiceSelectionManager {...this.props} onSubmit={this.onSubmit}/>
            </Grid>
        );
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }
}

export const Inquiry = withTranslation('inquiry')(InquiryInternal);