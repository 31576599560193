import React, {Suspense} from 'react';
import Grid from '@material-ui/core/Grid';
import i18n from 'i18next';
import {scroller, animateScroll as scroll} from 'react-scroll';
import {withTranslation} from 'react-i18next';
import {fetchReferencesByLanguage} from '../../api/reference/referenceApi';
import ReferenceManager from './manager/referenceManager/referenceManager';
import {CaseStudy} from '../caseStudy/caseStudy';
import LoadingScreen from '../../components/emptyScreen/loadingScreen';
import Title from '../../components/title/title';

class ReferenceInternal extends React.Component {

    state = {
        allReferences: [],
        fetched: false,
    };

    getAllCaseStudies = () => {
        const {allReferences} = this.state;
        if (Array.isArray(allReferences) && allReferences.length) {
            const rawCaseStudies = allReferences.map((reference) => {
                if (reference) {
                    const caseStudy = reference.referenceCaseStudy;
                    if (caseStudy && caseStudy.id) {
                        return caseStudy;
                    }
                }
                return null;
            });
            return rawCaseStudies.filter((caseStudy) => !!caseStudy);
        }
        return null;
    };

    onScrollToCaseStudy = (caseStudyId) => {
        scroller.scrollTo(caseStudyId, {
            duration: 800,
            delay: 0,
            smooth: 'easeInOutQuart',
        });
    };

    render() {
        const {fetched, allReferences} = this.state;
        if (fetched) {
            return (
                <Suspense fallback={<LoadingScreen/>}>
                    <Grid container className='place-content-center'>
                        <Title text={this.props.t('welcome.title')}/>
                        <ReferenceManager
                            t={this.props.t}
                            references={allReferences}
                            onScrollToCaseStudy={this.onScrollToCaseStudy}
                        />
                        {this.renderCaseStudy()}
                    </Grid>
                </Suspense>
            );
        }
        return <LoadingScreen/>;
    }

    renderCaseStudy = () => {
        const allCaseStudies = this.getAllCaseStudies();
        if (Array.isArray(allCaseStudies) && allCaseStudies.length) {
            return <CaseStudy {...this.props} caseStudies={allCaseStudies}/>;
        }
        return null;
    };

    async componentDidMount() {
        scroll.scrollToTop({duration: 0});
        i18n.on('languageChanged', async (lng) => {
            await this.fetchReferences(lng);
        });
        await this.fetchReferences(i18n.language);
        this.onInitScroll();
    }

    onInitScroll = () => {
        const params = new URLSearchParams(this.props.location.search);
        const caseStudyId = params.get('caseStudy');
        if (caseStudyId) {
            setTimeout(() => {
                const pos = document.getElementById(caseStudyId).offsetTop;
                scroll.scrollTo(pos);
            }, 600);
        }
    };

    fetchReferences = async (lng) => {
        await this.setState({fetched: false}, async () => {
            const response = await fetchReferencesByLanguage(lng.toLocaleUpperCase());
            let allReferences = [];
            if (response && response.data && response.data.length > 0) {
                let fetchedReferences = [];
                response.data.forEach((item) => {
                    fetchedReferences.push(...item.referenceContents);
                });
                if (fetchedReferences && fetchedReferences.length > 0) {
                    allReferences = fetchedReferences;
                }
            }
            this.setState({allReferences, fetched: true});
        });
    };
}

export const Reference = withTranslation('reference')(ReferenceInternal);
