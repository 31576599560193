import React from 'react';
import Grid from '@material-ui/core/Grid';

class Title extends React.Component {
    render() {
        const {text} = this.props;
        return (
            <Grid container item justify='center' xs={12}>
                <p className='text-28'>{text}</p>
            </Grid>
        );
    }
}

export default Title;