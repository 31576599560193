import React from 'react';
import AddIcon from '@material-ui/icons/Add';

class ReferenceToolbar extends React.Component {

    onAddClick = () => {
        this.props.onAddClick && this.props.onAddClick();
    };

    render() {
        return (
            <div className='float-right'>
                <AddIcon
                    className='cursor-pointer blue-button-2 button-large-icon'
                    onClick={this.onAddClick}
                />
            </div>
        );
    }
}

export default ReferenceToolbar;