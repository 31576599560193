const darkBlue = '#283269';

export const styles = {
    first: {
        width: '5px',
        height: '66px',
        backgroundColor: darkBlue,
    },
    second: {
        width: '5px',
        height: '66px',
        backgroundColor: darkBlue,
    },
    third: {
        width: '5px',
        height: '66px',
        backgroundColor: darkBlue,
    },
};
