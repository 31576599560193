import React from 'react';
import {Element, useEditor} from '@craftjs/core';
import {Tooltip} from '@material-ui/core';
import {ReactComponent as ButtonSvg} from '../../../../../assets/editor/toolbox/button.svg';
import {ReactComponent as UndoSvg} from '../../../../../assets/editor/toolbox/undo.svg';
import {ReactComponent as RedoSvg} from '../../../../../assets/editor/toolbox/redo.svg';
import {ReactComponent as SquareSvg} from '../../../../../assets/editor/toolbox/rectangle.svg';
import {ReactComponent as CheckmarkIcon} from '../../../../../assets/checkmark.svg';
import TitleIcon from '@material-ui/icons/Title';
import TodayIcon from '@material-ui/icons/Today';
import {ReactComponent as TypeSvg} from '../../../../../assets/editor/toolbox/text.svg';
import {ReactComponent as YoutubeSvg} from '../../../../../assets/editor/toolbox/video-line.svg';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import ImageIcon from '@material-ui/icons/Image';
import {Container} from '../../selectors/container/container';
import {Title} from '../../selectors/title/title';
import {Date} from '../../selectors/date/date';
import {Text} from '../../selectors/text';
import {NumberedParagraph} from '../../selectors/numberedParagraph/numberedParagraph';
import {Video} from '../../selectors/video/video';
import {Image} from '../../selectors/image/image';
import {Button} from '../../selectors/button/button';
import styled from 'styled-components';
import {TextBold} from '../../selectors/textBold';
import {CheckmarkText} from '../../selectors/checkmarkText/checkmarkText';

const ToolboxDiv = styled.div<{ enabled: boolean }>`
  transition: 0.4s cubic-bezier(0.19, 1, 0.22, 1);
  ${(props) => (!props.enabled ? `width: 0;` : '')} ${(props) => (!props.enabled ? `opacity: 0;` : '')}
`;

const Item = styled.a<{ disabled?: boolean; move?: boolean }>`
  svg {
    width: 22px;
    height: 22px;
    fill: #707070;
  }

  ${(props) =>
          props.move &&
          `
    cursor: move;
  `} ${(props) =>
          props.disabled &&
          `
    opacity:0.5;
    cursor: not-allowed;
  `}
`;

export const Toolbox = () => {
    const {
        enabled,
        connectors: {create},
        actions,
        canUndo,
        canRedo,
    } = useEditor((state, query) => ({
        enabled: state.options.enabled,
        canUndo: query.history.canUndo(),
        canRedo: query.history.canRedo(),
    }));

    return (
        <ToolboxDiv
            enabled={enabled && enabled}
            className='toolbox transition w-12 border-r h-full flex flex-col bg-white'
        >
            <div className='flex flex-1 flex-col items-center pt-3'>
                <div
                    ref={(ref) =>
                        create(
                            ref,
                            <Element
                                canvas
                                is={Container}
                                background={{r: 78, g: 78, b: 78, a: 1}}
                                color={{r: 0, g: 0, b: 0, a: 1}}
                                height='100px'
                                width='50%'
                            />
                        )
                    }
                >
                    <Tooltip title='Container' placement='right'>
                        <Item className='m-2 pb-2 cursor-pointer block border-none' move>
                            <SquareSvg/>
                        </Item>
                    </Tooltip>
                </div>
                <div ref={(ref) => create(ref, <Title/>)}>
                    <Tooltip title='Title' placement='right'>
                        <Item className='m-2 pb-2 cursor-pointer block border-none' move>
                            <TitleIcon/>
                        </Item>
                    </Tooltip>
                </div>
                <div ref={(ref) => create(ref, <Date/>)}>
                    <Tooltip title='Date' placement='right'>
                        <Item className='m-2 pb-2 cursor-pointer block border-none' move>
                            <TodayIcon/>
                        </Item>
                    </Tooltip>
                </div>
                <div ref={(ref) => create(ref, <Text/>)}>
                    <Tooltip title='Regular Text' placement='right'>
                        <Item className='m-2 pb-2 cursor-pointer block border-none' move>
                            <TypeSvg/>
                        </Item>
                    </Tooltip>
                </div>
                <div ref={(ref) => create(ref, <TextBold/>)}>
                    <Tooltip title='Bold Text' placement='right'>
                        <Item className='m-2 pb-2 cursor-pointer block bordr-none' move>
                            <TypeSvg/>
                        </Item>
                    </Tooltip>
                </div>
                <div ref={(ref) => create(ref, <CheckmarkText/>)}>
                    <Tooltip title='Checkmark Text' placement='right'>
                        <Item className='m-2 pb-2 cursor-pointer block border-none' move>
                            <CheckmarkIcon/>
                        </Item>
                    </Tooltip>
                </div>
                <div ref={(ref) => create(ref, <NumberedParagraph/>)}>
                    <Tooltip title='Numbered Paragraph' placement='right'>
                        <Item className='m-2 pb-2 cursor-pointer block border-none' move>
                            <FormatListNumberedIcon/>
                        </Item>
                    </Tooltip>
                </div>
                <div ref={(ref) => create(ref, <Button/>)}>
                    <Tooltip title='Button' placement='right'>
                        <Item className='m-2 pb-2 cursor-pointer block border-none' move>
                            <ButtonSvg/>
                        </Item>
                    </Tooltip>
                </div>
                <div ref={(ref) => create(ref, <Video/>)}>
                    <Tooltip title='Video' placement='right'>
                        <Item className='m-2 pb-2 cursor-pointer block border-none' move>
                            <YoutubeSvg/>
                        </Item>
                    </Tooltip>
                </div>
                <div ref={(ref) => create(ref, <Image/>)}>
                    <Tooltip title='Image' placement='right'>
                        <Item className='m-2 pb-2 cursor-pointer block border-none' move>
                            <ImageIcon/>
                        </Item>
                    </Tooltip>
                </div>
            </div>
            <div className='flex flex-col items-center pt-3'>
                <div>
                    <Tooltip title='Undo' placement='right'>
                        <Item
                            className='m-2 pb-2 cursor-pointer block border-none'
                            disabled={!canUndo}
                            onClick={() => actions.history.undo()}
                        >
                            <UndoSvg/>
                        </Item>
                    </Tooltip>
                </div>
                <div>
                    <Tooltip title='Redo' placement='right'>
                        <Item
                            className='m-2 pb-2 cursor-pointer block border-none'
                            disabled={!canRedo}
                            onClick={() => actions.history.redo()}
                        >
                            <RedoSvg/>
                        </Item>
                    </Tooltip>
                </div>
            </div>
        </ToolboxDiv>
    );
};
