import axios from 'axios';
import {web as webConfig} from '../../config/web';

export const noCaseStudyApiConnectionText = 'ERROR: Case Study API is not available';

const caseStudyApiClient = axios.create({
    baseURL: webConfig.api.url,
    timeout: webConfig.api.clientTimeout,
    headers: {
        'Content-Type': 'multipart/form-data',
    },
});

// log errors
function logError(response) {
    if (response.status !== 200 && response !== 301) {
        console.error(response.data.message);
    }
}

function handleError(error) {
    if (error && error.response) {
        return error.response.data;
    }
    return {error: true, fatalError: true, message: noCaseStudyApiConnectionText};
}

export async function getCaseStudy(referenceId) {
    try {
        const response = await caseStudyApiClient.get(`/reference/${referenceId}/caseStudy`);
        logError(response);
        return response.data;
    } catch (error) {
        return handleError(error);
    }
}

export async function createCaseStudy(referenceId, data) {
    try {
        const response = await caseStudyApiClient.post(`/reference/${referenceId}/caseStudy`, data);
        logError(response);
        return response.data;
    } catch (error) {
        return handleError(error);
    }
}

export async function updateCaseStudy(referenceId, data) {
    try {
        const response = await caseStudyApiClient.put(`/reference/${referenceId}/caseStudy`, data);
        logError(response);
        return response.data;
    } catch (error) {
        return handleError(error);
    }
}

export async function removeCaseStudy(referenceId) {
    try {
        const response = await caseStudyApiClient.delete(`/reference/${referenceId}/caseStudy`);
        logError(response);
        return response.data;
    } catch (error) {
        return handleError(error);
    }
}
