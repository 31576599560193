import React from 'react';
import Grid from '@material-ui/core/Grid';

// INFO:
//
// Properties:
// title - title of block
// text - text of block
class TextBlock extends React.Component {
    render() {
        const {title, text} = this.props;
        return (
            <Grid
                container
                item
                xs={12}
                sm={6}
                className='my-4'
            >
                <Grid item xs={12}>
                    <p className='title-1'>{title}</p>
                </Grid>
                <Grid item xs={12} className='ml-1'>
                    <p className='text-5'>{text}</p>
                </Grid>
            </Grid>
        );
    }
}

export default TextBlock;