import React from 'react';
import Grid from '@material-ui/core/Grid';
import {scroller} from 'react-scroll';
import {FirstBlock} from './firstBlock/firstBlock';
import {SecondBlock} from './secondBlock/secondBlock';
import {ThirdBlock} from './thirdBlock/thirdBlock';
import {FourthBlock} from './fourthBlock/fourthBlock';
import {FifthBlock} from './fifthBlock/fifthBlock';
import {config} from '../../config/config';

class OurServices extends React.Component {

    onScrollToBlock = (name) => {
        scroller.scrollTo(name, {
            duration: 800,
            delay: 0,
            smooth: 'easeInOutQuart',
        });
    };

    render() {
        const {randomReference, isApiWork} = this.props;
        const showReference = isApiWork && config.features.references.allow && randomReference && randomReference.id;
        return (
            <Grid container spacing={0}>
                <FirstBlock showReference={showReference} onScrollToBlock={this.onScrollToBlock}/>
                <div id='second-block'>
                    <SecondBlock/>
                </div>
                <ThirdBlock/>
                <FourthBlock showReference={showReference} reference={randomReference}/>
                <FifthBlock/>
            </Grid>
        );
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }
}

export default OurServices;
