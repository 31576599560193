import React from 'react';
import {InquiryForm} from '../../../components/inquiry/form/inquiryForm';
import {Grid} from '@material-ui/core';
import Dropdown from '../../../components/dropdown/Dropdown';
import ContinueButton from '../../../components/inquiry/continueButton/continueButton';
import InquiryInput from '../../../components/inquiry/input/inquiryInput';
import {withTranslation} from 'react-i18next';

class ReasonMigrationInternal extends React.Component {
    title = `3. ${this.props.t('reasonMigration.title')}`;

    reasons = [
        {
            key: 'saving money',
            name: this.props.t('reasonMigration.reasons.savingMoney'),
        },
        {
            key: 'independence',
            name: this.props.t('reasonMigration.reasons.independence'),
        },
        {
            key: 'data security',
            name: this.props.t('reasonMigration.reasons.dataSecurity'),
        },
        {
            key: 'legislation',
            name: this.props.t('reasonMigration.reasons.legislation'),
        },
        {
            key: 'other',
            name: this.props.t('reasonMigration.reasons.other'),
        },
    ];

    constructor(props) {
        super(props);
        this.state = {
            selectedReason: props.selectedReason || this.reasons[0],
            reasonInput: props.reasonInput || '',
        };
    }

    onChangeReason = (selectedReason) => this.setState({selectedReason});

    onChangeOtherInput = (target) => {
        const reasonInput = target.value;
        this.setState({reasonInput});
    };

    render() {
        const {sliderSettings, onNextClick, onPreviousClick, t} = this.props;
        return (
            <InquiryForm
                title={this.title}
                sliderSettings={sliderSettings}
                onNextClick={onNextClick}
                onPreviousClick={onPreviousClick}
            >
                <Grid
                    container
                    item
                    className='my-4'
                    xs={12}
                >
                    <Dropdown
                        selectedItem={this.state.selectedReason}
                        items={this.reasons}
                        onChange={this.onChangeReason}
                    />
                    <Grid item xs={12} lg={6} className='mt-3 mt-xl-0 ml-xl-3'>
                        <ContinueButton t={t} onClick={onNextClick}/>
                    </Grid>
                    {this.renderOtherInput()}
                </Grid>
            </InquiryForm>
        );
    }

    renderOtherInput = () => {
        if (this.state.selectedReason.key === this.reasons[4].key) {
            return (
                <Grid item xs={12} lg={6} className='mt-3'>
                    <InquiryInput
                        className='inquiry-input-3'
                        placeholder={this.props.t('inputPlaceholder')}
                        onChange={this.onChangeOtherInput}
                        value={this.state.reasonInput}
                    />
                </Grid>
            );
        }
        return null;
    };

    componentWillUnmount() {
        const {selectedReason, reasonInput} = this.state;
        this.props.saveMigrationSelectedReason(selectedReason);
        this.props.saveMigrationReasonInput(reasonInput);
    }
}

export const ReasonMigration = withTranslation('inquiry')(ReasonMigrationInternal);