import React from 'react';
import {config} from '../../../config/config';

class TextArea extends React.Component {

    getMaxLength = () => config.inputLength.message;

    getLengthIndicatorValue = () => {
        const {value} = this.props;
        const maxLength = this.getMaxLength();
        return value.length <= 0
            ? ''
            : `${value.length}/${maxLength}`;
    };

    onChange = (event) => {
        const {name, type} = this.props;
        const valueObject = {value: event.target.value, name, type};
        this.props.onChange && this.props.onChange(valueObject);
    };

    render() {
        const {className, placeholder} = this.props;
        const maxLength = this.getMaxLength();
        return (
            <div className='text-area-wrapper'>
                <textarea
                    className={className}
                    placeholder={placeholder}
                    onChange={this.onChange}
                    maxLength={maxLength}
                />
                {this.renderLengthIndicator()}
            </div>
        );
    }

    renderLengthIndicator = () => {
        const {showLength, lengthIndicatorStyle} = this.props;
        const value = this.getLengthIndicatorValue();
        if (showLength) {
            return <p className={lengthIndicatorStyle}>{value}</p>;
        }
        return null;
    };
}

export default TextArea;