import React from 'react';
import {formatUTCDate, getFileFromBinaryData} from '../../../../../utils/utils';
import {Link} from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

class ReferenceUI extends React.Component {

    getCompanyLogo = () => {
        const {reference} = this.props;
        const isCompanyLogoExist = reference && reference.referenceCompanyLogo;
        let targetCompanyLogo;
        if (isCompanyLogoExist && reference.referenceCompanyLogo.data) {
            const logo = reference.referenceCompanyLogo;
            if (logo.data.name && typeof logo.data.name === 'string') {
                targetCompanyLogo = logo.data;
            } else {
                const logoFromBinary = getFileFromBinaryData(logo);
                targetCompanyLogo = logoFromBinary ? logoFromBinary : null;
            }
        }
        return targetCompanyLogo;
    };

    render() {
        const {reference, languageSelector} = this.props;
        const companyLogo = this.getCompanyLogo();
        return (
            <tr className='text-table-row'>
                <td className='company-logo-container'>
                    {companyLogo &&
                    <img className='company-logo-img' src={URL.createObjectURL(companyLogo)} alt=''/>}
                </td>
                <td>{reference.clientName}</td>
                <td>{languageSelector(reference)}</td>
                <td>{formatUTCDate(reference.referenceMetadata.createdAt)}</td>
                <td>{formatUTCDate(reference.referenceMetadata.lastUpdated)}</td>
                {/* Reference */}
                <td>
                    <EditIcon className='mr-3 cursor-pointer' onClick={this.props.onEditReference}/>
                </td>
                {/* Case Study */}
                <td>
                    <Link to={`/references/admin/case-study/editor/${this.props.publicId}`} className='blue-button-2'>
                        {reference.referenceCaseStudy ? <EditIcon className='mr-3'/> : <AddIcon className='mr-3'/>}
                    </Link>
                    {reference.referenceCaseStudy && <DeleteIcon
                        className='mt-3 ml-2 mt-sm-0 blue-button-2 cursor-pointer'
                        onClick={this.props.onRemoveCaseStudy}
                    />}
                </td>
                {/* Reference */}
                <td>
                    <DeleteIcon
                        className='mt-3 mt-sm-0 blue-button-2 cursor-pointer'
                        onClick={this.props.onRemoveReference}
                    />
                </td>
            </tr>
        );
    }
}

export default ReferenceUI;
