import React from 'react';
import AddIcon from '@material-ui/icons/Add';
import {defaultArticle, getRandomDefaultImage} from '../../shared/blogShared';

class BlogToolbar extends React.Component {

    onAddClick = () => {
        getRandomDefaultImage((image) => {
            if (image) {
                const raw = defaultArticle;
                raw.contentBinaryData = {
                    data: image,
                };
                this.props.onCreateArticle && this.props.onCreateArticle(raw);
            }
        });
    };

    render() {
        return (
            <div className='float-right'>
                <div onClick={this.onAddClick} className='cursor-pointer blue-button-2'>
                    <AddIcon className='button-large-icon'/>
                </div>
            </div>
        );
    }
}

export default BlogToolbar;
