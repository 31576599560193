import React from 'react';
import Grid from '@material-ui/core/Grid';
import CategoryUI from '../../../../components/blog/present/categoryUI/categoryUI';

class CategoryManager extends React.Component {
    render() {
        return (
            <Grid container item justify='center'>
                <Grid item>
                    <CategoryUI
                        primary
                        text={this.props.t('allButton')}
                        value='all'
                        onClick={() => this.props.onClickCategory('all')}
                    />
                </Grid>
                {this.renderCategories()}
            </Grid>
        );
    }

    renderCategories = () => {
        const {categories, onClickCategory} = this.props;
        if (categories && categories.length > 0) {
            return categories.map((category, index) => {
                if (category) {
                    return (
                        <Grid item key={index.toString()}>
                            <CategoryUI
                                text={category}
                                value={category}
                                onClick={() => onClickCategory(category)}
                            />
                        </Grid>
                    );
                }
                return null;
            }).filter((item) => !!item);
        }
        return null;
    };
}

export default CategoryManager;