import React from 'react';
import {Grid} from '@material-ui/core';
import Collapse from '../../../components/collapse/collapse';
import Divider from '../../../components/divider/divider';
import {NavLink} from 'react-router-dom';

class Faq extends React.Component {

    faqInfo = () => {
        const {t} = this.props;
        return [
            {
                title: t('faq.first.title'),
                text: (
                    <Grid container className='py-3'>
                        <Grid item xs={12}>
                            <p className='text-47'>{t('faq.first.firstText')}</p>
                        </Grid>
                        <Grid item xs={12}>
                            <p className='text-47'>{t('faq.first.secondText')}</p>
                        </Grid>
                        <Grid item xs={12}>
                            <p className='text-47'>{t('faq.first.thirdText')}</p>
                        </Grid>
                        <Grid item xs={12} className='d-inline-flex flex-row'>
                            <p className='text-47'>
                                {t('faq.first.fourthText.text')}
                                <NavLink
                                    exact
                                    to='/blog'
                                    className='text-47-underline'
                                >
                                    {t('faq.first.fourthText.link')}
                                </NavLink>
                            </p>
                        </Grid>
                    </Grid>
                ),
            },
            {
                title: t('faq.second.title'),
                text: (
                    <Grid container className='py-3'>
                        <Grid item xs={12}>
                            <p className='text-47'>{t('faq.second.firstText')}</p>
                        </Grid>
                        <Grid item xs={12}>
                            <p className='text-47'>{t('faq.second.secondText')}</p>
                        </Grid>
                    </Grid>
                ),
            },
            {
                title: t('faq.third.title'),
                text: (
                    <Grid container className='py-3'>
                        <Grid item xs={12}>
                            <p className='text-47'>{t('faq.third.firstText')}</p>
                        </Grid>
                    </Grid>
                ),
            },
            {
                title: t('faq.fourth.title'),
                text: (
                    <Grid container className='py-3'>
                        <Grid item xs={12}>
                            <p className='text-47'>{t('faq.fourth.firstText')}</p>
                        </Grid>
                        <Grid item xs={12}>
                            <p className='text-47'>{t('faq.fourth.secondText')}</p>
                        </Grid>
                    </Grid>
                ),
            },
            {
                title: t('faq.fifth.title'),
                text: (
                    <Grid container className='py-3'>
                        <Grid item xs={12}>
                            <div className='text-47'>
                                {t('faq.fifth.firstText')}
                                <p className='text-underline d-inline'>{t('faq.fifth.phone')}</p>,
                                {t('faq.fifth.secondText')}
                                <p className='text-underline d-inline'>{t('faq.fifth.email')}</p>
                            </div>
                        </Grid>
                    </Grid>
                ),
            },
            {
                title: t('faq.sixth.title'),
                text: (
                    <Grid container className='py-3'>
                        <Grid item xs={12}>
                            <p className='text-47'>{t('faq.sixth.firstText')}</p>
                        </Grid>
                    </Grid>
                ),
            },
            {
                title: t('faq.seventh.title'),
                text: (
                    <Grid container className='py-3'>
                        <p className='text-47'>{t('faq.seventh.firstText')}</p>
                    </Grid>
                ),
            },
            {
                title: t('faq.eighth.title'),
                text: (
                    <Grid container className='py-3'>
                        <p className='text-47'>{t('faq.eighth.firstText')}</p>
                    </Grid>
                ),
            },
        ];
    };

    render() {
        return (
            <Grid container className='faq-container'>
                {this.renderQuestions()}
            </Grid>
        );
    }

    renderQuestions = () => {
        return this.faqInfo().map((item, index) => {
            return (
                <Grid key={index.toString()} item xs={12}>
                    <Collapse key={index} title={item.title} text={item.text}/>
                    <Divider vertical/>
                </Grid>
            );
        });
    };
}

export default Faq;