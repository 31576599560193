import React from 'react';
import Grid from '@material-ui/core/Grid';

class TermsAndConditionsCS extends React.Component {
    render() {
        return (
            <Grid
                item
                container
                direction='column'
                alignContent='center'
                xs={12}
            >
                {this.renderFirst()}
                {this.renderSecond()}
                {this.renderThird()}
                {this.renderFourth()}
                {this.renderFifth()}
                {this.renderSixth()}
                {this.renderSeventh()}
                {this.renderEighth()}
                {this.renderNinth()}
            </Grid>
        );
    }

    renderFirst = () => {
        const {t} = this.props;
        return (
            <React.Fragment>
                <p style={{marginTop: '100px'}} className='text-54 pt-2 pl-3'>{t('I.first')}</p>
            </React.Fragment>
        );
    };

    renderSecond = () => {
        const {t} = this.props;
        return (
            <React.Fragment>
                <p className='text-54 pt-4 pl-3'>{t('II.first')}</p>
            </React.Fragment>
        );
    };

    renderThird = () => {
        const {t} = this.props;
        return (
            <React.Fragment>
                <h2 className='text-53 pt-4 pl-3'>{t('III.title')}</h2>
                <p className='text-54 pt-2 pl-3'>{t('III.first')}</p>
                <p className='text-54 pt-2 pl-3'>{t('III.second')}</p>
            </React.Fragment>
        );
    };

    renderFourth = () => {
        const {t} = this.props;
        return (
            <React.Fragment>
                <h2 className='text-53 pt-4 pl-3'>{t('IV.title')}</h2>
                <p className='text-54 pt-2 pl-3'>{t('IV.first')}</p>
                <p className='text-54 pt-2 pl-3'>{t('IV.second')}</p>
                <p className='text-54 pt-2 pl-3'>{t('IV.third')}</p>
                <p className='text-54 pt-2 pl-3'>{t('IV.fourth')}</p>
                <p className='text-54 pt-2 pl-3'>{t('IV.fifth.title')}</p>
                <p className='text-54 pt-2 pl-5'>{t('IV.fifth.first')}</p>
                <p className='text-54 pt-2 pl-5'>{t('IV.fifth.second')}</p>
                <p className='text-54 pt-2 pl-5'>{t('IV.fifth.third')}</p>
                <p className='text-54 pt-2 pl-5'>{t('IV.fifth.fourth')}</p>
            </React.Fragment>
        );
    };

    renderFifth = () => {
        const {t} = this.props;
        return (
            <React.Fragment>
                <h2 className='text-53 pt-4 pl-3'>{t('V.title')}</h2>
                <p className='text-54 pt-2 pl-3'>{t('IV.first')}</p>
                <p className='text-54 pt-2 pl-3'>{t('IV.second')}</p>
                <p className='text-54 pt-2 pl-3'>{t('IV.third')}</p>
            </React.Fragment>
        );
    };

    renderSixth = () => {
        const {t} = this.props;
        return (
            <React.Fragment>
                <h2 className='text-53 pt-4 pl-3'>{t('VI.title')}</h2>
                <p className='text-54 pt-2 pl-3'>{t('VI.first')}</p>
            </React.Fragment>
        );
    };

    renderSeventh = () => {
        const {t} = this.props;
        return (
            <React.Fragment>
                <h2 className='text-53 pt-4 pl-3'>{t('VII.title')}</h2>
                <p className='text-54 pt-2 pl-3'>{t('VII.first')}</p>
                <p className='text-54 pt-2 pl-3'>{t('VII.second.title')}</p>
                <p className='text-54 pt-2 pl-5'>{t('VII.second.first')}</p>
                <p className='text-54 pt-2 pl-5'>{t('VII.second.second')}</p>
                <p className='text-54 pt-2 pl-5'>{t('VII.second.third')}</p>
                <p className='text-54 pt-2 pl-5'>{t('VII.second.fourth')}</p>
            </React.Fragment>
        );
    };

    renderEighth = () => {
        const {t} = this.props;
        return (
            <React.Fragment>
                <h2 className='text-53 pt-4 pl-3'>{t('VIII.title')}</h2>
                <p className='text-54 pt-2 pl-3'>{t('VIII.first')}</p>
                <p className='text-54 pt-2 pl-3'>{t('VIII.second')}</p>
            </React.Fragment>
        );
    };

    renderNinth = () => {
        const {t} = this.props;
        return (
            <React.Fragment>
                <h2 className='text-53 pt-4 pl-3'>{t('IX.title')}</h2>
                <p className='text-54 pt-2 pl-3'>{t('IX.first')}</p>
                <p className='text-54 pt-2 pl-3'>{t('IX.second')}</p>
            </React.Fragment>
        );
    };
}

export default TermsAndConditionsCS;