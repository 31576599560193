import axios from 'axios';
import {web as webConfig} from '../../config/web';

export const noReferenceApiConnectionText = 'ERROR: Reference API is not available';

const referenceApiClient = axios.create({
    baseURL: webConfig.api.url,
    timeout: webConfig.api.clientTimeout,
    headers: {
        'Content-Type': 'multipart/form-data',
    },
});

// log errors
function logError(response) {
    if (response.status !== 200 && response !== 301) {
        console.error(response.data.message);
    }
}

function handleError(error) {
    if (error && error.response) {
        return error.response.data;
    }
    return {error: true, fatalError: true, message: noReferenceApiConnectionText};
}

export async function fetchAllReferences() {
    try {
        const response = await referenceApiClient.get(`/reference/all/`);
        logError(response);
        return response.data;
    } catch (error) {
        return handleError(error);
    }
}

export async function fetchRandomReference(language) {
    try {
        const response = await referenceApiClient.get(`/reference/random?language=${language}`);
        logError(response);
        return response.data;
    } catch (error) {
        return handleError(error);
    }
}

export async function fetchReferenceByPublicId(publicId) {
    try {
        const response = await referenceApiClient.get(`/reference/${publicId}`);
        logError(response);
        return response.data;
    } catch (error) {
        return handleError(error);
    }
}

export async function fetchReferencesByLanguage(lng) {
    try {
        const response = await referenceApiClient.get(`/reference/all/?language=${lng || 'any'}`);
        logError(response);
        return response.data;
    } catch (error) {
        return handleError(error);
    }
}

export async function fetchAllReferenceContentsByPublicId(publicId) {
    try {
        const response = await referenceApiClient.get(`/reference/${publicId}`);
        logError(response);
        return response.data;
    } catch (error) {
        return handleError(error);
    }
}

export async function createReference(data) {
    try {
        const response = await referenceApiClient.post('/reference', data);
        logError(response);
        return response.data;
    } catch (error) {
        return handleError(error);
    }
}

export async function updateReference(id, data) {
    try {
        const response = await referenceApiClient.put(`/reference/${id}`, data);
        logError(response);
        return response.data;
    } catch (error) {
        return handleError(error);
    }
}

export async function removeReference(id) {
    try {
        const response = await referenceApiClient.delete(`/reference/${id}`);
        logError(response);
        return response.data;
    } catch (error) {
        return handleError(error);
    }
}
