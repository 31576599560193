import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '../button/button';
import {Link} from 'react-router-dom';

// INFO:
// a component that shows the rating from the client
//
// Properties:
// logo - client logo
// companyName - client name
// text -recommendation text
// author - person who write that
// position -position of the person in the company
class SaidAboutUs extends React.Component {

    render() {
        return (
            <Grid
                container
                item
                className='said-us-block'
                alignItems='center'
                xs={12}
                md={12}
                lg={8}
            >
                {this.renderImage()}
                {this.renderContent()}
            </Grid>
        );
    }

    renderImage = () => {
        const {logo} = this.props;
        if (logo) {
            return (
                <Grid
                    container
                    item
                    justify='center'
                    className='reference-img-container'
                    xs={12}
                    md={4}
                >
                    <img src={URL.createObjectURL(logo)} className='reference-preview-img' alt=''/>
                </Grid>
            );
        }
        return null;
    };

    renderContent = () => {
        const {companyName, referenceText, clientName, clientRole} = this.props;
        return (
            <Grid
                container
                item
                className='blog-content-container ml-4'
                xs={12}
                md={7}
            >
                {/* Company name */}
                {companyName && <p className='text-8'>{companyName}</p>}
                {/* Reference text */}
                {referenceText && <p className='text-9'>{referenceText}</p>}
                {/* Client name */}
                {clientName && <p className='mt-4 text-10'>{clientName}</p>}
                <Grid container item justify='space-between' xs={12}>
                    {/* Client role */}
                    {<p className='text-11 mt-1'>{clientRole}</p>}
                    {this.renderCaseStudyButton()}
                </Grid>
            </Grid>
        );
    };

    renderCaseStudyButton = () => {
        const {t, showCaseStudyButton, caseStudyId} = this.props;
        if (showCaseStudyButton) {
            return (
                <Link to={{pathname: '/references', search: `?caseStudy=${caseStudyId}`}}>
                    <Button type='clear' text={t('fourthBlock.caseStudyButton')} showArrow/>
                </Link>
            );
        }
        return null;
    };
}

export default SaidAboutUs;