import React from 'react';
import arrowDown from '../../assets/language-down.png';
import arrowUp from '../../assets/language-top.png';

// INFO:
// dropdown component,which is adapted for language selection and its parameters
//
// Properties:
// selectedLanguage - current selected language
// languages - list of all languages
// onChange - function, is trigger when select item in dropdown
class LanguageDropdown extends React.Component {
    state = {
        isOpen: false,
    };

    onChange = (event) => {
        if (this.props.languages.length > 1) {
            const isOpen = !event.currentTarget.className.includes('show');
            this.setState({isOpen});
        }
    };

    render() {
        const {isOpen} = this.state;
        const {selectedLanguage} = this.props;
        const bottomBorder = isOpen
            ? 'language-dropdown-bottom-border'
            : '';
        return (
            <div className='dropdown' onMouseDown={this.onChange}>
                <button
                    className={`btn btn-secondary language-dropdown-toggle ${bottomBorder}`}
                    id='dropdownMenuLink'
                    data-toggle={this.props.languages.length > 1 ? 'dropdown' : ''}
                    aria-haspopup='true'
                    aria-expanded='false'
                >
                    {selectedLanguage.name}
                    <img
                        src={this.state.isOpen ? arrowUp : arrowDown}
                        alt='arrow'
                        className='ml-2'
                    />
                </button>
                <div className='language-dropdown-menu dropdown-menu' aria-labelledby='dropdownMenuLink'>
                    {this.renderItems()}
                </div>
            </div>
        );
    }

    renderItems = () => {
        const {languages, selectedLanguage} = this.props;
        return languages.map((item, index) => {
            if (item.name !== selectedLanguage.name) {
                return (
                    <button
                        key={index.toString()}
                        className='dropdown-item language-dropdown-item'
                        onClick={() => this.props.onChange(item)}
                    >
                        {item.name}
                    </button>
                );
            }
            return null;
        }).filter((item) => !!item);
    };
}

export default LanguageDropdown;
