const darkBlue = '#283269';

export const stylesMD = {
    // front
    first: {
        width: '29%',
        height: '5px',
        backgroundColor: darkBlue,
    },
    second: {
        width: '37%',
        height: '5px',
        backgroundColor: darkBlue,
    },
    third: {
        width: '28%',
        height: '5px',
        backgroundColor: darkBlue,
    },
};
