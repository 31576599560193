import React, {Suspense} from 'react';
import {Redirect} from 'react-router-dom';
import {config} from '../../../config/config';
import {defaultTemplateBase64} from './defaultTemplate';
import {fetchReferenceByPublicId} from '../../../api/reference/referenceApi';
import {CaseStudySaveDialog} from '../../../components/caseStudy/editor/saveDialog/caseStudySaveDialog';
import CustomEditor from '../../../components/editor/editor';
import LanguageSelector from '../../../components/languageSelector/languageSelector';
import {getBase64InJSON, getFileFromBinaryData} from '../../../utils/utils';
import {createCaseStudy, updateCaseStudy} from '../../../api/caseStudy/caseStudyApi';
import InfoText from '../../../components/caseStudy/editor/infoText/InfoText';
import LoadingScreen from '../../../components/emptyScreen/loadingScreen';

class CaseStudyEditorProvider extends React.Component {

    state = {
        caseStudy: null,
        allCaseStudies: [],
        fetched: false,
        redirect: false,
        apiError: {
            error: false,
        },
        showSaveDialog: false,
    };

    deserialize = null;
    actualEditorContent = null;
    saveDialogCb = null;

    getFormData = (rawData) => {
        const data = new FormData();
        const previewImage = rawData.caseStudyPreviewImage;
        if (previewImage && previewImage.data) {
            let logoSource;
            if (previewImage.data.name && typeof previewImage.data.name === 'string') {
                logoSource = previewImage.data;
            } else {
                logoSource = getFileFromBinaryData(previewImage);
            }
            data.append('previewImage', logoSource);
        }
        rawData.content && data.append('content', rawData.content);
        data.append('blogArticleUrl', rawData.blogArticleUrl);
        data.append('inquiryType', rawData.inquiryType);
        return data;
    };

    getAllowedLanguages = () => {
        const {allCaseStudies} = this.state;
        if (allCaseStudies && allCaseStudies.length > 0) {
            const allCaseStudyLanguages = allCaseStudies.map((caseStudy) => caseStudy.language);
            return config.allowedReferenceLanguages.filter((lng) => !allCaseStudyLanguages.includes(lng));
        }
        return config.allowedReferenceLanguages;
    };

    onSaveToServer = (content) => {
        const {caseStudy} = this.state;
        this.onEditCaseStudyContent(content, !caseStudy.id,
            async (newAllCaseStudies) => {
                const allCaseStudies = newAllCaseStudies && newAllCaseStudies.length > 0
                    ? newAllCaseStudies
                    : this.state.allCaseStudies;
                if (allCaseStudies && allCaseStudies.length > 0) {
                    allCaseStudies.forEach(async (item, index) => {
                        const isLast = index === allCaseStudies.length - 1;
                        if (item.id) {
                            await this.onUpdateCaseStudy(item, isLast);
                        } else {
                            await this.onCreateCaseStudy(item, isLast);
                        }
                    });
                }
            });
    };

    onCreateCaseStudy = async (rawData, last) => {
        const data = this.getFormData(rawData);
        let caseStudy = {};
        await createCaseStudy(rawData.referenceId, data)
            .then(async (response) => {
                if (response) {
                    if (response.success) {
                        caseStudy = response.data;
                        last && this.setState({redirect: true});
                    }
                    if (!response.success || response.error) {
                        this.setState({
                            apiError: {
                                error: true,
                                message: response.message.includes('ERROR')
                                    ? response.message
                                    : `ERROR: ${response.message}`,
                            },
                        });
                    }
                }
            });
        return caseStudy;
    };

    onUpdateCaseStudy = async (rawData, last) => {
        const data = this.getFormData(rawData);
        let caseStudy = {};
        await updateCaseStudy(rawData.referenceId, data)
            .then(async (response) => {
                if (response) {
                    if (response.success) {
                        caseStudy = response.data;
                        last && this.setState({redirect: true});
                    }
                    if (!response.success || response.error) {
                        this.setState({
                            apiError: {
                                error: true,
                                message: response.message.includes('ERROR')
                                    ? response.message
                                    : `ERROR: ${response.message}`,
                            },
                        });
                    }
                }
            });
        return caseStudy;
    };

    onSaveCaseStudy = async (dialogData) => {
        const {caseStudy, allCaseStudies} = this.state;
        const newCaseStudy = {
            id: caseStudy.id || null,
            referenceId: caseStudy.referenceId,
            language: caseStudy.language,
            content: caseStudy.content,
            blogArticleUrl: dialogData.blogArticleUrl,
            inquiryType: dialogData.inquiryType,
            caseStudyPreviewImage: {...caseStudy.caseStudyPreviewImage, data: dialogData.previewImage},
        };
        const newAllCaseStudies = allCaseStudies.map((caseStudy) => {
            if (caseStudy.language === newCaseStudy.language) {
                return newCaseStudy;
            }
            return caseStudy;
        });
        this.setState({caseStudy: newCaseStudy, allCaseStudies: newAllCaseStudies});
        if (this.saveDialogCb) {
            await this.saveDialogCb(newAllCaseStudies);
            this.saveDialogCb = null;
        }
    };

    onEditCaseStudyContent = (content, showSaveDialog = true, cb) => {
        if (content) {
            const {caseStudy, allCaseStudies} = this.state;
            const newCaseStudy = {...caseStudy, content};
            const newAllCaseStudies = allCaseStudies.map((item) => {
                if (item.language === newCaseStudy.language) {
                    return newCaseStudy;
                }
                return item;
            });
            if (showSaveDialog && cb) {
                this.saveDialogCb = cb;
            }
            this.setState({
                caseStudy: newCaseStudy,
                allCaseStudies: newAllCaseStudies,
                showSaveDialog,
            }, () => !showSaveDialog && cb && cb());
        }
    };

    onChangeLanguage = (lng) => {
        this.onEditCaseStudyContent(this.actualEditorContent, false,
            () => {
                const newCaseStudy = this.state.allCaseStudies.find((caseStudy) => caseStudy.language === lng);
                this.setState({caseStudy: newCaseStudy});
                this.deserialize(getBase64InJSON(newCaseStudy.content));
            });
    };

    render() {
        const {fetched, showSaveDialog, apiError, redirect, caseStudy} = this.state;
        if (fetched) {
            if (apiError.error || redirect) {
                return <Redirect to={{pathname: '/references/admin', state: {apiError}}}/>;
            }
            if (caseStudy) {
                return (
                    <Suspense fallback={<LoadingScreen/>}>
                        <React.Fragment>
                            {showSaveDialog
                            && <CaseStudySaveDialog
                                show={showSaveDialog}
                                caseStudy={caseStudy}
                                onClose={() => this.setState({showSaveDialog: false})}
                                onSave={this.onSaveCaseStudy}
                            />}
                            <CustomEditor
                                onCancel={() => this.setState({redirect: true})}
                                onEdit={this.onEditCaseStudyContent}
                                onSave={this.onSaveToServer}
                                content={getBase64InJSON(caseStudy.content)}
                                infoText={caseStudy && <InfoText caseStudy={caseStudy}/>}
                                languageSelector={this.renderLanguageSelector}
                                isDialogOpen={showSaveDialog}
                            />
                        </React.Fragment>
                    </Suspense>
                );
            }
        }
        return <LoadingScreen/>;
    }

    renderLanguageSelector = (deserialize, getContent) => {
        const {caseStudy, allCaseStudies} = this.state;
        const previewImage = caseStudy.caseStudyPreviewImage;
        this.deserialize = deserialize;
        if (caseStudy && allCaseStudies.length > 0) {
            const allLanguages = allCaseStudies
                .map((caseStudy) => caseStudy.language)
                .filter((language) => language !== caseStudy.language);
            return (
                <div style={{marginRight: '50px'}}>
                    <LanguageSelector
                        selectedLanguage={caseStudy.language}
                        languages={allLanguages}
                        onChange={(lng) => {
                            this.actualEditorContent = getContent();
                            if (caseStudy && previewImage && previewImage.data) {
                                this.onChangeLanguage(lng);
                            } else {
                                this.setState({showSaveDialog: true});
                            }
                        }}
                        style={{marginTop: '6px'}}
                    />
                </div>
            );
        }
        return null;
    };

    async componentDidMount() {
        window.scrollTo(0, 0);
        this.setState({fetched: false}, async () => {
            const {publicId} = this.props.match.params;
            if (publicId) {
                let allCaseStudies = [];
                const response = await fetchReferenceByPublicId(publicId);
                if (response.data && response.data && response.data[0].referenceContents.length > 0) {
                    const references = response.data[0].referenceContents;
                    references.forEach((reference) => {
                        let caseStudy = {
                            ...reference.referenceCaseStudy,
                            referenceId: reference.id,
                            lastUpdated: reference.referenceMetadata.lastUpdated,
                            language: reference.language,
                        };
                        if (caseStudy.content) {
                            allCaseStudies.push(caseStudy);
                        } else {
                            caseStudy.content = defaultTemplateBase64;
                            allCaseStudies.push(caseStudy);
                        }
                    });
                }
                this.setState({allCaseStudies, caseStudy: allCaseStudies[0], fetched: true});
            }
        });
    }
}

export default CaseStudyEditorProvider;
