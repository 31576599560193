import React from 'react';
import i18n from 'i18next';
import {GoogleReCaptcha, GoogleReCaptchaProvider} from 'react-google-recaptcha-v3';
import {fetchRandomReference} from '../../api/reference/referenceApi';
import {web as webConfig} from '../../config/web';
import App from '../../App';
import {GA4React} from 'ga-4-react';

class AppProvider extends React.Component {

    state = {
        isApiWork: null,
        randomReference: null,
    };

    recaptchaToken = null;
    gaIsEnabled = 'INFO: Google analytics are enabled';
    gaIsDisabled = 'INFO: Google analytics are disabled';
    noApiWorkText = 'ERROR: Backend API is not available';

    render() {
        const {isApiWork, randomReference} = this.state;
        return (
            <GoogleReCaptchaProvider reCaptchaKey={webConfig.recaptchaV3.siteKey}>
                <GoogleReCaptcha onVerify={token => this.recaptchaToken = token}/>
                <App isApiWork={isApiWork} randomReference={randomReference}/>
            </GoogleReCaptchaProvider>
        );
    }

    async componentDidMount() {
        i18n.on('languageChanged', async (lng) => {
            await this.fetchRandomReference(lng);
        });
        this.loadGoogleAnalytics();
        await this.fetchRandomReference(i18n.language);
    }

    loadGoogleAnalytics = () => {
        const measurementId = webConfig.googleAnalytics.measurementId;
        if (measurementId) {
            const ga4react = new GA4React(measurementId, {}, [], 5000);
            ga4react.initialize().then((ga4) => {
                ga4.pageview('path');
                ga4.gtag('event', 'pageview', 'path');
                console.log(this.gaIsEnabled);
            }, () => {
                console.log(this.gaIsDisabled);
            });
        } else {
            console.log(this.gaIsDisabled);
        }
    };

    fetchRandomReference = async (language) => {
        const response = await fetchRandomReference(language.toUpperCase());
        const isApiWork = response && !response.error;
        !isApiWork && console.log(this.noApiWorkText);
        this.setState({isApiWork, randomReference: response.data});
    };
}

export default AppProvider;