import React from 'react';
import {Grid} from '@material-ui/core';
import Form from '../form/form';
import Input from '../form/input/input';
import TextArea from '../form/textarea/textArea';
import Submit from '../form/submit/submit';
import PersonalDataConsent from '../personalDataConsent/personalDataConsent';
import {withTranslation} from 'react-i18next';
import {config} from '../../config/config';

const defaultState = {
    name: {
        value: '',
        error: false,
    },
    email: {
        value: '',
        error: false,
    },
    message: {
        value: '',
        error: false,
    },
    personalDataConsent: {
        value: false,
        error: false,
    },
    isMessageSent: false,
};

// INFO:
// render the form, with name, email, message input and submit button
//
// Properties:
// style - adds another style next to existing
// smaller - render smaller version of this
// title - add title to form
// subTitle - add sub title to form
// submitLabel - add text to submit label
// onSubmit - function, when is trigger (onClick), passes all data (name, email, message, personalDataConsent)
class ContactFormInternal extends React.Component {
    state = defaultState;

    onSentMessage = () => this.setState({...defaultState, isMessageSent: true});

    isValueCorrect = (val) => !!val && val.length > 0;

    onSubmitForm = () => {
        const {name, email, message, personalDataConsent, isMessageSent} = this.state;
        if (isMessageSent) {
            return;
        }
        const newState = {
            name: {
                value: name.value,
                error: !this.isValueCorrect(name.value),
            },
            email: {
                value: email.value,
                error: !this.isValueCorrect(email.value),
            },
            message: {
                value: message.value,
                error: !this.isValueCorrect(message.value),
            },
            personalDataConsent: {
                value: personalDataConsent.value,
                error: !personalDataConsent.value,
            },
        };
        if (!newState.name.error &&
            !newState.email.error &&
            !newState.message.error &&
            !newState.personalDataConsent.error) {
            const formData = {
                name: name.value,
                email: email.value,
                message: message.value,
            };
            this.props.onSubmit && this.props.onSubmit(formData, () => this.onSentMessage());
        }
        this.setState(newState);
    };

    onChangeElement = (target) => {
        let value;
        if (target.type !== 'checkbox') {
            value = target.value;
        } else {
            value = target.checked;
        }
        this.setState({
            [target.name]: {
                ...this.state[target.name],
                value,
            },
        });
    };

    render() {
        const {smaller, className} = this.props;
        return (
            <Grid
                container
                item
                className={`background-1 p-4 ${className}`}
                xs={12}
                md={10}
                lg={smaller ? 5 : 7}
            >
                <Form onSubmit={this.onSubmitForm}>
                    {this.renderTitle()}
                    {this.renderSubtitle()}
                    <Grid
                        container
                        item
                        className='ml-1'
                        justify='center'
                        spacing={3}
                        xs={12}
                    >
                        {this.renderNameInput()}
                        {this.renderEmailInput()}
                        {this.renderMessageInput()}
                        {this.renderSubmitButtonAndRules()}
                    </Grid>
                </Form>
            </Grid>
        );
    }

    renderTitle = () => {
        const {title, smaller} = this.props;
        const className = smaller
            ? 'text-14 text-14-smaller ml-3 mb-4'
            : 'text-14 text-center mb-4';
        return <p className={className}>{title}</p>;
    };

    renderSubtitle = () => {
        const {subTitle, smaller} = this.props;
        if (subTitle) {
            const className = smaller
                ? 'text-15-smaller ml-3 mb-5'
                : 'text-15 text-center mb-5';
            return <p className={className}>{subTitle}</p>;
        }
        return null;
    };

    renderNameInput = () => {
        const {name} = this.state;
        const {t} = this.props;
        return (
            <Grid
                item
                xs={12}
                sm={6}
                lg={this.props.smaller ? 6 : 4}
            >
                <Input
                    name='name'
                    type='text'
                    className='input-1'
                    placeholder={t('contactForm.name.placeholder')}
                    onChange={this.onChangeElement}
                    value={name.value}
                    maxLength={config.inputLength.name}
                />
                {name.error &&
                <p className='text-49'>{t('contactForm.name.error')}</p>}
            </Grid>
        );
    };

    renderEmailInput = () => {
        const {email} = this.state;
        const {t} = this.props;
        return (
            <Grid
                item
                xs={12}
                sm={6}
                lg={this.props.smaller ? 6 : 4}
            >
                <Input
                    name='email'
                    type='email'
                    className='input-1'
                    placeholder={t('contactForm.email.placeholder')}
                    onChange={this.onChangeElement}
                    value={email.value}
                    maxLength={config.inputLength.email}
                />
                {email.error &&
                <p className='text-49'>{t('contactForm.email.error')}</p>}
            </Grid>
        );
    };

    renderMessageInput = () => {
        const {message} = this.state;
        const {smaller, t} = this.props;
        return (
            <Grid
                item
                className='pt-4'
                xs={12}
                md={12}
                lg={smaller ? 12 : 8}
            >
                <TextArea
                    name='message'
                    className='text-area-1'
                    lengthIndicatorStyle='text-area-length'
                    placeholder={t('contactForm.message.placeholder')}
                    onChange={this.onChangeElement}
                    value={message.value}
                    showLength
                />
                {message.error &&
                <p className='text-49'>{t('contactForm.message.error')}</p>}
            </Grid>
        );
    };

    renderSubmitButtonAndRules = () => {
        const {smaller, submitLabel, t} = this.props;
        const {personalDataConsent, isMessageSent} = this.state;
        const targetClassName = smaller ? 'personal-data-consent' : 'personal-data-consent-2';
        return (
            <Grid
                container
                item
                sm={12}
                md={12}
                lg={smaller ? 12 : 8}
                justify={isMessageSent ? 'center' : 'flex-start'}
            >
                <Grid
                    container
                    item
                    className='pr-1'
                    xs={12}
                    sm
                >
                    <Submit
                        type={smaller ? 'contact-form-only-text' : 'contact-form'}
                        text={submitLabel}
                    />
                </Grid>
                <Grid
                    container
                    item
                    alignItems='center'
                    className={`mt-3 mt-sm-0 ${targetClassName}`}
                    xs={12}
                    sm
                >
                    <PersonalDataConsent
                        t={t}
                        checkboxName='personalDataConsent'
                        onChangeCheckbox={this.onChangeElement}
                        checkboxValue={personalDataConsent.value}
                        showWarning={personalDataConsent.error}
                    />
                </Grid>
                {isMessageSent && <p className='text-50 pt-3'>{t('contactForm.sentMessage')}</p>}
            </Grid>
        );
    };
}

export const ContactForm = withTranslation('global')(ContactFormInternal);
