import React from 'react';
import Grid from '@material-ui/core/Grid';

// INFO:
//icon with text, used for why use our services
//
// Properties:
// icon - icon of content
// label - label of context
// text - text under label
// className - adds another style next to existing
class TextIcon extends React.Component {

    onMouseEnter = () => {
        this.props.onMouseMove(true);
        this.props.onHover();
    };

    onMouseLeave = () => {
        this.props.onMouseMove(false);
    };

    render() {
        const {icon, label, text, className, grid} = this.props;
        return (
            <Grid
                item
                container
                className={`text-icon ${className || ''} mb-3`}
                alignItems='center'
                onMouseEnter={this.onMouseEnter}
                onMouseLeave={this.onMouseLeave}
                xs={grid.all.xs}
                sm={grid.all.sm}
                md={grid.all.md}
                lg={grid.all.lg}
            >
                <Grid
                    item
                    xs={grid.icon.xs}
                    sm={grid.icon.sm}
                    md={grid.icon.md}
                    lg={grid.icon.lg}
                >
                    <img src={icon} alt={label}/>
                </Grid>
                <Grid
                    item
                    xs={grid.text.xs}
                    sm={grid.text.sm}
                    md={grid.text.md}
                    lg={grid.text.lg}
                >
                    <Grid item className='icon-label'>{label}</Grid>
                    <Grid item className='icon-text'>{text}</Grid>
                </Grid>
            </Grid>
        );
    }
}

export default TextIcon;
