import React from 'react';


// INFO:
// render line, which can be used for divide content
//
// Properties:
// longer - render the longest version
class Divider extends React.Component {
    render() {
        const {longer, vertical} = this.props;
        const targetStyle = !longer ? 'divider' : 'divider divider-longer';
        if (vertical) {
            return <div className='divider-vertical my-2'/>;
        }
        return <div className={targetStyle}/>;
    }
}

export default Divider;