import React from 'react';
import Grid from '@material-ui/core/Grid';
import {ContactForm} from '../../../components/contactForm/contactForm';
import {companyInfo} from '../../../config/companyInfo';
import FooterSocialMedia from '../../../components/footer/footerSocialMedia/footerSocialMedia';
import {withTranslation} from 'react-i18next';
import {sendEmail} from '../../../utils/email/sendEmail';

class ContactFormWithAddressInternal extends React.Component {

    onSubmitContactForm = (formData, cb) => {
        const newFormData = {
            title: 'Contact Form',
            ...formData,
        };
        sendEmail(newFormData, cb);
    };

    render() {
        return (
            <Grid container item className='background-2 p-4 justify-content-xl-start' justify='center'>
                {this.renderContactForm()}
                {this.renderContactInformation()}
            </Grid>
        );
    }

    renderContactForm = () => {
        const {contactFormTitle, contactFormSubTitle, submitLabel} = this.props;
        return (
            <ContactForm
                title={contactFormTitle}
                subTitle={contactFormSubTitle}
                submitLabel={submitLabel}
                onSubmit={this.onSubmitContactForm}
                className='m-auto block-2'
                smaller
            />
        );
    };

    renderContactInformation = () => {
        const {t} = this.props;
        const links = companyInfo.links;
        return (
            <Grid container item className='mr-auto contact-address-block' xs={12} lg={5}>
                <Grid
                    container
                    item
                    direction='column'
                    className='mb-4'
                    alignContent='center'
                    xs={12}
                    md={6}
                >
                    <p className='text-29 pb-2'>{t('footer.address.companyName')}</p>
                    <p className='text-24'>{t('footer.address.street')}, {t('footer.address.city')}</p>
                    <p className='text-24 pb-4'>{t('footer.address.country')}</p>
                    <p className='text-24'>{t('footer.IC.key')}: {t('footer.IC.value')}</p>
                    <p className='text-24'>{t('footer.DIC.key')}: {t('footer.DIC.value')}</p>
                    <p className='text-30 pt-3'>{t('footer.contacts.web')}</p>
                </Grid>
                <Grid
                    container
                    item
                    direction='column'
                    className='contact-block pr-5 pr-lg-0'
                    alignContent='center'
                    xs={12}
                    md={6}
                >
                    <p className='text-29 pb-2'>{t('footer.contacts.key')}</p>
                    <p className='text-27 text-underline'>{t('footer.contacts.web')}</p>
                    <p className='text-27 text-underline pb-3'>{t('footer.contacts.email')}</p>
                    <p className='text-25 pt-2'>{t('footer.contacts.phone')}</p>
                    <FooterSocialMedia
                        links={links}
                        className='mr-auto social-media-links'
                        isDividerVisible
                        disableContainer
                        md={4}
                        lg
                    />
                </Grid>
            </Grid>
        );
    };
}

export const ContactFormWithAddress = withTranslation('global')(ContactFormWithAddressInternal);
