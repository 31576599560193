const darkBlue = '#283269';

export const stylesLG = {
    // front
    first: {
        width: '26%',
        height: '5px',
        backgroundColor: darkBlue,
    },
    second: {
        width: '37%',
        height: '5px',
        backgroundColor: darkBlue,
    },
    third: {
        width: '32%',
        height: '5px',
        backgroundColor: darkBlue,
    },
};
