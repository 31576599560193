import React from 'react';
import {Grid} from '@material-ui/core';
import ContinueButton from '../../../components/inquiry/continueButton/continueButton';
import {InquiryForm} from '../../../components/inquiry/form/inquiryForm';
import InquiryInput from '../../../components/inquiry/input/inquiryInput';
import ErasingFrame from '../../../components/inquiry/erasingFrame/erasingFrame';
import {withTranslation} from 'react-i18next';

class MovesMigrationInternal extends React.Component {
    title = `4. ${this.props.t('movesMigration.title')}`;

    constructor(props) {
        super(props);
        this.state = {
            moves: props.moves || [],
            moveInput: props.moveInput || '',
        };
    }

    onChangeMoveInput = (target) => {
        this.setState({moveInput: target.value});
    };

    onAddMove = () => {
        const {moves, moveInput} = this.state;
        if (moveInput) {
            let newMoves = moves;
            const key = moves.length !== 0
                ? moves[moves.length - 1].key + 1
                : 0;
            newMoves.push({key, value: moveInput});
            this.setState({moves: newMoves, moveInput: ''});
        }
    };

    onRemoveMove = (key) => {
        const newMoves = this.state.moves.filter((move) => move.key !== key);
        this.setState({moves: newMoves});
    };

    render() {
        const {sliderSettings, onNextClick, onPreviousClick, t} = this.props;
        return (
            <InquiryForm
                title={this.title}
                sliderSettings={sliderSettings}
                onNextClick={onNextClick}
                onPreviousClick={onPreviousClick}
            >
                <Grid
                    container
                    item
                    className='my-3'
                >
                    <Grid
                        item
                        xs={12}
                        md={5}
                        lg={4}
                    >
                        {this.renderAddMoveInput()}
                    </Grid>
                    <Grid
                        item
                        className='mt-3 mt-lg-0 ml-lg-3'
                        xs={12}
                        md={5}
                        lg={4}
                    >
                        <ContinueButton t={t} onClick={onNextClick}/>
                    </Grid>
                </Grid>
            </InquiryForm>
        );
    }

    renderAddMoveInput = () => {
        return (
            <Grid container direction='row'>
                {this.renderMoves()}
                <Grid item xs={12} className='mt-2 mt-md-0'>
                    <InquiryInput
                        placeholder={this.props.t('inputPlaceholder')}
                        onChange={this.onChangeMoveInput}
                        onIconClick={this.onAddMove}
                        value={this.state.moveInput}
                        showIcon
                    />
                </Grid>
            </Grid>
        );
    };

    renderMoves = () => {
        const {moves} = this.state;
        if (moves.length > 0) {
            return moves.map((move, index) => {
                return (
                    <ErasingFrame
                        key={index}
                        text={move.value}
                        onClick={() => this.onRemoveMove(move.key)}
                        className='ml-0'
                    />
                );
            });
        }
    };

    componentWillUnmount() {
        const {moves, moveInput} = this.state;
        this.props.saveMoves(moves);
        this.props.saveMoveInput(moveInput);
    }
}

export const MovesMigration = withTranslation('inquiry')(MovesMigrationInternal);