import React from 'react';
import {Grid} from '@material-ui/core';
import {InquiryForm} from '../../../components/inquiry/form/inquiryForm';
import ContinueButton from '../../../components/inquiry/continueButton/continueButton';
import InquiryInput from '../../../components/inquiry/input/inquiryInput';
import ErasingFrame from '../../../components/inquiry/erasingFrame/erasingFrame';
import {withTranslation} from 'react-i18next';

class CloudImproveInternal extends React.Component {
    title = `4. ${this.props.t('cloudImprove.title')}`;

    constructor(props) {
        super(props);
        this.state = {
            improves: props.improves || [],
            improveInput: props.improveInput || '',
        };
    }


    onChangeImproveInput = (target) => {
        this.setState({improveInput: target.value});
    };

    onAddImprove = () => {
        const {improves, improveInput} = this.state;
        if (improveInput) {
            let newImproves = improves;
            const key = improves.length !== 0
                ? improves[improves.length - 1].key + 1
                : 0;
            newImproves.push({key, value: improveInput});
            this.setState({improves: newImproves, improveInput: ''});
        }
    };

    onRemoveImprove = (key) => {
        const newImproves = this.state.improves.filter((improve) => improve.key !== key);
        this.setState({improves: newImproves});
    };

    render() {
        const {sliderSettings, onNextClick, onPreviousClick, t} = this.props;
        return (
            <InquiryForm
                title={this.title}
                sliderSettings={sliderSettings}
                onNextClick={onNextClick}
                onPreviousClick={onPreviousClick}
            >
                <Grid container item className='my-3'>
                    <Grid item xs={12} md={5} lg={4}>
                        {this.renderAddImproveInput()}
                    </Grid>
                    <Grid item className='mt-3 mt-lg-0 ml-lg-3' xs={12} md={5} lg={4}>
                        <ContinueButton t={t} onClick={onNextClick}/>
                    </Grid>
                </Grid>
            </InquiryForm>
        );
    }

    renderAddImproveInput = () => {
        const {t} = this.props;
        return (
            <Grid container direction='row'>
                {this.renderImproves()}
                <Grid item xs={12} className='mt-2 mt-md-0'>
                    <InquiryInput
                        placeholder={t('inputPlaceholder')}
                        onChange={this.onChangeImproveInput}
                        onIconClick={this.onAddImprove}
                        value={this.state.improveInput}
                        showIcon
                    />
                </Grid>
            </Grid>
        );
    };

    renderImproves = () => {
        const {improves} = this.state;
        if (improves.length > 0) {
            return improves.map((improve, index) => {
                return (
                    <ErasingFrame
                        key={index}
                        text={improve.value}
                        onClick={() => this.onRemoveImprove(improve.key)}
                        className='ml-0'
                    />
                );
            });
        }
    };

    componentWillUnmount() {
        const {improves, improveInput} = this.state;
        this.props.saveImproves(improves);
        this.props.saveImproveinput(improveInput);
    }
}

export const CloudImprove = withTranslation('inquiry')(CloudImproveInternal);