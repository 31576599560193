import React from 'react';
import {useNode, useEditor} from '@craftjs/core';
import {DateSettings} from './dateSettings';
import ContentEditable from 'react-contenteditable';
import {date} from "../../../utils/date";

export const Date
    = ({
           fontSize,
           textAlign,
           fontWeight,
           color,
           shadow,
           text,
           margin,
       }: any) => {
    const {
        connectors: {connect},
        setProp,
    } = useNode();
    const {enabled} = useEditor((state) => ({
        enabled: state.options.enabled,
    }));
    return (
        <ContentEditable
            innerRef={connect}
            html={text}
            disabled={!enabled}
            onChange={(e) => {
                setProp((prop) => (prop.text = e.target.value), 500);
            }}
            tagName='p'
            style={{
                width: '100%',
                margin: `${margin[0]}px ${margin[1]}px ${margin[2]}px ${margin[3]}px`,
                color: `rgba(${Object.values(color)})`,
                fontSize: `${fontSize}px`,
                textShadow: `0px 0px 2px rgba(0,0,0,${(shadow || 0) / 100})`,
                fontFamily: 'WorkSans-Regular',
                lineHeight: 1,
                fontWeight,
                textAlign,
            }}
        />
    );
};

Date.craft = {
    displayName: 'Date',
    props: {
        fontSize: '16',
        textAlign: 'center',
        fontWeight: 'normal',
        color: {r: 40, g: 50, b: 105, a: 0.8},
        margin: [49, 0, 49, 0],
        shadow: 0,
        text: date(),
    },
    related: {
        toolbar: DateSettings,
    },
};
