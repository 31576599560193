import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '../button/button';
import Divider from '../divider/divider';

// INFO:
// a component that describes everything about the service (image and text)
//
// Properties:
// reverse - swipe image and text
// icon - icon of service
// title - title of service
// text - all info about service
// image - image of service
class OurService extends React.Component {

    state = {
        screenWidth: window.innerWidth,
    };

    showDivider = () => {
        return this.state.screenWidth >= 960;
    };

    render() {
        const {reverse} = this.props;
        const showDivider = this.showDivider();
        return (
            <Grid container item className='my-5' direction='row' justify='center'>
                {reverse && showDivider ? this.renderImage() : this.renderServiceContent()}
                {this.renderDivider()}
                {reverse && showDivider ? this.renderServiceContent() : this.renderImage()}
            </Grid>
        );
    }

    renderServiceContent = () => {
        const {redirect, icon, title, text, reverse, t} = this.props;
        const justify = reverse ? 'flex-end' : 'flex-start';
        const textAlign = reverse ? 'text-right' : 'text-left';
        return (
            <Grid
                container
                item
                xs={12}
                md={5}
                className='pt-3 px-md-5'
                spacing={1}
                direction='column'
                justify='center'
            >
                <Grid container item justify={justify}>
                    <img src={icon} className='illustration' alt={title}/>
                </Grid>
                <Grid container item justify={justify}>
                    <p className={`title-3 ${textAlign}`}>{title}</p>
                </Grid>
                <Grid container item>
                    <p className={`text-7 ${textAlign}`}>{text}</p>
                </Grid>
                <Grid container item justify={justify}>
                    <Button
                        showArrow
                        to={`/inquiry/${redirect}`}
                        type='gold'
                        text={t('thirdBlock.buttonText')}
                        onClick={this.props.onRedirect}
                    />
                </Grid>
            </Grid>
        );
    };

    renderDivider = () => {
        if (this.showDivider()) {
            return (
                <Grid container item className='mt-4' justify='center' alignItems='center' xs={1}>
                    <Divider/>
                </Grid>
            );
        } else {
            return null;
        }
    };

    renderImage = () => {
        const {image, title, reverse} = this.props;
        const justify = !reverse ? 'flex-end' : 'flex-start';
        const style = !this.showDivider() && 'mt-5';
        return (
            <Grid
                container
                item
                xs={12}
                md={5}
                className={`${style} mr-n2 px-md-5 our-service-picture-bg`}
                justify={this.showDivider() ? justify : 'center'}
            >
                <img src={image} className='illustration' alt={title}/>
            </Grid>
        );
    };

    componentDidMount() {
        window.addEventListener('resize',
            () => this.setState({screenWidth: window.innerWidth}));
    }

    componentWillUnmount() {
        window.removeEventListener('resize',
            () => this.setState({screenWidth: window.innerWidth}));
    }
}

export default OurService;