import React from 'react';
import Grid from '@material-ui/core/Grid';

// INFO:
// simple card component, which show information
//
// Properties:
// title - set the title to card
// text - set the text to card
class InfoCard extends React.Component {
    render() {
        if (this.props.small) {
            return this.renderSmallSize();
        }
        return this.renderNormalSize();
    }

    renderNormalSize = () => {
        const {title, text, className} = this.props;
        return (
            <div className={`info-card col${' ' + className || ''}`}>
                <p className='text-35'>{title}</p>
                <p className='text-36'>{text}</p>
            </div>
        );
    };

    renderSmallSize = () => {
        const {title, text} = this.props;
        return (
            <Grid
                container
                item
                className='info-card-2 m-auto p-4 py-5'
            >
                <Grid item xs={12}>
                    <p className='text-35'>{title}</p>
                </Grid>
                <Grid item xs={12}>
                    <p className='text-36'>{text}</p>
                </Grid>
            </Grid>
        );
    };
}

export default InfoCard;