import React, {useState, useEffect} from 'react';
import {useEditor} from '@craftjs/core';
import {Toolbox} from './toolbox';
import {Sidebar} from './sidebar/sidebar';
import {Header} from './header';
import {autoSaveSeconds} from '../../../../blog/shared/blogShared';
import cx from 'classnames';

type Props = {
    onEdit?: (base64: string) => void,
    onCancel?: () => void,
    onSave?: () => void,
    children: React.ReactNode,
    isDialogOpen?: boolean,
    infoText?: JSX.Element,
    languageSelector?: JSX.Element,
    autoSaveStatus?: JSX.Element,
    isUsedForBlog?: boolean,
};

export const Viewport: React.FC<Props> = ({
                                              onCancel,
                                              onEdit,
                                              onSave,
                                              children,
                                              infoText,
                                              languageSelector,
                                              autoSaveStatus,
                                              isDialogOpen,
                                              isUsedForBlog,
                                          }) => {
    const {enabled, connectors} = useEditor((state) => ({
        enabled: state.options.enabled,
    }));
    const [loaded, setLoaded] = useState(false);
    const [mouseEnabled, setMouseEnabled] = useState(false);
    let unmounted = false;
    useEffect(() => {
        setTimeout(() => {
            if (!unmounted) setLoaded(true);
            setTimeout(() => {
                if (
                    localStorage &&
                    localStorage.getItem('craftjs-demo-notice') !== 'set'
                ) {
                    localStorage.setItem('craftjs-demo-notice', 'set');
                }
                setTimeout(() => {
                    if (!unmounted) setMouseEnabled(true);
                }, 200);
            }, 400);
        }, 1000);

        return () => {
            // eslint-disable-next-line
            unmounted = true;
        };
        // eslint-disable-next-line
    }, [unmounted]);
    return (
        <div
            className={cx(['viewport'], {
                loaded: loaded,
                'mouse-enabled': mouseEnabled,
            })}
        >
            <Header
                onCancel={onCancel}
                onEdit={onEdit}
                onSave={onSave}
                infoText={infoText}
                languageSelector={languageSelector}
                autoSaveStatus={autoSaveStatus}
                isDialogOpen={isDialogOpen}
                autoSaveSeconds={autoSaveSeconds}
                isUsedForBlog={isUsedForBlog}
            />
            <div
                style={{paddingTop: '59px'}}
                className={cx([
                    'flex h-full overflow-hidden flex-row w-full',
                    {
                        'h-full': !enabled,
                        fixed: enabled,
                        relative: !enabled,
                    },
                ])}
            >
                <Toolbox/>
                <div className='flex-1 h-full'>
                    <div className='w-full h-full'>
                        <div
                            className={cx([
                                'craftjs-renderer h-full  w-full transition',
                                {
                                    'overflow-auto': enabled,
                                    'bg-renderer-gray': enabled,
                                },
                            ])}
                            ref={(ref) =>
                                connectors.select(connectors.hover(ref, null), null)
                            }
                        >
                            <div
                                className={cx([
                                    'relative flex-col flex items-center pb-8',
                                    {
                                        'pt-8': enabled,
                                    },
                                ])}
                            >
                                {children}
                            </div>
                        </div>
                    </div>
                </div>
                <Sidebar/>
            </div>
        </div>
    );
};
