import React from 'react';
import Grid from '@material-ui/core/Grid';
import BlogTable from '../../../components/blog/admin/table/blogTable';
import BlogToolbar from '../../../components/blog/admin/toolbar/blogToolbar';
import BlogRemoveDialog from '../../../components/blog/admin/removeDialog/blogRemoveDialog';
import backward from '../../../assets/form-backward.png';
import LanguageSelectDialog from '../../../components/languageSelectDialog/languageSelectDialog';
import {defaultArticle} from '../../../components/blog/shared/blogShared';

class BlogAdmin extends React.Component {

    state = {
        showRemoveDialog: false,
        showLanguageSelectDialog: false,
    };

    title = 'Blog management';
    backToHomepage = 'Back to homepage';
    selectedArticle = null;
    articleLanguages = null;

    showLanguageDialog = (languages, article) => {
        this.articleLanguages = languages;
        this.selectedArticle = article;
        this.setState({showLanguageSelectDialog: true});
    };

    showRemoveDialog = (article) => {
        this.selectedArticle = article;
        this.setState({showRemoveDialog: true});
    };

    onAddNewLanguage = (language) => {
        if (this.selectedArticle && this.selectedArticle.id) {
            const newArticle = {
                ...defaultArticle,
                language,
                article: this.selectedArticle.article,
            };
            const callback = this.setState({showLanguageSelectDialog: false});
            this.props.onCreateArticle && this.props.onCreateArticle(newArticle, callback);
        }
    };

    render() {
        const {onRemoveArticle} = this.props;
        const {showRemoveDialog, showLanguageSelectDialog} = this.state;
        return (
            <Grid container className='background-3 p-4 h-100'>
                {showLanguageSelectDialog
                && <LanguageSelectDialog
                    show={showLanguageSelectDialog}
                    languages={this.articleLanguages}
                    onClose={() => this.setState({showLanguageSelectDialog: false})}
                    onAddClick={this.onAddNewLanguage}
                />}
                {this.selectedArticle
                && <BlogRemoveDialog
                    show={showRemoveDialog}
                    selectedArticle={this.selectedArticle}
                    onDisagree={() => this.setState({showRemoveDialog: false})}
                    onAgree={onRemoveArticle}
                />}
                {this.renderTitleBar()}
                <Grid item container xs={12}>
                    {this.renderApiError()}
                    {this.renderToolbar()}
                    {this.renderTable()}
                </Grid>
            </Grid>
        );
    }

    renderTitleBar = () => {
        const blogUrl = '/blog';
        return (
            <Grid item container className='mt-5 mb-4' xs={12}>
                <h1 className='text-title-universal'>{this.title}</h1>
                <a href={blogUrl} className='text-back-to-homepage ml-auto mt-2'>
                    <img src={backward} alt='backward' className='mr-2'/>
                    {this.backToHomepage}
                </a>
            </Grid>
        );
    };

    renderApiError = () => {
        const {renderApiError} = this.props;
        if (renderApiError) {
            return (
                <Grid item xs={6} className='pt-3 pb-2'>
                    {renderApiError}
                </Grid>
            );
        }
        return null;
    };

    renderToolbar = () => {
        const {isApiEnabled} = this.props;
        if (isApiEnabled) {
            return (
                <Grid item xs className='mb-4'>
                    <BlogToolbar {...this.props}/>
                </Grid>
            );
        }
        return null;
    };

    renderTable = () => {
        const {articles} = this.props;
        if (articles && articles.length > 0) {
            const articlesWithIndex = articles.map((item, index) => {
                return {id: index, ...item};
            });
            return (
                <Grid item xs={12}>
                    <BlogTable
                        {...this.props}
                        data={articlesWithIndex}
                        onRemoveArticle={this.showRemoveDialog}
                        onAddNewLanguage={this.showLanguageDialog}
                    />
                </Grid>
            );
        }
        return null;
    };
}

export default BlogAdmin;