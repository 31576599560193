import React from 'react';
import ArrowNext from '../../../assets/button-arrow-white.png';

// INFO:
//
// Properties:
// style - adds another style next to existing
// onClick - function, is trigger when click on button
class ContinueButton extends React.Component {
    render() {
        const {t, className, lastStep} = this.props;
        return (
            <button
                className={`continue-button ${className}`}
                type='submit'
            >
                {lastStep ? t('send') : t('continue')}
                <img src={ArrowNext} alt='button-arrow-next' className='ml-3'/>
            </button>
        );
    }
}

export default ContinueButton;