import React from 'react';
import {Grid} from '@material-ui/core';
import {InquiryForm} from '../../../../components/inquiry/form/inquiryForm';
import InquiryInput from '../../../../components/inquiry/input/inquiryInput';
import ContinueButton from '../../../../components/inquiry/continueButton/continueButton';
import {withTranslation} from 'react-i18next';

class ContactInformationInternal extends React.Component {
    title = `${this.props.screenNumber}. ${this.props.t('contactInformation.title')}`;

    screenWidthBreakpoint = 905;

    constructor(props) {
        super(props);
        this.state = {
            screenWidth: window.innerWidth,
            companyInput: props.companyInput || '',
            emailInput: props.emailInput || '',
        };
    }

    onChangeCompany = (target) => {
        this.props.saveCompanyInput(target.value);
        this.setState({companyInput: target.value});
    };

    onChangeEmail = (target) => {
        this.props.saveEmailInput(target.value);
        this.setState({emailInput: target.value});
    };

    render() {
        const {sliderSettings, onNextClick, onPreviousClick, t} = this.props;
        const {companyInput, emailInput} = this.state;
        return (
            <InquiryForm
                title={this.title}
                sliderSettings={sliderSettings}
                onNextClick={onNextClick}
                onPreviousClick={onPreviousClick}
            >
                <Grid
                    container
                    item
                    className='my-4 mb-5'
                >
                    <Grid container item className='mb-3' xs={12}>
                        <InquiryInput
                            placeholder={t('contactInformation.company')}
                            onChange={this.onChangeCompany}
                            value={companyInput}
                            required
                        />
                        {this.state.screenWidth > this.screenWidthBreakpoint
                        && <ContinueButton t={t} onClick={onNextClick} className='ml-3' lastStep/>}
                    </Grid>
                    <Grid item xs={12}>
                        <InquiryInput
                            placeholder={t('contactInformation.email')}
                            onChange={(this.onChangeEmail)}
                            value={emailInput}
                            required
                        />
                    </Grid>
                    {this.state.screenWidth < this.screenWidthBreakpoint
                    && <ContinueButton t={t} onClick={onNextClick} className='mt-3' lastStep/>}
                </Grid>
            </InquiryForm>
        );
    }

    componentDidMount() {
        window.addEventListener('resize',
            () => this.setState({screenWidth: window.innerWidth}));
    }
}


export const ContactInformation = withTranslation('inquiry')(ContactInformationInternal);