import React from 'react';
import Grid from '@material-ui/core/Grid';

class CookiesEN extends React.Component {
    render() {
        const {t} = this.props;
        return (
            <Grid
                item
                container
                direction='column'
                alignContent='center'
                xs={12}
            >
                <p className='text-52 pt-4' style={{marginTop: '100px'}}>{t('first')}</p>
                <p className='text-52 pt-4'>{t('second')}</p>
                {this.renderThird()}
                {this.renderFourth()}
                {this.renderFifth()}
            </Grid>
        );
    }

    renderThird = () => {
        const {t} = this.props;
        return (
            <React.Fragment>
                <h2 className='text-55 pt-4'>{t('third.title')}</h2>
                <p className='text-52 pt-4'>{t('third.first.title')}</p>
                <p className='text-54 pt-2 pl-3'>{t('third.first.first')}</p>
            </React.Fragment>
        );
    };

    renderFourth = () => {
        const {t} = this.props;
        return (
            <React.Fragment>
                <h2 className='text-55 pt-4'>{t('fourth.title')}</h2>
                <p className='text-52 pt-4'>{t('fourth.first.title')}</p>
                <p className='text-54 pt-2 pl-3'>{t('fourth.first.first')}</p>
                <p className='text-54 pt-2 pl-3'>{t('fourth.first.second')}</p>
            </React.Fragment>
        );
    };

    renderFifth = () => {
        const {t} = this.props;
        return (
            <React.Fragment>
                <h2 className='text-55 pt-4'>{t('fifth.title')}</h2>
                <p className='text-52 pt-4'>{t('fifth.first')}</p>
            </React.Fragment>
        );
    };
}

export default CookiesEN;