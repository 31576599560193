import React from 'react';
import {getFileFromBinaryData} from '../../../../utils/utils';
import ShowCaseStudyButton from './showCaseStudyButton/showCaseStudyButton';

class ReferenceUI extends React.Component {

    getCompanyLogo = () => {
        const {reference} = this.props;
        const isCompanyLogoExist = reference && reference.referenceCompanyLogo;
        let targetCompanyLogo;
        if (isCompanyLogoExist && reference.referenceCompanyLogo.data) {
            const logo = reference.referenceCompanyLogo;
            if (logo.data.name && typeof logo.data.name === 'string') {
                targetCompanyLogo = logo.data;
            } else {
                const logoFromBinary = getFileFromBinaryData(logo);
                targetCompanyLogo = logoFromBinary ? logoFromBinary : null;
            }
        }
        return targetCompanyLogo;
    };

    render() {
        const {reference} = this.props;
        return (
            <div className='reference-container'>
                {this.renderCompanyLogo()}
                <p className='text-63'>
                    {reference.referenceText}
                </p>
                <p className='text-64'>
                    {reference.clientName}
                </p>
                <p className='text-65'>
                    {reference.clientRole}
                </p>
                {this.renderCaseStudyButton()}
            </div>
        );
    }

    renderCompanyLogo = () => {
        const companyLogo = this.getCompanyLogo();
        if (companyLogo) {
            return (
                <div className='company-logo-container'>
                    <a href={this.props.reference.companyWebUrl} target='_blank' rel="noreferrer">
                        <img className='company-logo-img' src={URL.createObjectURL(companyLogo)} alt=''/>
                    </a>
                </div>
            );
        }
        return null;
    };

    renderCaseStudyButton = () => {
        const {t, reference, onClickCaseStudy} = this.props;
        const caseStudy = reference.referenceCaseStudy;
        if (caseStudy && caseStudy.id) {
            return <ShowCaseStudyButton t={t} onClick={() => onClickCaseStudy(caseStudy.id)}/>;
        }
        return null;
    };
}

export default ReferenceUI;