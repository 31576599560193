import React from 'react';
import Grid from '@material-ui/core/Grid';

class PrivacyPolicyEN extends React.Component {
    render() {
        const {t} = this.props;
        return (
            <Grid
                item
                container
                direction='column'
                alignContent='center'
                xs={12}
            >
                <p className='text-52 pt-4' style={{marginTop: '100px'}}>{t('welcome.explain')}</p>
                {this.renderFirst()}
                {this.renderSecond()}
                {this.renderThird()}
                {this.renderFourth()}
                {this.renderFifth()}
                {this.renderSixth()}
                {this.renderSeventh()}
                {this.renderEight()}
                {this.renderNinth()}
            </Grid>
        );
    }

    renderFirst = () => {
        const {t} = this.props;
        return (
            <React.Fragment>
                <h2 className='text-55 pt-4'>{t('I.title')}</h2>
                <p className='text-54 pt-2 pl-3'>{t('I.first')}</p>
                <p className='text-54 pt-2 pl-3'>{t('I.second')}</p>
                <p className='text-54 pt-2 pl-3'>{t('I.third')}</p>
            </React.Fragment>
        );
    };

    renderSecond = () => {
        const {t} = this.props;
        return (
            <React.Fragment>
                <h2 className='text-55 pt-4'>{t('II.title')}</h2>
                <p className='text-52 pt-4'>{t('II.first.title')}</p>
                <p className='text-54 pt-2 pl-3'>{t('II.first.first')}</p>
                <p className='text-54 pt-2 pl-3'>{t('II.first.second')}</p>
                <p className='text-54 pt-2 pl-3'>{t('II.first.third')}</p>
            </React.Fragment>
        );
    };

    renderThird = () => {
        const {t} = this.props;
        return (
            <React.Fragment>
                <h2 className='text-55 pt-4'>{t('III.title')}</h2>
                <p className='text-52 pt-4'>{t('III.first.title')}</p>
                <p className='text-54 pt-2 pl-3'>{t('III.first.first')}</p>
                <p className='text-54 pt-2 pl-3'>{t('III.first.second')}</p>
                <p className='text-54 pt-2 pl-3'>{t('III.first.third')}</p>
                <p className='text-54 pt-2 pl-3'>{t('III.first.fourth')}</p>
                <p className='text-54 pt-2 pl-3'>{t('III.first.fifth')}</p>
                <p className='text-54 pt-2 pl-3'>{t('III.first.sixth')}</p>
            </React.Fragment>
        );
    };

    renderFourth = () => {
        const {t} = this.props;
        return (
            <React.Fragment>
                <h2 className='text-55 pt-4'>{t('IV.title')}</h2>
                <p className='text-52 pt-4'>{t('IV.first')}</p>
                <p className='text-52 pt-4'>{t('IV.second')}</p>
            </React.Fragment>
        );
    };

    renderFifth = () => {
        const {t} = this.props;
        return (
            <React.Fragment>
                <h2 className='text-55 pt-4'>{t('V.title')}</h2>
                <p className='text-52 pt-4'>{t('V.first.title')}</p>
                <p className='text-54 pt-2 pl-3'>{t('V.first.first')}</p>
                <p className='text-54 pt-2 pl-3'>{t('V.first.second')}</p>
                <p className='text-54 pt-2 pl-3'>{t('V.first.third')}</p>
                <p className='text-54 pt-2 pl-3'>{t('V.first.fourth')}</p>
                <p className='text-54 pt-2 pl-3'>{t('V.first.fifth')}</p>
                <p className='text-54 pt-2 pl-3'>{t('V.first.sixth')}</p>
                <p className='text-54 pt-2 pl-3'>{t('V.first.seventh')}</p>
                <p className='text-54 pt-2 pl-3'>{t('V.first.contact.phone')}</p>
                <p className='text-54 pt-2 pl-3'>{t('V.first.contact.address')}</p>
            </React.Fragment>
        );
    };

    renderSixth = () => {
        const {t} = this.props;
        return (
            <React.Fragment>
                <h2 className='text-55 pt-4'>{t('VI.title')}</h2>
                <p className='text-52 pt-4'>{t('VI.first')}</p>
            </React.Fragment>
        );
    };

    renderSeventh = () => {
        const {t} = this.props;
        return (
            <React.Fragment>
                <h2 className='text-55 pt-4'>{t('VII.title')}</h2>
                <p className='text-52 pt-4'>{t('VII.first')}</p>
            </React.Fragment>
        );
    };

    renderEight = () => {
        const {t} = this.props;
        return (
            <React.Fragment>
                <h2 className='text-55 pt-4'>{t('VIII.title')}</h2>
                <p className='text-52 pt-4'>{t('VIII.first.title')}</p>
                <p className='text-54 pt-2 pl-3'>{t('VIII.first.contact.email')}</p>
                <p className='text-54 pt-2 pl-3'>{t('VIII.first.contact.phone')}</p>
                <p className='text-54 pt-2 pl-3'>{t('VIII.first.contact.address')}</p>
            </React.Fragment>
        );
    };

    renderNinth = () => {
        const {t} = this.props;
        return (
            <React.Fragment>
                <h2 className='text-55 pt-4'>{t('IX.title')}</h2>
                <p className='text-52 pt-4'>{t('IX.first')}</p>
            </React.Fragment>
        );
    };
}

export default PrivacyPolicyEN;