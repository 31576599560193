import React from 'react';
import Checkbox from '../form/checkbox/checkbox';
import {NavLink} from 'react-router-dom';

// INFO:
// a component that confirms the terms of the contract
//
// Properties:
// checkboxName - name of checkbox
// checkboxValue - true or false value
// onChange - function, is trigger when click on checkbox
class PersonalDataConsent extends React.Component {
    render() {
        return (
            <React.Fragment>
                <Checkbox
                    label={this.renderRulesText()}
                    name={this.props.checkboxName}
                    checked={this.props.checkboxValue}
                    onChange={this.props.onChangeCheckbox}
                    className='checkbox-block'
                />
            </React.Fragment>
        );
    }

    renderRulesText = () => {
        const {t, isQuestionForm} = this.props;
        const text = isQuestionForm ? 'questionForm' : 'contactForm';
        return (
            <React.Fragment>
                <p className='text-16 mr-1'>{t(`${text}.personalDataConsentText.firstText`)}</p>
                <NavLink
                    exact
                    to='/privacy-policy'
                    className='text-17'
                >
                    {t(`${text}.personalDataConsentText.secondText`)}
                </NavLink>
                {this.renderWarningText()}
            </React.Fragment>
        );
    };

    renderWarningText = () => {
        const {t, isQuestionForm, showWarning} = this.props;
        const text = isQuestionForm ? 'questionForm' : 'contactForm';
        if (showWarning) {
            return <p className='text-49'>{t(`${text}.personalDataConsentText.error`)}</p>;
        }
        return null;
    };
}

export default PersonalDataConsent;
