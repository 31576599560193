import React from 'react';
import {withTranslation} from 'react-i18next';
import {companyInfo} from '../../config/companyInfo';
import Footer from '../../components/footer/footer';
import LogoBigger from '../../assets/SRM logo Flat bigger.png';
import {config} from '../../config/config';

class FooterProviderInternal extends React.Component {
    render() {
        const {t} = this.props;
        const links = companyInfo.links;
        const privacyPolicy = config.features.legalDocuments.privacyPolicy.allow && {
            text: t('footer.buttons.privacyPolicy'),
            href: '/privacy-policy',
        };
        const termsAndConditions = config.features.legalDocuments.termsAndConditions.allow && {
            text: t('footer.buttons.termsAndConditions'),
            href: '/terms',
        };
        const certification = config.features.legalDocuments.certification.allow && {
            text: t('footer.buttons.certification'),
            style: '',
            href: '',
        };
        const cookiesUse = config.features.legalDocuments.cookiesUse.allow && {
            text: t('footer.buttons.cookiesUse'),
            style: '',
            href: '/cookies',
        };
        const texts = {
            newsletter: {
                title: t('footer.newsletter.title'),
                emailMessage: t('footer.newsletter.emailMessage'),
                subTitle: t('footer.newsletter.subTitle'),
                success: t('footer.newsletter.success'),
                unsuccessful: t('footer.newsletter.unsuccessful'),
                badEmail: t('footer.newsletter.badEmail'),
            },
            whoWeAre: t('footer.whoWeAre'),
            address: {
                key: t('footer.address.key'),
                companyName: t('footer.address.companyName'),
                street: t('footer.address.street'),
                city: t('footer.address.city'),
                country: t('footer.address.country'),
            },
            IC: {
                key: t('footer.IC.key'),
                value: t('footer.IC.value'),
            },
            DIC: {
                key: t('footer.DIC.key'),
                value: t('footer.DIC.value'),
            },
            contacts: {
                key: t('footer.contacts.key'),
                web: t('footer.contacts.web'),
                email: t('footer.contacts.email'),
                phone: t('footer.contacts.phone'),
            },
            buttons: [privacyPolicy, termsAndConditions, certification, cookiesUse],
            links,
        };
        return <Footer logo={LogoBigger} texts={texts} links={links}/>;
    }
}

export const FooterProvider = withTranslation('global')(FooterProviderInternal);