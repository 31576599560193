import axios from 'axios';
import {web as webConfig} from '../../config/web';

export const noBlogApiConnectionText = 'ERROR: Blog API is not available';

console.debug('API_URL:', webConfig.api.url);

const blogApiClient = axios.create({
    baseURL: webConfig.api.url,
    timeout: webConfig.api.clientTimeout,
    headers: {
        'Content-Type': 'multipart/form-data',
    },
});

// log errors
function logError(response) {
    if (response.status !== 200 && response !== 301) {
        console.error(response.data.message);
    }
}

function handleError(error) {
    if (error && error.response) {
        return error.response.data;
    }
    return {error: true, fatalError: true, message: noBlogApiConnectionText};
}

export async function fetchAllArticles() {
    try {
        const response = await blogApiClient.get('/blog/article/all');
        logError(response);
        return response.data;
    } catch (error) {
        return handleError(error);
    }
}

export async function fetchArticleContent(slug) {
    try {
        const response = await blogApiClient.get(`/blog/article/content/${slug}`);
        logError(response);
        return response.data;
    } catch (error) {
        return handleError(error);
    }
}

export async function fetchAllArticlesPreviews(language) {
    try {
        const response = await blogApiClient.get(`/blog/article/preview/all/?language=${language || 'any'}`);
        logError(response);
        return response.data;
    } catch (error) {
        return handleError(error);
    }
}

export async function fetchAllArticlesPreviewsByPublicId(publicId) {
    try {
        const response = await blogApiClient.get(`/blog/article/${publicId}`);
        logError(response);
        return response.data;
    } catch (error) {
        return handleError(error);
    }
}

export async function createArticle(data) {
    return await blogApiClient.post('/blog/article', data)
        .then((response) => {
            logError(response);
            return response.data;
        })
        .catch((error) => {
            return handleError(error);
        });
}

export async function updateArticle(id, data) {
    try {
        const response = await blogApiClient.put(`/blog/article/content/${id}`, data);
        logError(response);
        return response.data;
    } catch (error) {
        return handleError(error);
    }
}

export async function removeArticle(id) {
    try {
        const response = await blogApiClient.delete(`/blog/article/content/${id}`);
        logError(response);
        return response.data;
    } catch (error) {
        return handleError(error);
    }
}
