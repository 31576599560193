import React from 'react';
import {Editor, Frame} from '@craftjs/core';
import {editorResolvers} from '../../../editor/editor';
import Grid from '@material-ui/core/Grid';
import lz from 'lzutf8';
import {Link} from 'react-router-dom';
import {getFileFromBinaryData} from '../../../../utils/utils';
import Button from '../../../button/button';

class CaseStudyUI extends React.Component {

    state = {
        screenWidth: window.innerWidth,
    };

    widthBreakpoint = 1300;

    canSwipeContent = () => {
        const {swipe} = this.props;
        const isSmallestScreen = this.state.screenWidth < this.widthBreakpoint;
        return swipe && !isSmallestScreen;
    };

    isInquiryTypeExist = () => {
        const {caseStudy} = this.props;
        if (!caseStudy) {
            return false;
        }
        if (caseStudy && !caseStudy.inquiryType) {
            return false;
        }
        return !(caseStudy && caseStudy.inquiryType === 'none');

    };

    getPreviewImage = () => {
        const caseStudy = this.props.caseStudy;
        const isPreviewImageExist = caseStudy && caseStudy.caseStudyPreviewImage;
        let targetPreviewImage;
        if (isPreviewImageExist && caseStudy.caseStudyPreviewImage.data) {
            const previewImage = caseStudy.caseStudyPreviewImage;
            if (previewImage.data.name && typeof previewImage.data.name === 'string') {
                targetPreviewImage = previewImage.data;
            } else {
                const logoFromBinary = getFileFromBinaryData(previewImage);
                if (logoFromBinary) {
                    targetPreviewImage = logoFromBinary;
                }
            }
        }
        return targetPreviewImage;
    };

    getContent = () => this.props.caseStudy.content;

    render() {
        const {caseStudy, isFirstItem} = this.props;
        if (caseStudy && caseStudy.id) {
            const swipe = this.canSwipeContent();
            const itemList = [this.renderContent(), this.renderPreviewImage()];
            const firstItem = swipe ? itemList[0] : itemList[1];
            const secondItem = swipe ? itemList[1] : itemList[0];
            return (
                <Grid
                    container
                    item
                    xs={12}
                    justify='center'
                    className={!isFirstItem ? 'case-study-mt' : ''}
                >
                    {firstItem}
                    {secondItem}
                </Grid>
            );
        }
        return null;
    }

    renderPreviewImage = () => {
        const previewImage = this.getPreviewImage();
        if (previewImage) {
            return (
                <Grid item className='case-study-preview-image-container'>
                    <img src={URL.createObjectURL(previewImage)} className='company-logo-img' alt=''/>
                </Grid>
            );
        }
        return null;
    };

    renderContent = () => {
        const content = this.getContent();
        if (content) {
            return (
                <div className='case-study-preview-content-container'>
                    <Editor resolver={editorResolvers} enabled={false}>
                        <Frame data={lz.decompress(lz.decodeBase64(content))}/>
                    </Editor>
                    {this.renderButtons()}
                </div>
            );
        }
        return null;
    };

    renderButtons = () => {
        const {caseStudy} = this.props;
        const isInquiryTypeExist = this.isInquiryTypeExist();
        if (caseStudy.blogArticleUrl || isInquiryTypeExist) {
            return (
                <Grid item xs={12} className='case-study-redirect-container'>
                    <div className='case-study-line'/>
                    <div className='case-study-article-button flew row'>
                        {caseStudy.blogArticleUrl && this.renderBlogArticleUrlButton()}
                        {isInquiryTypeExist && this.renderInquiryTypeButton()}
                    </div>
                </Grid>
            );
        }
        return null;
    };

    renderBlogArticleUrlButton = () => {
        const {t, caseStudy} = this.props;
        const blogArticleUrl = caseStudy.blogArticleUrl;
        const urlHack = document.createElement('a');
        urlHack.href = blogArticleUrl;
        return (
            <Button
                to={urlHack.pathname || blogArticleUrl}
                showArrow
                type='gold'
                text={t('articleButton')}
            />
        );
    };

    renderInquiryTypeButton = () => {
        const {t, caseStudy} = this.props;
        const isBlogArticleUrlExist = !!caseStudy.blogArticleUrl;
        const inquiryType = caseStudy.inquiryType;
        return (
            <Link
                to={`/inquiry/${inquiryType}`}
                className={`text-decoration-none mt-4 mt-sm-2 text-same-solution ${isBlogArticleUrlExist && 'ml-sm-5'}`}
            >
                {t('sameSolution')}
            </Link>
        );
    };

    componentDidMount() {
        window.addEventListener('resize',
            () => this.setState({screenWidth: window.innerWidth}));
    }

    componentWillUnmount() {
        window.removeEventListener('resize',
            () => this.setState({screenWidth: window.innerWidth}));
    }
}

export default CaseStudyUI;