import React, {useEffect, useState} from 'react';
import {useNode, useEditor} from '@craftjs/core';
import {TitleSettings} from './titleSettings';
import ContentEditable from 'react-contenteditable';

export const Title
    = ({
           fontSize,
           textAlign,
           fontWeight,
           color,
           shadow,
           text,
           margin,
       }: any) => {
    const {
        connectors: {connect},
        setProp,
    } = useNode();
    const {enabled} = useEditor((state) => ({
        enabled: state.options.enabled,
    }));
    const size = useWindowSize();
    let targetFontSize = size.width < 450 ? fontSize / 1.3 : fontSize;
    return (
        <ContentEditable
            innerRef={connect}
            html={text}
            disabled={!enabled}
            onChange={(e) => {
                setProp((prop) => (prop.text = e.target.value), 500);
            }}
            tagName='h2'
            style={{
                width: '100%',
                margin: `${margin[0]}px ${margin[1]}px ${margin[2]}px ${margin[3]}px`,
                color: `rgba(${Object.values(color)})`,
                fontSize: `${targetFontSize}px`,
                textShadow: `0px 0px 2px rgba(0,0,0,${(shadow || 0) / 100})`,
                fontFamily: 'WorkSans-Regular',
                lineHeight: 1,
                fontWeight,
                textAlign,
            }}
        />
    );
};

const useWindowSize = () => {
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
    });
    useEffect(() => {
        const handleResize = () => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    return windowSize;
}

Title.craft = {
    displayName: 'Title',
    props: {
        fontSize: '50',
        textAlign: 'center',
        fontWeight: '600',
        color: {r: 40, g: 50, b: 105, a: 1},
        margin: [0, 0, 0, 0],
        shadow: 0,
        text: 'Title',
    },
    related: {
        toolbar: TitleSettings,
    },
};
