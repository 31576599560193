import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/styles';
import {styles} from './styles';

// INFO:
// simple verticalSlider which contains 3 values
//
// Properties:
// classes - style for values
// index - current slide
class VerticalSlider extends React.Component {

    onMouseEnter = (index) => {
        this.props.onHover(index);
        this.props.onMouseMove(true);
    };
    onMouseLeave = () => {
        this.props.onMouseMove(false);
    };

    render() {
        const {index, classes} = this.props;
        const activeOpacity = '0.97';
        const noActiveOpacity = '0.2';
        const firstOpacity = index === 0
            ? activeOpacity
            : noActiveOpacity;
        const secondOpacity = index === 1
            ? activeOpacity
            : noActiveOpacity;
        const thirdOpacity = index === 2
            ? activeOpacity
            : noActiveOpacity;
        return (
            <Fragment>
                <div
                    onMouseEnter={() => this.onMouseEnter(0)}
                    onMouseLeave={this.onMouseLeave}
                    className={classes.first}
                    style={{opacity: firstOpacity}}
                />
                <div
                    onMouseEnter={() => this.onMouseEnter(1)}
                    onMouseLeave={this.onMouseLeave}
                    className={classes.second}
                    style={{opacity: secondOpacity}}
                />
                <div
                    onMouseEnter={() => this.onMouseEnter(2)}
                    onMouseLeave={this.onMouseLeave}
                    className={classes.third}
                    style={{opacity: thirdOpacity}}
                />
            </Fragment>
        );
    }
}

VerticalSlider.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(VerticalSlider);
