import React from 'react';
import {Grid} from '@material-ui/core';
import {NavLink} from 'react-router-dom';
import InquirySlider from '../../../../components/inquiry/slider/slider';
import icon from '../../../../assets/form-backward.png';
import {withTranslation} from 'react-i18next';

class InquiryFinishInternal extends React.Component {
    widthBreakpoint = 750;
    state = {
        screenWidth: this.widthBreakpoint + 1,
    };

    get isScreenSmall() {
        return this.state.screenWidth < this.widthBreakpoint;
    }

    render() {
        const inquiryFormStyle = this.isScreenSmall
            ? 'inquiry-form inquiry-form-smaller'
            : 'inquiry-form';
        return (
            <Grid
                container
                item
                className={inquiryFormStyle}
            >
                {this.renderIntroductoryText()}
                {this.renderSlider()}
                {this.renderBackButton()}
            </Grid>
        );
    }

    renderIntroductoryText = () => {
        const {t} = this.props;
        const greetingTextStyle = this.isScreenSmall
            ? 'text-37 text-37-smaller'
            : 'text-37';
        const introductoryTextStyle = this.isScreenSmall
            ? 'text-38 text-38-smaller'
            : 'text-38';
        return (
            <Grid
                container
                item
                xs={12}
                direction='column'
            >
                <p className={greetingTextStyle}>{t('inquiryFinish.greeting')}</p>
                <p className={introductoryTextStyle}>{t('inquiryFinish.introductory')}</p>
                <div className='row mt-4 ml-1'>
                    <p className={introductoryTextStyle}>{t('inquiryFinish.reference')}</p>
                    <NavLink
                        exact
                        to='/references'
                        className={introductoryTextStyle + ' ml-sm-2 text-underline text-43'}
                    >
                        {t('inquiryFinish.ourReference')}
                    </NavLink>
                    <p className={introductoryTextStyle}>.</p>
                </div>
            </Grid>
        );
    };

    renderSlider = () => {
        return <InquirySlider total={1} current={1}/>;
    };

    renderBackButton = () => {
        const {t} = this.props;
        return (
            <NavLink
                exact
                to='/'
            >
                <img src={icon} alt='back-img'/>
                <p className='text-44'>{t('inquiryFinish.backButton')}</p>
            </NavLink>
        );
    };

    componentDidMount() {
        window.addEventListener('resize',
            () => this.setState({screenWidth: window.innerWidth}));
    }
}

export const InquiryFinish = withTranslation('inquiry')(InquiryFinishInternal);