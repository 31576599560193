import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from 'react-router-dom';
import {Inquiry} from './screens/inquiry/inquiry';
import {NavbarProvider} from './providers/navbar/navbarProvider';
import {FooterProvider} from './providers/footer/footerProvider';
import OurServices from './screens/ourServices/ourServices';
import {Contacts} from './screens/contacts/contacts';
import {Reference} from './screens/reference/reference';
import {Blog} from './screens/blog/blog';
import {FrequentlyAskedQuestions} from './screens/frequentlyAskedQuestions/frequentlyAskedQuestions';
import {PrivacyPolicy} from './screens/privacyPolicy/privacyPolicy';
import {Cookies as CookiesComponent} from './screens/cookies/cookies';
import {config} from './config/config';
import {TermsAndConditions} from './screens/termsAndConditions/termsAndConditions';
import {ArticlePageProvider} from './providers/blog/articlePage/articlePageProvider';
import ArticleEditorProvider from './providers/blog/articleEditor/articleEditorProvider';
import BlogAdminProvider from './providers/blog/admin/blogAdminProvider';
import ReferenceAdminProvider from './providers/reference/admin/referenceAdminProvider';
import {CookiesConsentBanner} from './components/cookieConsent/cookieConsentBanner';
import CaseStudyEditorProvider from './providers/caseStudy/editor/caseStudyEditorProvider';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './App.scss';
import LoadingScreen from './components/emptyScreen/loadingScreen';

class App extends React.Component {

    showNavbarAndFooter = () => {
        const location = window.location.href.toString();
        return location.includes('/blog/admin/editor')
            || location.includes('/blog/admin')
            || location.includes('/references/admin/case-study/editor')
            || location.includes('/references/admin');
    };

    render() {
        return this.renderRouter();
    };

    renderRouter = () => {
        if (this.props.isApiWork !== null) {
            const showNavbarAndFooter = this.showNavbarAndFooter();
            return (
                <Router>
                    {!showNavbarAndFooter && this.renderNavBar()}
                    {this.renderSwitch()}
                    {!showNavbarAndFooter && this.renderFooter()}
                    <CookiesConsentBanner/>
                </Router>
            );
        }
        return <LoadingScreen/>;
    };

    renderNavBar = () => <NavbarProvider isApiWork={this.props.isApiWork}/>;

    renderSwitch = () => {
        const {isApiWork, randomReference} = this.props;
        const isBlogAllow = isApiWork && config.features.blog.allow;
        const isReferenceAllow = isApiWork && config.features.references.allow;
        return (
            <Switch>
                <Route
                    exact
                    path={['/inquiry/:service', '/inquiry']}
                    render={() => <Inquiry/>}
                />
                <Route
                    exact
                    path='/contacts'
                    render={() => <Contacts/>}
                />
                <Route
                    exact
                    path='/faq'
                    render={() => <FrequentlyAskedQuestions/>}
                />
                {isBlogAllow
                && <Route
                    exact
                    path='/blog'
                    render={(props) => <Blog {...props}/>}
                />}
                {isBlogAllow && <Route
                    exact
                    path='/blog/admin'
                    render={(props) => <BlogAdminProvider {...props}/>}
                />}
                {isBlogAllow
                && <Route
                    path='/blog/article/:slug'
                    render={(props) => <ArticlePageProvider {...props}/>}
                />}
                {isBlogAllow
                && <Route
                    path='/blog/admin/editor/:publicId/:language'
                    render={(props) => <ArticleEditorProvider {...props}/>}
                />}
                {isReferenceAllow
                && <Route
                    exact
                    path='/references'
                    render={(props) => <Reference {...props}/>}
                />}
                {isReferenceAllow
                && <Route
                    exact
                    path='/references/admin'
                    render={(props) => <ReferenceAdminProvider {...props}/>}
                />}
                {isReferenceAllow
                && <Route
                    exact
                    path='/references/admin/case-study/editor/:publicId'
                    render={(props) => <CaseStudyEditorProvider {...props}/>}
                />}
                <Route
                    exact
                    path='/terms'
                    render={() => <TermsAndConditions/>}
                />
                <Route
                    exact
                    path='/privacy-policy'
                    render={() => <PrivacyPolicy/>}
                />
                <Route
                    exact
                    path='/cookies'
                    render={() => <CookiesComponent/>}
                />
                <Route
                    exact
                    path='/'
                    render={() => <OurServices isApiWork={isApiWork} randomReference={randomReference}/>}
                />
            </Switch>
        );
    };

    renderFooter = () => <FooterProvider/>;
}

export default App;
