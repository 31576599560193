import React from 'react';

class SocialLink extends React.Component {
    render() {
        const {src, alt, name} = this.props;
        return (
            <React.Fragment>
                <img src={src} alt={alt}/>
                <p>{name}</p>
            </React.Fragment>
        );
    }
}

export default SocialLink;
