import React from 'react';
import ArticlePreviewUI from '../../../../components/blog/present/articlePreviewUI/articlePreviewUI';
import Grid from '@material-ui/core/Grid';
import LoadMoreButton from '../../../../components/blog/present/loadMoreButton/loadMoreButton';
import {chunkArray} from '../../../../utils/utils';

class ArticlePreviewManager extends React.Component {

    state = {
        renderedRows: 3,
    };

    onLoadMore = () => {
        this.setState({renderedRows: this.state.renderedRows + 1});
    };

    render() {
        const {t, articlesPreviews} = this.props;
        const {renderedRows} = this.state;
        const chunkArticlePreviews = chunkArray(articlesPreviews, 4);
        const filteredRenderedRows = [];
        chunkArticlePreviews.forEach((item) => {
            if (filteredRenderedRows.length !== renderedRows) {
                filteredRenderedRows.push(item);
            }
        });
        const showLoadMoreButton = renderedRows !== chunkArticlePreviews.length
            && chunkArticlePreviews.length > renderedRows;
        return (
            <Grid container item style={{marginTop: '86px'}} justify='center'>
                {filteredRenderedRows.map(this.renderRow)}
                {showLoadMoreButton && <LoadMoreButton t={t} onClick={this.onLoadMore}/>}
            </Grid>
        );
    }

    renderRow = (articlePreviews, index) => {
        const {t} = this.props;
        return (
            <Grid
                key={index.toString()}
                container
                item
                className='article-blog-row ml-4'
                justify='center'
            >
                {articlePreviews.map((articlePreview, i) => {
                    return (
                        <ArticlePreviewUI
                            t={t}
                            key={i.toString()}
                            articlePreview={articlePreview}
                            onRedirect={this.props.onRedirect}
                        />
                    );
                })}
            </Grid>
        );
    };
}

export default ArticlePreviewManager;