import React from 'react';
import RightArrow from './rightArrow/rightArrow';
import LeftArrow from './leftArrow/leftArrow';
import Carousel from 'react-multi-carousel';
import ReferenceUI from './referenceUI/referenceUI';
import 'react-multi-carousel/lib/styles.css';

// INFO:
// Modified external library that represents references
//
// Properties:
// items - all references images
class ReferenceMultipleCarousel extends React.Component {

    responsiveOptions = {
        xl: {
            breakpoint: {max: 3000, min: 1501},
            items: 5,
            slidesToSlide: 1,
        },
        lg: {
            breakpoint: {max: 1500, min: 1251},
            items: 4,
            slidesToSlide: 1,
        },
        md: {
            breakpoint: {max: 1250, min: 901},
            items: 3,
            slidesToSlide: 1,
        },
        sm: {
            breakpoint: {max: 900, min: 651},
            items: 2,
            slidesToSlide: 1,
        },
        xs: {
            breakpoint: {max: 650, min: 0},
            items: 1,
            slidesToSlide: 1,
        },
    };

    get leftArrow() {
        return <LeftArrow/>;
    }

    get rightArrow() {
        return <RightArrow/>;
    }

    render() {
        return (
            <Carousel
                infinite
                autoPlay
                swipeable
                responsive={this.responsiveOptions}
                itemClass='reference-image'
                sliderClass='slider-ul'
                containerClass='reference-slider'
                customLeftArrow={this.leftArrow}
                customRightArrow={this.rightArrow}
                autoPlaySpeed={3000}
            >
                {this.renderItems()}
            </Carousel>
        );
    }

    renderItems = () => this.props.items.map((item, index) => <ReferenceUI src={item} key={index}/>);
}

export default ReferenceMultipleCarousel;
