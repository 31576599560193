import React from 'react';
import Grid from '@material-ui/core/Grid';
import Faq from './faq/faq';
import QuestionForm from '../../components/questionForm/questionForm';
import {withTranslation} from 'react-i18next';
import {sendEmail} from '../../utils/email/sendEmail';
import Title from '../../components/title/title';

class FrequentlyAskedQuestionsInternal extends React.Component {

    onSubmitQuestionForm = (formData, cb) => {
        const newFormData = {
            title: 'FAQ Form',
            ...formData,
        };
        sendEmail(newFormData, cb);
    };

    render() {
        const {t} = this.props;
        return (
            <Grid container className='pb-5'>
                <Title text={this.props.t('welcome.title')}/>
                <Faq t={t}/>
                <QuestionForm t={t} title={t('questionForm.title')} onSubmit={this.onSubmitQuestionForm}/>
            </Grid>
        );
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }
}

export const FrequentlyAskedQuestions = withTranslation('faq')(FrequentlyAskedQuestionsInternal);