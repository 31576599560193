import React from 'react';
import Grid from '@material-ui/core/Grid';
import SocialMedia from './socialMedia/SocialMedia';

class FooterSocialMedia extends React.Component {
    render() {
        const {className, disableContainer} = this.props;
        return (
            <Grid
                container={!disableContainer}
                item
                className={className}
                md={this.props.md}
            >
                {this.renderSocialMedia()}
            </Grid>
        );
    }

    renderSocialMedia = () => {
        const {links} = this.props;
        return links.map((item, index) => {
            if (item.show) {
                return (
                    <SocialMedia
                        key={index.toString()}
                        href={item.link}
                        icon={item.icon}
                        iconHover={item.iconHover}
                        alt=''
                        style={index !== links.length - 1 ? 'mr-3' : ''}
                    />
                );
            }
            return null;
        }).filter((item) => !!item);
    };
}

export default FooterSocialMedia;