import React from 'react';
import {config} from '../../config/config';
import Navbar from '../../components/navbar/navbar';
import Logo from '../../assets/SRM logo Flat.png';
import {withTranslation} from 'react-i18next';

class NavbarProviderInternal extends React.Component {
    render() {
        const {t, isApiWork} = this.props;
        const isReferenceAllow = isApiWork && config.features.references.allow;
        const isBlogAllow = isApiWork && config.features.blog.allow;
        const navBarItems = [
            {
                link: '/',
                style: 'normal-nav-link',
                name: t('navbar.ourService'),
            },
            isReferenceAllow && {
                link: '/references',
                style: 'normal-nav-link',
                name: t('navbar.reference'),
            },
            isBlogAllow && {
                link: '/blog',
                style: 'normal-nav-link',
                name: t('navbar.blog'),
            },
            {
                link: '/faq',
                style: 'normal-nav-link',
                name: t('navbar.faq'),
            },
            {
                link: '/contacts',
                style: 'normal-nav-link',
                name: t('navbar.contact'),
            },
            {
                link: '/inquiry',
                name: t('navbar.inquiry'),
                style: 'create-inquiry-button',
                isButton: true,
            },
        ].filter((a) => !!a);
        return (
            <Navbar
                logo={Logo}
                activeItemStyle='bold-nav-link'
                items={navBarItems}
            />
        );
    }
}

export const NavbarProvider = withTranslation('global')(NavbarProviderInternal);