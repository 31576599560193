import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
// INFO:
// CS Import
import globalCS from './localizations/cs/global/globalCS.json';
import inquiryCS from './localizations/cs/inquiry/inquiryCS.json';
import ourServiceCS from './localizations/cs/ourService/ourServiceCS.json';
import contactsCS from './localizations/cs/contacts/contactsCS.json';
import faqCS from './localizations/cs/faq/faqCS.json';
import privacyPolicyCS from './localizations/cs/privacyPolicy/privacyPolicyCS.json';
import cookiesCS from './localizations/cs/cookies/cookiesCS.json';
import blogCS from './localizations/cs/blog/blogCS.json';
import referenceCS from './localizations/cs/reference/referenceCS.json';
import caseStudyCS from './localizations/cs/caseStudy/caseStudyCS.json';
import termsAndConditionCS from './localizations/cs/termsAndConditions/termsAndConditionsCS.json';
// INFO:
// EN Import
import globalEN from './localizations/en/global/globalEN.json';
import inquiryEN from './localizations/en/inquiry/inquiryEN.json';
import ourServiceEN from './localizations/en/ourService/ourServiceEN.json';
import contactsEN from './localizations/en/contacts/contactsEN.json';
import faqEN from './localizations/en/faq/faqEN.json';
import privacyPolicyEN from './localizations/en/privacyPolicy/privacyPolicyEN.json';
import cookiesEN from './localizations/en/cookies/cookiesEN.json';
import blogEN from './localizations/en/blog/blogEN.json';
import referenceEN from './localizations/en/reference/referenceEN.json';
import caseStudyEN from './localizations/en/caseStudy/caseStudyEN.json';
import termsAndConditionEN from './localizations/en/termsAndConditions/termsAndConditionsEN.json';
import {config} from './config/config';

const resources = {
    cs: {
        global: globalCS,
        inquiry: inquiryCS,
        ourService: ourServiceCS,
        contacts: contactsCS,
        faq: faqCS,
        privacyPolicy: privacyPolicyCS,
        cookies: cookiesCS,
        blog: blogCS,
        reference: referenceCS,
        caseStudy: caseStudyCS,
        termsAndConditions: termsAndConditionCS,
    },
};

if (config.features.ENTranslation.allow) {
    resources.en = {
        global: globalEN,
        inquiry: inquiryEN,
        ourService: ourServiceEN,
        contacts: contactsEN,
        faq: faqEN,
        privacyPolicy: privacyPolicyEN,
        cookies: cookiesEN,
        blog: blogEN,
        reference: referenceEN,
        caseStudy: caseStudyEN,
        termsAndConditions: termsAndConditionEN,
    };
}

const options = {
    order: ['querystring', 'navigator'],
    lookupQuerystring: 'lng',
};

i18n
    .use(detector)
    .init({
        detection: options,
        interpolation: {
            escapeValue: false,
        },
        resources,
        fallbackLng: 'cs',
    });

export default i18n;