import React from 'react';
import {withRouter} from 'react-router';
import {ServiceSelection} from '../serviceSelection/serviceSelection';
import {OptimizationCloudUse} from '../cloudOptimization/cloudUse';
import {CloudSolution} from '../cloudOptimization/cloudSolution';
import {CloudImprove} from '../cloudOptimization/cloudImprove';
import {ContactInformation} from '../shared/contactInformation/contactInformation';
import {WhenceMigration} from '../cloudMigration/whenceMigration';
import {ReasonMigration} from '../cloudMigration/reasonMigration';
import {MovesMigration} from '../cloudMigration/movesMigration';
import {IsReadyForMigration} from '../cloudMigration/isReadyForMigration';
import {ProposalMigration} from '../cloudMigration/proposalMigration';
import {DesignCloudUse} from '../cloudDesign/cloudUse';
import {CloudParameter} from '../cloudDesign/cloudParameter';
import {CloudCapacity} from '../cloudDesign/cloudCapacity';
import {CloudHosting} from '../cloudDesign/cloudHosting';
import {CloudDataMigration} from '../cloudDesign/cloudDataMigration';
import {CloudService} from '../cloudAdvice/cloudService';
import {InquiryFinish} from '../shared/inquiryFinish/inquiryFinish';

const servicesValues = {
    // INFO:
    // optimization
    optimizationSelectedSolution: null,
    optimizationSolutionInput: '',
    optimizationOtherInput: '',
    optimizationUsages: null,
    optimizationImproves: null,
    optimizationImproveInput: null,
    // migration
    migrationSelectedPlace: null,
    migrationSelectedReason: null,
    migrationReasonInput: null,
    migrationMoves: null,
    migrationMoveInput: null,
    migrationSelectedReadyStatus: null,
    migrationSelectedProposal: null,
    // design
    designOtherInput: '',
    designUsages: null,
    designParameters: null,
    designParameterInput: null,
    designSelectedCloudCapacity: null,
    designExpandInFuture: false,
    designSelectedCloudHosting: null,
    designSelectedDataMigrationHelp: null,
    // service
    serviceSelected: null,
    serviceSomethingElseInput: null,
    // universal for every component
    companyInput: null,
    emailInput: null,

};

class ServiceSelectionManager extends React.Component {
    constructor(props) {
        super(props);
        const service = this.props.match.params.service;
        const isServiceCorrect = this.isServiceCorrect(service);
        this.state = {
            showInquiryFinish: false,
            serviceName: '',
            selectedService: isServiceCorrect ? service : 'optimization',
            currentServiceScreenIndex: 0,
            sliderSettings: {
                total: 7,
                current: 1,
            },
            ...servicesValues,
        };
        window.scrollTo(0, 100);
    }

    isServiceCorrect = (service) => {
        return service === 'optimization' ||
            service === 'migration' ||
            service === 'design' ||
            service === 'advice';
    };

    reset = () => this.setState({...servicesValues});

    getTargetService = (key) => {
        switch (key || this.state.selectedService) {
            case 'optimization':
                return this.cloudOptimization;
            case 'migration':
                return this.cloudMigration;
            case 'design':
                return this.cloudDesign;
            case 'advice':
                return this.cloudService;
            default:
                return this.cloudOptimization;
        }
    };

    canNext = () => {
        const {currentServiceScreenIndex} = this.state;
        const getTargetService = this.getTargetService();
        const nextIndex = currentServiceScreenIndex + 1;
        return nextIndex < getTargetService.length - 1;
    };

    onServiceSelect = (service) => {
        const selectedService = service.key;
        const targetService = this.getTargetService(selectedService);
        const serviceName = service.name;
        const sliderSettings = {
            total: targetService.length + 1,
            current: 1,
        };
        this.setState({sliderSettings, selectedService, serviceName}, () => {
            this.reset();
            this.onNext();
        });
    };

    onPrevious = () => {
        const {currentServiceScreenIndex, sliderSettings} = this.state;
        this.setState({
            currentServiceScreenIndex: currentServiceScreenIndex - 1,
            sliderSettings: {
                total: sliderSettings.total,
                current: sliderSettings.current - 1,
            },
        });
    };

    onNext = () => {
        const {currentServiceScreenIndex, sliderSettings} = this.state;
        const nextIndex = currentServiceScreenIndex + 1;
        this.setState({
            currentServiceScreenIndex: nextIndex,
            sliderSettings: {
                total: sliderSettings.total,
                current: sliderSettings.current + 1,
            },
        });
    };

    onFinish = () => {
        this.setState({showInquiryFinish: true});
        const {
            selectedService,
            optimizationSelectedSolution,
            optimizationSolutionInput,
            optimizationUsages,
            optimizationImproves,
            optimizationOtherInput,
            optimizationImproveInput,
            migrationSelectedPlace,
            migrationSelectedReason,
            migrationReasonInput,
            migrationMoves,
            migrationSelectedReadyStatus,
            migrationSelectedProposal,
            migrationMoveInput,
            designUsages,
            designParameters,
            designSelectedCloudCapacity,
            designExpandInFuture,
            designSelectedCloudHosting,
            designSelectedDataMigrationHelp,
            designOtherInput,
            designParameterInput,
            companyInput,
            emailInput,
            serviceSelected,
            serviceSomethingElseInput,
        } = this.state;
        let finalJSON = {};
        const company = companyInput;
        const email = emailInput;
        if (selectedService === 'optimization') {
            const solution = optimizationSelectedSolution.key !== 'other'
                ? optimizationSelectedSolution.name
                : optimizationSolutionInput;
            const usages = optimizationUsages.map((item, index) => {
                if (optimizationUsages.length - 1 === index && item.active) {
                    return [...item.others.map((item) => item.value)];
                }
                return item.active && item.name;
            }).filter((item) => !!item);
            const improves = optimizationImproves.map((item) => item.value);
            if (optimizationOtherInput && optimizationOtherInput.length > 0) {
                usages.push(optimizationOtherInput);
            }
            if (optimizationImproveInput && optimizationImproveInput.length > 0) {
                improves.push(optimizationImproveInput);
            }
            finalJSON = {
                option_1_label: 'Jaké cloudové řešení používáte?',
                option_1_value: solution.toString(),
                option_2_label: 'K čemu cloud používáte?',
                option_2_value: usages.toString().split(',').join(', '),
                option_3_label: 'Co vám na stávajícím řešení nevyhovuje?',
                option_3_value: improves.toString().split(',').join(', '),
            };
        }
        if (selectedService === 'migration') {
            const moves = migrationMoves.map((item) => item.value);
            if (migrationMoveInput && migrationMoveInput.length > 0) {
                moves.push(migrationMoveInput);
            }
            finalJSON = {
                option_1_label: 'Odkud chcete migrovat?',
                option_1_value: migrationSelectedPlace.name,
                option_2_label: 'Jaké jsou vaše důvody pro migraci?',
                option_2_value: migrationSelectedReason.key !== 'other'
                    ? migrationSelectedReason.name
                    : migrationReasonInput,
                option_3_label: 'Co chcete do cloudu přesouvat?',
                option_3_value: moves.toString().split(',').join(', '),
                option_4_label: 'Je přesouvaný software cloud-ready?',
                option_4_value: migrationSelectedReadyStatus.name,
                option_5_label: 'Máme pro vás v rámci migrace navrhnout architekturu/hardware/backup cílového cloudu?',
                option_5_value: migrationSelectedProposal.name,
            };
        }
        if (selectedService === 'design') {
            const usages = designUsages.map((item, index) => {
                if (designUsages.length - 1 === index && item.active) {
                    return [...item.others.map((item) => item.value)];
                }
                return item.active && item.name;
            }).filter((item) => !!item);
            const parameters = designParameters.map((item) => item.value);
            if (designOtherInput && designOtherInput.length > 0) {
                usages.push(designOtherInput);
            }
            if (designParameterInput && designParameterInput.length > 0) {
                parameters.push(designParameterInput);
            }
            finalJSON = {
                option_1_label: 'K čemu cloud používáte?',
                option_1_value: usages.toString().split(',').join(', '),
                option_2_label: 'Jaký výkon, dostupnost nebo další parametry od cloudu očekáváte?',
                option_2_value: parameters.toString().split(',').join(', '),
                option_3_label: 'Jakou by měl mít váš cloud kapacitu?',
                option_3_value: designSelectedCloudCapacity.name,
                option_4_label: 'Budete do budoucna rozšiřovat velikost cloudu?',
                option_4_value: designExpandInFuture ? 'Ano' : 'Ne',
                option_5_label: 'Na čem by měl váš privátní cloud běžet?',
                option_5_value: designSelectedCloudHosting.name,
                option_6_label: 'Potřebujete pomoct také s migrací dat?',
                option_6_value: designSelectedDataMigrationHelp.name,
            };
        }
        if (selectedService === 'advice') {
            finalJSON = {
                option_1_label: 'Dejte nám vědět, co pro vás můžeme udělat:',
                option_1_value: serviceSelected.key !== 'something else'
                    ? serviceSelected.name
                    : serviceSomethingElseInput,
            };
        }
        this.props.onSubmit &&
        this.props.onSubmit({
            formData: finalJSON,
            service: this.state.serviceName,
            company,
            email,
        });
    };

    render() {
        const {selectedService, sliderSettings, currentServiceScreenIndex, showInquiryFinish} = this.state;
        if (showInquiryFinish) {
            return <InquiryFinish/>;
        }
        if (currentServiceScreenIndex !== 0) {
            return this.renderTargetService();
        }
        return (
            <ServiceSelection
                onServiceSelect={this.onServiceSelect}
                onChange={(service) => this.setState({selectedService: service})}
                selectedService={selectedService}
                sliderSettings={sliderSettings}
            />
        );
    }

    renderTargetService = () => {
        const {currentServiceScreenIndex, sliderSettings} = this.state;
        const currentTargetService = this.getTargetService()
            .find((service) => {
                return service.index === currentServiceScreenIndex;
            });
        return currentTargetService.component(sliderSettings);
    };

    cloudOptimization = [
        {
            index: 1,
            component: (sliderSettings) => (
                <CloudSolution
                    sliderSettings={sliderSettings}
                    onNextClick={this.canNext && this.onNext}
                    onPreviousClick={this.onPrevious}
                    selectedSolution={this.state.optimizationSelectedSolution}
                    inputOtherValue={this.state.optimizationSolutionInput}
                    saveSelectedSolution={(val) => this.setState({optimizationSelectedSolution: val})}
                    saveInputOtherValue={(val) => this.setState({optimizationSolutionInput: val})}
                />
            ),
        },
        {
            index: 2,
            component: (sliderSettings) => (
                <OptimizationCloudUse
                    sliderSettings={sliderSettings}
                    onNextClick={this.canNext && this.onNext}
                    onPreviousClick={this.onPrevious}
                    usages={this.state.optimizationUsages}
                    otherInput={this.state.optimizationOtherInput}
                    saveUsages={(val) => this.setState({optimizationUsages: val})}
                    saveOtherInput={(val) => this.setState({optimizationOtherInput: val})}
                />
            ),
        },
        {
            index: 3,
            component: (sliderSettings) => (
                <CloudImprove
                    sliderSettings={sliderSettings}
                    onNextClick={this.canNext && this.onNext}
                    onPreviousClick={this.onPrevious}
                    improves={this.state.optimizationImproves}
                    improveInput={this.state.optimizationImproveInput}
                    saveImproves={(val) => this.setState({optimizationImproves: val})}
                    saveImproveinput={(val) => this.setState({optimizationImproveInput: val})}
                />
            ),
        },
        {
            index: 4,
            component: (sliderSettings) => (
                <ContactInformation
                    sliderSettings={sliderSettings}
                    screenNumber={5}
                    onPreviousClick={this.onPrevious}
                    onNextClick={this.onFinish}
                    companyInput={this.state.companyInput}
                    emailInput={this.state.emailInput}
                    saveCompanyInput={(val) => this.setState({companyInput: val})}
                    saveEmailInput={(val) => this.setState({emailInput: val})}
                />
            ),
        },
    ];

    cloudMigration = [
        {
            index: 1,
            component: (sliderSettings) => (
                <WhenceMigration
                    sliderSettings={sliderSettings}
                    onNextClick={this.canNext && this.onNext}
                    onPreviousClick={this.onPrevious}
                    selectedMigrationPlace={this.state.migrationSelectedPlace}
                    saveSelectedMigrationPlace={(val) => this.setState({migrationSelectedPlace: val})}
                />
            ),
        },
        {
            index: 2,
            component: (sliderSettings) => (
                <ReasonMigration
                    sliderSettings={sliderSettings}
                    onNextClick={this.canNext && this.onNext}
                    onPreviousClick={this.onPrevious}
                    selectedReason={this.state.migrationSelectedReason}
                    reasonInput={this.state.migrationReasonInput}
                    saveMigrationSelectedReason={(val) => this.setState({migrationSelectedReason: val})}
                    saveMigrationReasonInput={(val) => this.setState({migrationReasonInput: val})}
                />
            ),
        },
        {
            index: 3,
            component: (sliderSettings) => (
                <MovesMigration
                    sliderSettings={sliderSettings}
                    onNextClick={this.canNext && this.onNext}
                    onPreviousClick={this.onPrevious}
                    moves={this.state.migrationMoves}
                    moveInput={this.state.migrationMoveInput}
                    saveMoves={(val) => this.setState({migrationMoves: val})}
                    saveMoveInput={(val) => this.setState({migrationMoveInput: val})}
                />
            ),
        },
        {
            index: 4,
            component: (sliderSettings) => (
                <IsReadyForMigration
                    sliderSettings={sliderSettings}
                    onNextClick={this.canNext && this.onNext}
                    onPreviousClick={this.onPrevious}
                    selectedMigrationReadyStatus={this.state.migrationSelectedReadyStatus}
                    saveSelectedMigrationReadyStatus={(val) => this.setState({migrationSelectedReadyStatus: val})}
                />
            ),
        },
        {
            index: 5,
            component: (sliderSettings) => (
                <ProposalMigration
                    sliderSettings={sliderSettings}
                    onNextClick={this.canNext && this.onNext}
                    onPreviousClick={this.onPrevious}
                    selectedMigrationProposal={this.state.migrationSelectedProposal}
                    saveSelectedMigrationProposal={(val) => this.setState({migrationSelectedProposal: val})}
                />
            ),
        },
        {
            index: 6,
            component: (sliderSettings) => (
                <ContactInformation
                    sliderSettings={sliderSettings}
                    screenNumber={7}
                    onPreviousClick={this.onPrevious}
                    onNextClick={this.onFinish}
                    companyInput={this.state.companyInput}
                    emailInput={this.state.emailInput}
                    saveCompanyInput={(companyInput) => this.setState({companyInput})}
                    saveEmailInput={(emailInput) => this.setState({emailInput})}
                />
            ),
        },
    ];

    cloudDesign = [
        {
            index: 1,
            component: (sliderSettings) => (
                <DesignCloudUse
                    sliderSettings={sliderSettings}
                    onNextClick={this.canNext && this.onNext}
                    onPreviousClick={this.onPrevious}
                    usages={this.state.designUsages}
                    otherInput={this.state.designOtherInput}
                    saveUsages={(val) => this.setState({designUsages: val})}
                    saveOtherInput={(val) => this.setState({designOtherInput: val})}
                />
            ),
        },
        {
            index: 2,
            component: (sliderSettings) => (
                <CloudParameter
                    sliderSettings={sliderSettings}
                    onNextClick={this.canNext && this.onNext}
                    onPreviousClick={this.onPrevious}
                    parameters={this.state.designParameters}
                    parameterInput={this.state.designParameterInput}
                    saveParameters={(val) => this.setState({designParameters: val})}
                    saveParameterInput={(val) => this.setState({designParameterInput: val})}
                />
            ),
        },
        {
            index: 3,
            component: (sliderSettings) => (
                <CloudCapacity
                    sliderSettings={sliderSettings}
                    onNextClick={this.canNext && this.onNext}
                    onPreviousClick={this.onPrevious}
                    selectedCloudCapacity={this.state.designSelectedCloudCapacity}
                    expandInFuture={this.state.designExpandInFuture}
                    saveSelectedCloudCapacity={(val) => this.setState({designSelectedCloudCapacity: val})}
                    saveExpandInFuture={(val) => this.setState({designExpandInFuture: val})}
                />
            ),
        },
        {
            index: 4,
            component: (sliderSettings) => (
                <CloudHosting
                    sliderSettings={sliderSettings}
                    onNextClick={this.canNext && this.onNext}
                    onPreviousClick={this.onPrevious}
                    cloudHosting={this.state.designSelectedCloudHosting}
                    saveCloudHosting={(val) => this.setState({designSelectedCloudHosting: val})}
                />
            ),
        },
        {
            index: 5,
            component: (sliderSettings) => (
                <CloudDataMigration
                    sliderSettings={sliderSettings}
                    onNextClick={this.canNext && this.onNext}
                    onPreviousClick={this.onPrevious}
                    dataMigrationHelp={this.state.designSelectedDataMigrationHelp}
                    saveDataMigrationHelp={(val) => this.setState({designSelectedDataMigrationHelp: val})}
                />
            ),
        },
        {
            index: 6,
            component: (sliderSettings) => (
                <ContactInformation
                    sliderSettings={sliderSettings}
                    screenNumber={7}
                    onNextClick={this.onFinish}
                    onPreviousClick={this.onPrevious}
                    companyInput={this.state.companyInput}
                    emailInput={this.state.emailInput}
                    saveCompanyInput={(companyInput) => this.setState({companyInput})}
                    saveEmailInput={(emailInput) => this.setState({emailInput})}
                />
            ),
        },
    ];

    cloudService = [
        {
            index: 1,
            component: (sliderSettings) => (
                <CloudService
                    sliderSettings={sliderSettings}
                    onNextClick={this.canNext && this.onNext}
                    onPreviousClick={this.onPrevious}
                    selectedService={this.state.serviceSelected}
                    sometingElseInput={this.state.serviceSomethingElseInput}
                    saveSelectedService={(val) => this.setState({serviceSelected: val})}
                    saveSomethingElseInput={(val) => this.setState({serviceSomethingElseInput: val})}
                />
            ),
        },
        {
            index: 2,
            component: (sliderSettings) => (
                <ContactInformation
                    sliderSettings={sliderSettings}
                    screenNumber={3}
                    onPreviousClick={this.onPrevious}
                    onNextClick={this.onFinish}
                    companyInput={this.state.companyInput}
                    emailInput={this.state.emailInput}
                    saveCompanyInput={(companyInput) => this.setState({companyInput})}
                    saveEmailInput={(emailInput) => this.setState({emailInput})}
                />
            ),
        },
    ];
}

export default withRouter(ServiceSelectionManager);