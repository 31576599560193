import React from 'react';
import {Grid} from '@material-ui/core';
import ReferenceUI from '../../../../components/reference/present/referenceUI/referenceUI';

class ReferenceManager extends React.Component {

    onClickCaseStudy = (id) => {
        this.props.onScrollToCaseStudy && this.props.onScrollToCaseStudy(id.toString());
    };

    render() {
        return (
            <Grid item container justify='center' xl={12} className='references-container'>
                {this.renderReferences()}
            </Grid>
        );
    }

    renderReferences = () => {
        const {references, t} = this.props;
        return references.map((reference, index) => {
            if (reference && reference.id) {
                return (
                    <ReferenceUI
                        t={t}
                        key={index.toString()}
                        reference={reference}
                        onClickCaseStudy={this.onClickCaseStudy}
                    />
                );
            }
            return null;
        }).filter((item) => !!item);
    };
}

export default ReferenceManager;
