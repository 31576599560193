import React from 'react';
import ReferenceBlock from '../referenceBlock/referenceBlock';

class ReferenceTableBody extends React.Component {
    render() {
        const {data} = this.props;
        return (
            <tbody>
            {data.map(this.renderBlock)}
            </tbody>
        );
    }

    renderBlock = (item, index) => {
        return (
            <ReferenceBlock
                key={index.toString()}
                references={item.referenceContents}
                publicId={item.publicId}
                {...this.props}
            />
        )
    };
}

export default ReferenceTableBody;