import React from 'react';
import icon from '../../../assets/plus.svg';

// INFO:
// component that can be removed by clicking delete
//
// Properties:
// style - adds another style next to existing
// onClick - function, is trigger when click on delete icon
// text - text in middle of frame
class ErasingFrame extends React.Component {
    render() {
        return (
            <div className={`erasing-frame ${this.props.className}`}>
                <p className='text-45 overflow-any'>{this.props.text}</p>
                <img
                    src={icon}
                    className='erasing-frame-icon'
                    onClick={this.props.onClick}
                    alt=''
                />
            </div>
        );
    }
}

export default ErasingFrame;