import React from 'react';
import Grid from '@material-ui/core/Grid';

class TermsAndConditionsEN extends React.Component {
    render() {
        return (
            <Grid
                item
                container
                direction='column'
                alignContent='center'
                xs={12}
            >
                {this.renderFirst()}
                {this.renderSecond()}
                {this.renderThird()}
                {this.renderFourth()}
                {this.renderFifth()}
                {this.renderSixth()}
                {this.renderSeventh()}
                {this.renderEighth()}
                {this.renderNinth()}
                {this.renderTenth()}
                {this.renderEleventh()}
                {this.renderTwelfth()}
            </Grid>
        );
    }

    renderFirst = () => {
        const {t} = this.props;
        return (
            <React.Fragment>
                <h2 style={{marginTop: '150px'}} className='text-53 pt-4 pl-3'>{t('I.title')}</h2>
                <p className='text-54 pt-2 pl-3'>{t('I.first')}</p>
                <p className='text-54 pt-2 pl-3'>{t('I.second')}</p>
            </React.Fragment>
        );
    };

    renderSecond = () => {
        const {t} = this.props;
        return (
            <React.Fragment>
                <h2 className='text-53 pt-4 pl-3'>{t('II.title')}</h2>
                <p className='text-54 pt-2 pl-3'>{t('II.first')}</p>
                <p className='text-54 pt-2 pl-3'>{t('II.second')}</p>
            </React.Fragment>
        );
    };

    renderThird = () => {
        const {t} = this.props;
        return (
            <React.Fragment>
                <h2 className='text-53 pt-4 pl-3'>{t('III.title')}</h2>
                <p className='text-54 pt-2 pl-3'>{t('III.first.title')}</p>
                <p className='text-54 pt-2 pl-5'>{t('III.first.first')}</p>
                <p className='text-54 pt-2 pl-5'>{t('III.first.second')}</p>
                <p className='text-54 pt-2 pl-5'>{t('III.first.third')}</p>
                <p className='text-54 pt-2 pl-5'>{t('III.first.fourth')}</p>
                <p className='text-54 pt-2 pl-5'>{t('III.first.fifth')}</p>
                <p className='text-54 pt-2 pl-5'>{t('III.first.sixth')}</p>
                <p className='text-54 pt-2 pl-5'>{t('III.first.seventh')}</p>
                <p className='text-54 pt-2 pl-5'>{t('III.first.eighth')}</p>
                <p className='text-54 pt-2 pl-3'>{t('III.second')}</p>
            </React.Fragment>
        );
    };

    renderFourth = () => {
        const {t} = this.props;
        return (
            <React.Fragment>
                <h2 className='text-53 pt-4 pl-3'>{t('IV.title')}</h2>
                <p className='text-54 pt-2 pl-3'>{t('IV.first')}</p>
                <p className='text-54 pt-2 pl-3'>{t('IV.second')}</p>
            </React.Fragment>
        );
    };

    renderFifth = () => {
        const {t} = this.props;
        return (
            <React.Fragment>
                <h2 className='text-53 pt-4 pl-3'>{t('V.title')}</h2>
                <p className='text-54 pt-2 pl-3'>{t('V.first')}</p>
            </React.Fragment>
        );
    };

    renderSixth = () => {
        const {t} = this.props;
        return (
            <React.Fragment>
                <h2 className='text-53 pt-4 pl-3'>{t('VI.title')}</h2>
                <p className='text-54 pt-2 pl-3'>{t('VI.first')}</p>
            </React.Fragment>
        );
    };

    renderSeventh = () => {
        const {t} = this.props;
        return (
            <React.Fragment>
                <h2 className='text-53 pt-4 pl-3'>{t('VII.title')}</h2>
                <p className='text-54 pt-2 pl-3'>{t('VII.first')}</p>
            </React.Fragment>
        );
    };

    renderEighth = () => {
        const {t} = this.props;
        return (
            <React.Fragment>
                <h2 className='text-53 pt-4 pl-3'>{t('VIII.title')}</h2>
                <p className='text-54 pt-2 pl-3'>{t('VIII.first')}</p>
            </React.Fragment>
        );
    };

    renderNinth = () => {
        const {t} = this.props;
        return (
            <React.Fragment>
                <h2 className='text-53 pt-4 pl-3'>{t('IX.title')}</h2>
                <p className='text-54 pt-2 pl-3'>{t('IX.first')}</p>
            </React.Fragment>
        );
    };

    renderTenth = () => {
        const {t} = this.props;
        return (
            <React.Fragment>
                <h2 className='text-53 pt-4 pl-3'>{t('X.title')}</h2>
                <p className='text-54 pt-2 pl-3'>{t('X.first')}</p>
            </React.Fragment>
        );
    };

    renderEleventh = () => {
        const {t} = this.props;
        return (
            <React.Fragment>
                <h2 className='text-53 pt-4 pl-3'>{t('XI.title')}</h2>
                <p className='text-54 pt-2 pl-3'>{t('XI.first')}</p>
            </React.Fragment>
        );
    };

    renderTwelfth = () => {
        const {t} = this.props;
        return (
            <React.Fragment>
                <h2 className='text-53 pt-4 pl-3'>{t('XII.title')}</h2>
                <p className='text-54 pt-2 pl-3'>{t('XII.first')}</p>
            </React.Fragment>
        );
    };
}

export default TermsAndConditionsEN;