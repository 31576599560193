import React from 'react';
import Grid from '@material-ui/core/Grid';
import arrowDown from '../../assets/language-down.png';
import arrowUp from '../../assets/language-top.png';

// INFO:
// is toggleable, contextual overlays for displaying lists of links
//
// Properties:
// selectedItem - current selected item
// items -all items that are in dropdown
// onChange - function, is trigger when select item in dropdown
class Dropdown extends React.Component {
    state = {
        isOpen: false,
    };

    onClick = (item) => {
        this.props.onChange && this.props.onChange(item);
    };

    onChange = (event) => {
        const isOpen = !event.currentTarget.className.includes('show');
        this.setState({isOpen});
    };

    render() {
        const {selectedItem, className} = this.props;
        const {isOpen} = this.state;
        return (
            <div className='dropdown' onMouseDown={this.onChange}>
                <a
                    className={`btn btn-secondary dropdown-toggle pl-3 ${className}`}
                    role='button'
                    id='dropdownMenuLink'
                    data-toggle='dropdown'
                    aria-haspopup='true'
                    aria-expanded='false'
                    href='/'
                >
                    <Grid
                        container
                        item
                        direction='row'
                        className='inquiry-dropdown mt-1'
                        xs={12}
                    >
                        {selectedItem.src && <Grid item>
                            <img className='dropdown-item-icon' src={selectedItem.src} alt={selectedItem.name}/>
                        </Grid>}
                        <p className='text-40'>{selectedItem.name}</p>
                        <Grid item className='ml-auto justify-content-center'>
                            <img src={isOpen ? arrowUp : arrowDown} alt='arrow'/>
                        </Grid>
                    </Grid>
                </a>
                <div className={`dropdown-menu w-100 ${className}`} aria-labelledby='dropdownMenuLink'>
                    {this.renderItems()}
                </div>
            </div>
        );
    }

    renderItems = () => {
        const {items, selectedItem} = this.props;
        return items.map((item, index) => {
            if (item.key !== selectedItem.key) {
                return (
                    <Grid
                        container
                        item
                        key={index}
                        direction='row'
                        className='dropdown-item flex-nowrap pl-3'
                        onClick={() => this.onClick(item)}
                        xs={12}
                    >
                        {item.src && <Grid item className='mt-1'>
                            <img className='dropdown-item-icon' src={item.src} alt={item.name}/>
                        </Grid>}
                        <p className='text-40 mt-1'>{item.name}</p>
                    </Grid>
                );
            }
            return null;
        }).filter((item) => !!item);
    };
}

export default Dropdown;