import React from 'react';
import Grid from '@material-ui/core/Grid';
import Input from '../../form/input/input';
import {isEmailValid} from '../../../utils/utils';

class FirstBlock extends React.Component {

    state = {
        email: '',
        newsletterMessage: {
            success: false,
            text: null,
        },
    };

    onSubmitEmail = () => {
        const {email} = this.state;
        if (isEmailValid(email)) {
            if (this.props.onSendNewsletter) {
                this.props.onSendNewsletter(email, (error) => {
                    if (!error) {
                        this.onSuccessfulSent();
                    } else {
                        this.onUnsuccessfulSent(this.props.unsuccessfulMessage);
                    }
                });
            } else {
                this.onUnsuccessfulSent(this.props.unsuccessfulMessage);
            }
        } else {
            this.onUnsuccessfulSent(this.props.badEmailMessage);
        }
    };

    onSuccessfulSent = () => {
        this.setState({email: '', newsletterMessage: {success: true, text: this.props.successMessage}});
    };

    onUnsuccessfulSent = (text) => this.setState({newsletterMessage: {success: false, text}});

    render() {
        const {isDividerVisible, logo, title, subTitle} = this.props;
        const justifyStart = isDividerVisible ? 'justify-content-start' : '';
        const marginLeft = isDividerVisible && 'footer-first-half';
        const newsletterAlign = isDividerVisible ? 'align-items-start' : 'align-items-center';
        return (
            <Grid container item className={`mb-5 ${marginLeft}`} xs={12} lg={5}>
                <Grid
                    container={!isDividerVisible}
                    item
                    className={justifyStart}
                    justify={!isDividerVisible ? 'center' : undefined}
                    xs={12}
                >
                    <img src={logo} alt=''/>
                </Grid>
                <Grid
                    container
                    item
                    justify='center'
                    alignItems='flex-end'
                    className={justifyStart}
                    xs={12}
                    lg={8}
                >
                    <p className='text-18 pt-lg-4'>{title}</p>
                </Grid>
                <Grid
                    container
                    item
                    justify='center'
                    className={justifyStart}
                    xs={12}
                >
                    <p className={`text-19 mb-1 ${!isDividerVisible ? 'text-center mb-3' : ''}`}>{subTitle}</p>
                </Grid>
                <Grid
                    container
                    item
                    direction='column'
                    className={newsletterAlign}
                    xs={12}
                >
                    <Input
                        type='email'
                        placeholder='Email'
                        value={this.state.email}
                        onChange={(event) => this.setState({email: event.target.value})}
                        onSubmit={this.onSubmitEmail}
                        showSendIcon
                    />
                    <div className='text-50-wrap'>
                        {this.renderNewsletterMessage()}
                    </div>
                </Grid>
                {this.props.bottomLinks()}
            </Grid>
        );
    }

    renderNewsletterMessage = () => {
        const {newsletterMessage} = this.state;
        if (newsletterMessage.text) {
            const style = newsletterMessage.success ? 'text-50' : 'text-50-red';
            return <p className={`${style} text-left mt-2`}>{newsletterMessage.text}</p>;
        }
        return null;
    };
}

export default FirstBlock;
