import React from 'react';
import {Grid} from '@material-ui/core';
import {InquiryForm} from '../../../components/inquiry/form/inquiryForm';
import Dropdown from '../../../components/dropdown/Dropdown';
import ContinueButton from '../../../components/inquiry/continueButton/continueButton';
import InquiryInput from '../../../components/inquiry/input/inquiryInput';
import {withTranslation} from 'react-i18next';

class CloudServiceInternal extends React.Component {
    title = `2. ${this.props.t('cloudService.title')}`;
    services = [
        {
            key: 'consultation',
            name: this.props.t('cloudService.services.consultation'),
        },
        {
            key: 'penetration',
            name: this.props.t('cloudService.services.penetration'),
        },
        {
            key: 'cloud development',
            name: this.props.t('cloudService.services.cloudDevelopment'),
        },
        {
            key: 'hardware',
            name: this.props.t('cloudService.services.hardware'),
        },
        {
            key: 'something else',
            name: this.props.t('cloudService.services.somethingElse'),
        },
    ];

    screenWidthBreakpoint = 1280;

    constructor(props) {
        super(props);
        this.state = {
            screenWidth: window.innerWidth,
            selectedService: this.props.selectedService || this.services[0],
            somethingElseInput: props.sometingElseInput || '',
        };
    }

    onChangeSelectedService = (selectedService) => this.setState({selectedService});

    onChangeSomethingElseInput = (target) => {
        const value = target.value;
        this.setState({somethingElseInput: value});
    };

    render() {
        const {sliderSettings, onNextClick, onPreviousClick, t} = this.props;
        return (
            <InquiryForm
                title={this.title}
                sliderSettings={sliderSettings}
                onNextClick={onNextClick}
                onPreviousClick={onPreviousClick}
            >
                <Grid container item className='my-4' xs={12}>
                    <Dropdown
                        selectedItem={this.state.selectedService}
                        items={this.services}
                        onChange={this.onChangeSelectedService}
                    />
                    {this.state.screenWidth > this.screenWidthBreakpoint
                    && <Grid item xs={12} lg={6} className='mt-3 mt-xl-0 ml-xl-3'>
                        <ContinueButton t={t} onClick={onNextClick}/>
                    </Grid>}
                    {this.renderSomethingElseInput()}
                    {this.state.screenWidth < this.screenWidthBreakpoint
                    && <Grid item xs={12} lg={6} className='mt-3 mt-xl-0 ml-xl-3'>
                        <ContinueButton t={t} onClick={onNextClick}/>
                    </Grid>}
                </Grid>
            </InquiryForm>
        );
    }

    renderSomethingElseInput = () => {
        const {t} = this.props;
        if (this.state.selectedService.key === this.services[4].key) {
            return (
                <Grid item xs={12} lg={6} className='mt-3'>
                    <InquiryInput
                        className='inquiry-input-3'
                        placeholder={t('inputPlaceholder')}
                        onChange={this.onChangeSomethingElseInput}
                        value={this.state.somethingElseInput}
                    />
                </Grid>
            );
        }
        return null;
    };

    componentWillUnmount() {
        const {selectedService, somethingElseInput} = this.state;
        this.props.saveSelectedService(selectedService);
        this.props.saveSomethingElseInput(somethingElseInput);
    }

    componentDidMount() {
        window.addEventListener('resize',
            () => this.setState({screenWidth: window.innerWidth}));
    }
}

export const CloudService = withTranslation('inquiry')(CloudServiceInternal);