import React from 'react';
import {ContainerSettings} from './containerSettings';
import {Resizer} from "../resizer/resizer";

const defaultProps = {
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    fillSpace: 'no',
    padding: ['0', '0', '0', '0'],
    margin: ['0', '0', '0', '0'],
    background: {r: 255, g: 255, b: 255, a: 1},
    color: {r: 0, g: 0, b: 0, a: 1},
    shadow: 0,
    radius: 0,
    width: '100%',
    height: 'auto',
};

export const Container = (props: any) => {
    props = {
        ...defaultProps,
        ...props,
    };
    const {
        flexDirection,
        alignItems,
        justifyContent,
        fillSpace,
        background,
        color,
        padding,
        margin,
        shadow,
        radius,
        children,
    } = props;
    return (
        <Resizer
            propKey={{width: 'width', height: 'height'}}
            style={{
                justifyContent,
                flexDirection,
                alignItems,
                background: `rgba(${Object.values(background)})`,
                color: `rgba(${Object.values(color)})`,
                padding: `${padding[0]}px ${padding[1]}px ${padding[2]}px ${padding[3]}px`,
                margin: `${margin[0]}px ${margin[1]}px ${margin[2]}px ${margin[3]}px`,
                boxShadow:
                    shadow === 0
                        ? 'none'
                        : `0px 3px 100px ${shadow}px rgba(0, 0, 0, 0.13)`,
                borderRadius: `${radius}px`,
                flex: fillSpace === 'yes' ? 1 : 'unset',
                display: 'flex',
                flexGrow: 0,
            }}
        >
            {children}
        </Resizer>
    );
};

Container.craft = {
    displayName: 'Container',
    props: defaultProps,
    rules: {
        canDrag: () => true,
    },
    related: {
        toolbar: ContainerSettings,
    },
};
