import React from 'react';
import {InquiryForm} from '../../../components/inquiry/form/inquiryForm';
import {Grid} from '@material-ui/core';
import Dropdown from '../../../components/dropdown/Dropdown';
import ContinueButton from '../../../components/inquiry/continueButton/continueButton';
import Checkbox from '../../../components/form/checkbox/checkbox';
import {withTranslation} from 'react-i18next';

class CloudCapacityInternal extends React.Component {
    title = `4. ${this.props.t('cloudCapacity.title')}`;
    cloudCapacities = [
        {
            key: '1000',
            name: '1000 GB',
        },
        {
            key: '2000',
            name: '2000 GB',
        },
        {
            key: '4000',
            name: '4000 GB',
        },
        {
            key: '8000',
            name: '8000 GB',
        },
        {
            key: '10000',
            name: '10 000 GB',
        },
    ];


    screenWidthBreakpoint = 1280;

    constructor(props) {
        super(props);
        this.state = {
            screenWidth: window.innerWidth,
            selectedCloudCapacity: props.selectedCloudCapacity || this.cloudCapacities[0],
            expandInFuture: props.expandInFuture,
        };
    }

    onChangeCloudCapacity = (selectedCloudCapacity) => this.setState({selectedCloudCapacity});

    render() {
        const {sliderSettings, onNextClick, onPreviousClick, t} = this.props;
        const {expandInFuture} = this.state;
        return (
            <InquiryForm
                title={this.title}
                sliderSettings={sliderSettings}
                onNextClick={onNextClick}
                onPreviousClick={onPreviousClick}
            >
                <Grid container item className='my-4' xs={12}>
                    <Dropdown
                        selectedItem={this.state.selectedCloudCapacity}
                        items={this.cloudCapacities}
                        onChange={this.onChangeCloudCapacity}
                    />
                    {this.state.screenWidth < this.screenWidthBreakpoint && <Checkbox
                        label={<p className='text-46'>{t('cloudCapacity.checkboxLabel')}</p>}
                        name='expandInFuture'
                        checked={expandInFuture}
                        className='flex-nowrap mb-2 mt-3'
                        onChange={() => this.setState({expandInFuture: !expandInFuture})}
                    />}
                    <Grid item xs={12} lg={6} className='mt-3 mt-xl-0 ml-xl-3'>
                        <ContinueButton t={t} onClick={onNextClick}/>
                    </Grid>
                </Grid>
                {this.state.screenWidth > this.screenWidthBreakpoint && <Checkbox
                    label={<p className='text-46'>{t('cloudCapacity.checkboxLabel')}</p>}
                    name='expandInFuture'
                    checked={expandInFuture}
                    className='flex-nowrap mb-3'
                    onChange={() => this.setState({expandInFuture: !expandInFuture})}
                />}
            </InquiryForm>
        );
    }

    componentDidMount() {
        window.addEventListener('resize',
            () => this.setState({screenWidth: window.innerWidth}));
    }

    componentWillUnmount() {
        const {selectedCloudCapacity, expandInFuture} = this.state;
        this.props.saveSelectedCloudCapacity(selectedCloudCapacity);
        this.props.saveExpandInFuture(expandInFuture);
    }
}

export const CloudCapacity = withTranslation('inquiry')(CloudCapacityInternal);