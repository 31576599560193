import React, {Fragment} from 'react';
import ReferenceUI from '../referenceUI/referenceUI';
import LanguageSelector from '../../../../languageSelector/languageSelector';
import AddIcon from '@material-ui/icons/Add';

class ReferenceBlock extends React.Component {
    state = {
        selectedLanguageIndex: 0,
    };

    get languages() {
        return this.props.references.map((item) => item.language);
    }

    onChangeLanguage = (languageIndex) => {
        const languages = this.languages;
        this.setState({
            selectedLanguageIndex: languages.findIndex((item) => item === languageIndex),
        });
    };

    render() {
        const {references} = this.props;
        return (
            <Fragment>
                {references.map(this.renderReferenceUI).filter((a) => !!a)}
            </Fragment>
        );
    }

    renderReferenceUI = (reference, index) => {
        if (this.state.selectedLanguageIndex === index) {
            return (
                <ReferenceUI
                    key={index.toString()}
                    reference={reference}
                    publicId={this.props.publicId}
                    languageSelector={this.renderLanguagesSelector}
                    onEditReference={() => this.props.onEditReference(reference)}
                    onRemoveReference={() => this.props.onRemoveReference(reference)}
                    onRemoveCaseStudy={() => this.props.onRemoveCaseStudy(reference)}
                />
            );
        }
        return null;
    };

    renderLanguagesSelector = (reference) => {
        const {selectedLanguageIndex} = this.state;
        const languages = this.languages;
        return (
            <div>
                <LanguageSelector
                    selectedLanguage={languages[selectedLanguageIndex]}
                    languages={languages.filter((item, index) => index !== selectedLanguageIndex)}
                    onChange={this.onChangeLanguage}
                />
                <AddIcon
                    className='blue-button-2 cursor-pointer ml-2 mb-2'
                    onClick={() => this.props.onAddReferenceLanguage(reference)}
                />
            </div>
        );
    };
}

export default ReferenceBlock;
