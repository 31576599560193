import React from 'react';
import {useNode, useEditor} from '@craftjs/core';
import {CheckmarkTextSettings} from './checkmarkTextSettings';
import ContentEditable from 'react-contenteditable';
import ListIcon from "../../../../../assets/list-ikon.png";

export const CheckmarkText
    = ({
           fontSize,
           textAlign,
           fontWeight,
           color,
           shadow,
           text,
           margin,
       }: any) => {
    const {
        connectors: {connect},
        setProp,
    } = useNode();
    const {enabled} = useEditor((state) => ({
        enabled: state.options.enabled,
    }));
    return (
        <div style={{
            flexDirection: 'row',
            display: 'flex',
            margin: `${margin[0]}px ${margin[1]}px ${margin[2]}px ${margin[3]}px`,
        }}>
            <img alt='list-icon' src={ListIcon} style={{width: '16px', height: '16px', marginTop: '4px'}}/>
            <ContentEditable
                innerRef={connect}
                html={text}
                disabled={!enabled}
                onChange={(e) => {
                    setProp((prop) => (prop.text = e.target.value), 500);
                }}
                tagName='p'
                style={{
                    width: '100%',
                    paddingLeft: '15px',
                    color: `rgba(${Object.values(color)})`,
                    fontSize: `${fontSize}px`,
                    textShadow: `0px 0px 2px rgba(0,0,0,${(shadow || 0) / 100})`,
                    fontFamily: 'WorkSans-Regular',
                    fontWeight,
                    textAlign,
                }}
            />
        </div>
    );
};

CheckmarkText.craft = {
    displayName: 'CheckmarkText',
    props: {
        fontSize: 17,
        textAlign: 'left',
        fontWeight: 'normal',
        color: {r: 40, g: 50, b: 105, a: 1},
        margin: [0, 0, 0, 0],
        shadow: 0,
        text: 'Kdyby pod balónky nebyla sytě zelenkavá tráva, ale třeba.',
        number: '1',
    },
    related: {
        toolbar: CheckmarkTextSettings,
    },
};
