import React from 'react';
import {NavLink, withRouter} from 'react-router-dom';
import {animateScroll as scroll} from 'react-scroll';
import LanguageDropdown from '../languageDropdown/languageDropdown';
import i18n from '../../i18n';
import {config} from '../../config/config';
// INFO:
//
// Properties:
// logo - company logo
// enableFixedNavbar - when is true, navbar is still on top of website
// items - all website links
// activeItemStyle - active link style
class Navbar extends React.Component {
    languages = [
        {
            name: 'CZ',
            locale: 'cs',
        },
        config.features.ENTranslation.allow && {
            name: 'EN',
            locale: 'en',
        },
    ];

    getTargetLanguage = () => {
        const i18nLanguage = i18n.language.toLowerCase();
        const targetLanguage = this.languages
            .find((lng) => lng && lng.locale.toLowerCase().includes(i18nLanguage));
        return targetLanguage ? targetLanguage : this.languages[0];
    };

    state = {
        selectedLanguage: this.getTargetLanguage(),
        isMenuOpened: false,
    };

    onChangeLanguageDropdown = (selectedLanguage) => {
        this.setState({selectedLanguage});
        i18n.changeLanguage(selectedLanguage.locale);
    };

    render() {
        const {location} = this.props;
        const {isMenuOpened} = this.state;
        const showMenu = isMenuOpened
            ? 'show'
            : '';
        return (
            <nav className='navbar navbar-fixed navbar-expand-xl navbar-light bg-light w-100'>
                {location.pathname === '/' ? this.renderHomePageLogo() : this.renderAnotherPageLogo()}
                <button
                    className='navbar-toggler'
                    type='button'
                    data-toggle='collapse'
                    data-target='#navbarSupportedContent'
                    aria-controls='navbarSupportedContent'
                    aria-expanded='false'
                    onClick={() => this.setState({isMenuOpened: !isMenuOpened})}
                >
                    <span className='navbar-toggler-icon'/>
                </button>
                <div className={`collapse navbar-collapse ${showMenu}`}>
                    <ul className='navbar-nav align-items-end ml-auto'>
                        {this.renderItems()}
                        <li className='pt-2 mr-md-2 pr-1'>
                            <LanguageDropdown
                                selectedLanguage={this.state.selectedLanguage}
                                languages={this.languages.filter((a) => !!a)}
                                onChange={this.onChangeLanguageDropdown}
                            />
                        </li>
                    </ul>
                </div>
            </nav>
        );
    }

    renderHomePageLogo = () => {
        const {logo} = this.props;
        return (
            <div onClick={() => scroll.scrollToTop()} className='cursor-pointer navbar-brand plc-lg-2'>
                <img src={logo} className='d-inline-block align-top navbar-logo' alt=''/>
            </div>
        );
    };

    renderAnotherPageLogo = () => {
        const {logo} = this.props;
        return (
            <NavLink
                exact
                className='navbar-brand plc-lg-2'
                to='/'
            >
                <img src={logo} className='d-inline-block align-top navbar-logo' alt=''/>
            </NavLink>
        );
    };

    renderItems = () => {
        const {items, activeItemStyle} = this.props;
        return items.map((item, index) => {
            if (item.isButton) {
                return (
                    <li className='nav-item mr-xl-4 mr-md-2 pr-1' key={index}>
                        <NavLink
                            to={item.link}
                            className={item.style}
                            onClick={() => this.setState({isMenuOpened: false})}
                        >
                            {item.name}
                        </NavLink>
                    </li>
                );
            }
            const style = `${item.style} nav-link`;
            return (
                <li className='nav-item mr-xl-4 mr-md-2 pr-1' key={index}>
                    <NavLink
                        exact
                        to={item.link}
                        className={style}
                        activeClassName={activeItemStyle}
                        onClick={() => this.setState({isMenuOpened: false})}
                    >
                        {item.name}
                    </NavLink>
                </li>
            );
        });
    };
}

export default withRouter(props => <Navbar {...props}/>);
