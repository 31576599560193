import React from 'react';
import {Grid} from '@material-ui/core';
import SocialMedia from '../footer/footerSocialMedia/socialMedia/SocialMedia';
import FacebookLogo from '../../assets/Social Network-Facebook.png';
import FacebookLogoYellow from '../../assets/Social Network-Facebook ON.png';
import LinkedinLogo from '../../assets/Social Network-Linkedin.png';
import LinkedinLogoYellow from '../../assets/Social Network-Linkedin ON.png';
import TwitterLogo from '../../assets/Social Network-Twitter.png';
import TwitterLogoYellow from '../../assets/Social Network-Twitter ON.png';
import InstagramLogo from '../../assets/Social Network-Instagram.png';
import InstagramLogoYellow from '../../assets/Social Network-Instagram ON.png';

// INFO:
// render text with image and socialMedia buttons, responsive
//
// Properties:
// image - set the contact image
// name - set the contact name
// info - set the information about contact
// style - adds another style next to existing
// swipe - swipe the image and and all texts
// socialMedia - object with href (link to social media) and type (facebook, twitter...)
class ContactCard extends React.Component {

    getSocialMediaIconByType = (type) => {
        let normal;
        let hover;
        if (type === 'facebook') {
            normal = FacebookLogo;
            hover = FacebookLogoYellow;
        }
        if (type === 'linkedin') {
            normal = LinkedinLogo;
            hover = LinkedinLogoYellow;
        }
        if (type === 'twitter') {
            normal = TwitterLogo;
            hover = TwitterLogoYellow;
        }
        if (type === 'instagram') {
            normal = InstagramLogo;
            hover = InstagramLogoYellow;
        }
        return {normal, hover};
    };

    render() {
        if (this.props.small) {
            return this.renderSmallSize();
        }
        return this.renderNormalSize();
    }

    renderNormalSize = () => {
        const {image, name, position, info, className, swipe, containerStyle} = this.props;
        return (
            <div className={`${containerStyle || 'contact-card'} row${' ' + className || ''}`}>
                {!swipe && <img src={image} className='contact-card-image' alt=''/>}
                <div className={`col align-self-center ${swipe ? 'mr-4' : 'ml-4'}`}>
                    <p className={`text-32 ${swipe && 'text-right'}`}>{name}</p>
                    <p className={`text-33 ${swipe && 'text-right'}`}>{position}</p>
                    <p className={`text-34 ${swipe && 'text-right'}`}>{info}</p>
                    <div className={`row ${swipe && 'justify-content-end'} mx-1`}>
                        {this.renderSocialMedia()}
                    </div>
                </div>
                {swipe && <img src={image} className='contact-card-image' alt=''/>}
            </div>
        );
    };

    renderSmallSize = () => {
        const {image, name, position, info} = this.props;
        return (
            <Grid container item className='contact-card-2 m-auto text-center'>
                <Grid item xs={12}>
                    <img src={image} className='contact-card-image' alt=''/>
                </Grid>
                <Grid item xs={12} className='m-auto py-4'>
                    <p className='text-32'>{name}</p>
                    <p className='text-33'>{position}</p>
                    <div className='row mx-1 justify-content-center'>
                        {this.renderSocialMedia()}
                    </div>
                </Grid>
                <p className='text-34 px-4'>{info}</p>
            </Grid>
        );
    };

    renderSocialMedia = () => {
        const {socialMedia} = this.props;
        return socialMedia.map((item, value) => {
            const icons = this.getSocialMediaIconByType(item.type);
            return (
                <SocialMedia
                    key={value}
                    href={item.href}
                    alt={`${item.type}-logo`}
                    icon={icons.normal}
                    iconHover={icons.hover}
                    style={value + 1 !== socialMedia.length ? 'mr-3' : ''}
                />
            );
        });
    };
}

export default ContactCard;