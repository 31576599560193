import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {formatUTCDate} from '../../../../utils/utils';

export default function BlogRemoveDialog(props) {
    const title = 'Are you sure you want remove this article content?';
    if (!props.selectedArticle) {
        return null;
    }
    return (
        <Dialog
            open={props.show}
            onClose={props.onDisagree}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
        >
            <DialogTitle
                id='alert-dialog-title'
            >
                <p
                    style={{
                        fontFamily: '$work-sans',
                        fontSize: '23px',
                        fontWeight: 600,
                        lineHeight: 1.25,
                        color: '#283269',
                    }}
                >
                    {title}
                </p>
            </DialogTitle>
            <DialogContent>
                <DialogContentText id='alert-dialog-description' className='ml-3'>
                    {props.selectedArticle.title && <div className='row'>
                        <p className='text-23'>Title:</p>
                        <p className='text-24 ml-3' style={{marginTop: '2px'}}>
                            {props.selectedArticle.title}
                        </p>
                    </div>}
                    {props.selectedArticle.language && <div className='row'>
                        <p className='text-23'>Language:</p>
                        <p className='text-24 ml-3' style={{marginTop: '2px'}}>
                            {props.selectedArticle.language}
                        </p>
                    </div>}
                    {props.selectedArticle.contentMetadata.category && <div className='row'>
                        <p className='text-23'>Category:</p>
                        <p className='text-24 ml-3' style={{marginTop: '2px'}}>
                            {props.selectedArticle.contentMetadata.category}
                        </p>
                    </div>}
                    {props.selectedArticle.contentMetadata.createdAt && <div className='row'>
                        <p className='text-23'>Created at:</p>
                        <p className='text-24 ml-3' style={{marginTop: '2px'}}>
                            {formatUTCDate(props.selectedArticle.contentMetadata.createdAt)}
                        </p>
                    </div>}
                    {props.selectedArticle.contentMetadata.lastUpdated && <div className='row'>
                        <p className='text-23'>Last updated:</p>
                        <p className='text-24 ml-3' style={{marginTop: '2px'}}>
                            {formatUTCDate(props.selectedArticle.contentMetadata.lastUpdated)}
                        </p>
                    </div>}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onDisagree} autoFocus>
                    <p className='text-24'>Disagree</p>
                </Button>
                <Button onClick={() => props.onAgree(props.selectedArticle.id)}>
                    <p className='text-24'>Agree</p>
                </Button>
            </DialogActions>
        </Dialog>
    );
}
