import React, {Suspense} from 'react';
import {withTranslation} from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import CategoryManager from './manager/category/categoryManager';
import ArticlePreviewManager from './manager/articlePreview/articlePreviewManager';
import {fetchAllArticlesPreviews} from '../../api/blog/blogApi';
import {formatUTCDate} from '../../utils/utils';
import i18n from '../../i18n';
import {exampleCategoryItems} from './examples/categories';
import {examplePreviewArticles} from './examples/previewArticles';
import LoadingScreen from '../../components/emptyScreen/loadingScreen';
import Title from '../../components/title/title';

class BlogInternal extends React.Component {

    state = {
        allArticlesPreviews: [],
        selectedArticlesPreviews: [],
        redirect: {
            active: false,
            url: null,
        },
        fetched: false,
    };

    // INFO:
    // Render example items (categories, articlePreview)
    renderExampleItems = false;

    getAllCategories = () => {
        const {allArticlesPreviews} = this.state;
        if (allArticlesPreviews.length > 0) {
            return [...new Set(allArticlesPreviews.map((articlePreview) => articlePreview.category))];
        }
        return [];
    };

    onClickCategory = (category) => {
        const {allArticlesPreviews} = this.state;
        let newSelectedArticlesPreviews;
        if (category !== 'all') {
            newSelectedArticlesPreviews = allArticlesPreviews
                .filter((articlePreview) => articlePreview.category === category);
        } else {
            newSelectedArticlesPreviews = allArticlesPreviews;
        }
        this.setState({selectedArticlesPreviews: newSelectedArticlesPreviews});
    };

    render() {
        const {t} = this.props;
        const {fetched, selectedArticlesPreviews} = this.state;
        if (fetched) {
            const articlesPreviews = this.renderExampleItems ? examplePreviewArticles : selectedArticlesPreviews;
            return (
                <Suspense fallback={<LoadingScreen/>}>
                    <Grid container spacing={0} style={{marginBottom: '150px'}}>
                        <Title text={this.props.t('welcome.title')}/>
                        <CategoryManager
                            t={t}
                            categories={this.renderExampleItems ? exampleCategoryItems : this.getAllCategories()}
                            onClickCategory={this.onClickCategory}
                        />
                        <ArticlePreviewManager
                            t={t}
                            articlesPreviews={articlesPreviews}
                        />
                    </Grid>
                </Suspense>
            );
        }
        return <LoadingScreen/>;
    }

    async componentDidMount() {
        window.scrollTo(0, 0);
        i18n.on('languageChanged', async (lng) => {
            await this.fetchArticles(lng);
        });
        await this.fetchArticles(i18n.language);
    }

    fetchArticles = async (lng) => {
        await this.setState({fetched: false}, async () => {
            const response = await fetchAllArticlesPreviews(lng.toLocaleUpperCase());
            let allArticlesPreviews = [];
            if (response && response.data && response.data.length > 0) {
                let rawAllArticlesPreviews = [];
                response.data.forEach((item) => {
                    rawAllArticlesPreviews.push(...item.articles);
                });
                const formattedAllArticlesPreviews = rawAllArticlesPreviews.map((item) => {
                    return {...item, date: formatUTCDate(item.createdAt)};
                });
                if (formattedAllArticlesPreviews && formattedAllArticlesPreviews.length > 0) {
                    allArticlesPreviews = formattedAllArticlesPreviews;
                }
            }
            this.setState({
                fetched: true,
                allArticlesPreviews: allArticlesPreviews,
                selectedArticlesPreviews: allArticlesPreviews,
            });
        });
    };
}

export const Blog = withTranslation('blog')(BlogInternal);
