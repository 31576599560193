import React from 'react';
import Grid from '@material-ui/core/Grid';
import ReferenceRemoveDialog from '../../../components/reference/admin/removeDialog/referenceRemoveDialog';
import ReferenceToolbar from '../../../components/reference/admin/toolbar/referenceToolbar';
import ReferenceTable from '../../../components/reference/admin/table/referenceTable';
import ReferenceSaveDialog from '../../../components/reference/admin/saveDialog/referenceSaveDialog';
import {config} from '../../../config/config';
import backward from '../../../assets/form-backward.png';
import CaseStudyRemoveDialog from '../../../components/reference/admin/caseStudyRemoveDialog/caseStudyRemoveDialog';

class ReferenceAdmin extends React.Component {

    state = {
        showSaveDialog: false,
        showRemoveDialog: false,
        showCaseStudyRemoveDialog: false,
        selectedReference: null,
    };
    title = 'Reference and Case Study management';
    backToHomepage = 'Back to homepage';

    // INFO:
    // We get all languages from config, which are not use
    getAllowedReferenceLanguages = () => {
        if (this.state.selectedReference &&
            this.state.selectedReference.reference &&
            this.props.references &&
            this.props.references.length > 0) {
            const publicId = this.state.selectedReference.reference;
            const references = this.props.references
                .find((reference) => reference.publicId === publicId).referenceContents;
            const allReferenceLanguages = references
                .map((reference) => reference.language);
            return config.allowedReferenceLanguages
                .filter((lng) => !allReferenceLanguages.includes(lng));
        }
        return config.allowedReferenceLanguages;
    };

    showRemoveDialog = (reference) => {
        this.setState({selectedReference: reference, showRemoveDialog: true});
    };

    showCaseStudyDialog = (reference) => {
        this.setState({selectedReference: reference, showCaseStudyRemoveDialog: true});
    };

    showSaveDialog = (reference) => {
        const {showSaveDialog, showRemoveDialog} = this.state;
        if (!showSaveDialog && !showRemoveDialog) {
            this.setState({selectedReference: reference, showSaveDialog: true});
        }
    };

    onSaveReference = async (newReference) => {
        const prevReference = this.state.selectedReference;
        let prevCompanyLogo;
        if (prevReference && prevReference.referenceCompanyLogo) {
            prevCompanyLogo = {...prevReference.referenceCompanyLogo};
        }
        const targetReference = {
            id: prevReference && prevReference.id ? prevReference.id : null,
            reference: prevReference && prevReference.reference ? prevReference.reference : null,
            language: newReference.language,
            companyName: newReference.companyName,
            companyWebUrl: newReference.companyWebUrl,
            clientName: newReference.clientName,
            clientRole: newReference.clientRole,
            referenceText: newReference.referenceText,
            referenceCompanyLogo: {prevCompanyLogo, data: newReference.companyLogo},
        };
        if (targetReference && targetReference.id) {
            this.props.onUpdate && this.props.onUpdate(targetReference);
        } else {
            this.props.onCreate && this.props.onCreate(targetReference);
        }
    };

    onAddReferenceLanguage = (referenceContent) => {
        const selectedReference = {
            reference: referenceContent.reference,
        };
        this.showSaveDialog(selectedReference);
    };

    render() {
        const {references} = this.props;
        const referencesWithIndex = references && references.length > 0 && references.map((item, index) => {
            return {id: index, ...item};
        });
        return (
            <Grid container className='background-3 p-4 h-100'>
                {this.renderSaveDialog()}
                {this.renderRemoveDialog()}
                {this.renderCaseStudyRemoveDialog()}
                {this.renderTitleBar()}
                <Grid item container xs={12}>
                    {this.renderApiError()}
                    {this.renderToolbar()}
                    {referencesWithIndex && referencesWithIndex.length > 0
                    && <ReferenceTable
                        data={referencesWithIndex}
                        onAddReferenceLanguage={this.onAddReferenceLanguage}
                        onEditReference={this.showSaveDialog}
                        onRemoveReference={this.showRemoveDialog}
                        onRemoveCaseStudy={this.showCaseStudyDialog}
                    />}
                </Grid>
            </Grid>
        );
    }

    renderTitleBar = () => {
        const referencesUrl = '/references';
        return (
            <Grid item container className='mt-5 mb-4' xs={12}>
                <h1 className='text-title-universal'>{this.title}</h1>
                <a href={referencesUrl} className='text-back-to-homepage ml-auto mt-2'>
                    <img src={backward} alt='backward' className='mr-2'/>
                    {this.backToHomepage}
                </a>
            </Grid>
        );
    };

    renderApiError = () => {
        const {renderApiError} = this.props;
        if (renderApiError) {
            return (
                <Grid item xs={6} className='pt-3 pb-2'>
                    {renderApiError}
                </Grid>
            );
        }
        return null;
    };

    renderToolbar = () => {
        const {isApiEnabled} = this.props;
        if (isApiEnabled) {
            return (
                <Grid item xs className='mb-4'>
                    <ReferenceToolbar onAddClick={this.showSaveDialog}/>
                </Grid>
            );
        }
        return null;
    };

    renderSaveDialog = () => {
        const {selectedReference, showSaveDialog} = this.state;
        if (showSaveDialog) {
            return (
                <ReferenceSaveDialog
                    show={showSaveDialog}
                    reference={selectedReference}
                    languages={this.getAllowedReferenceLanguages()}
                    onClose={() => this.setState({showSaveDialog: false})}
                    onSave={this.onSaveReference}
                />
            );
        }
        return null;
    };

    renderRemoveDialog = () => {
        const {selectedReference, showRemoveDialog} = this.state;
        if (selectedReference) {
            return (
                <ReferenceRemoveDialog
                    show={showRemoveDialog}
                    selectedReference={selectedReference}
                    onDisagree={() => this.setState({showRemoveDialog: false})}
                    onAgree={this.props.onRemove}
                />
            );
        }
        return null;
    };

    renderCaseStudyRemoveDialog = () => {
        const {selectedReference, showCaseStudyRemoveDialog} = this.state;
        if (showCaseStudyRemoveDialog) {
            const caseStudy = selectedReference.referenceCaseStudy;
            return (
                <CaseStudyRemoveDialog
                    show={showCaseStudyRemoveDialog}
                    selectedCaseStudy={caseStudy}
                    onDisagree={() => this.setState({showCaseStudyRemoveDialog: false})}
                    onAgree={() => this.props.onRemoveCaseStudy(selectedReference.id)}
                />
            );
        }
        return null;
    };
}

export default ReferenceAdmin;
