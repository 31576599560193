import React from 'react';
import SendBlue from '../../../assets/Send-blue.png';
import SendYellow from '../../../assets/Send-yellow.png';
import {config} from '../../../config/config';

class Input extends React.Component {
    state = {
        isIconHover: false,
    };

    getMaxLength = () => this.props.maxLength || config.inputLength.name;

    getMaxEmailLength = () => this.props.maxLength || config.inputLength.email;

    onIconMouseEnter = () => this.setState({isIconHover: true});

    onIconMouseLeave = () => this.setState({isIconHover: false});

    onChange = (event) => this.props.onChange && this.props.onChange(event.target);

    onKeyPress = (event) => {
        if (event.key === 'Enter') {
            this.props.onSubmit && this.props.onSubmit();
        }
    };

    render() {
        const {showSendIcon} = this.props;
        if (showSendIcon) {
            return this.renderWithSendIcon();
        }
        return this.renderNormalIcon();
    }

    renderNormalIcon = () => {
        const {name, type, placeholder, value, className, showLength} = this.props;
        const maxLength = this.getMaxLength();
        return (
            <div className='input-2-icon-wrapper'>
                <input
                    name={name}
                    type={type}
                    placeholder={placeholder}
                    value={value}
                    className={className || 'input-2'}
                    onChange={this.onChange}
                    maxLength={maxLength}
                />
                {showLength && <p className='input-length'>{value.length <= 0 ? '' : maxLength}</p>}
            </div>
        );
    };

    renderWithSendIcon = () => {
        const {isIconHover} = this.state;
        const {name, type, placeholder, value} = this.props;
        const maxLength = this.getMaxEmailLength();
        const targetIcon = !isIconHover
            ? SendBlue
            : SendYellow;
        return (
            <div className='input-3-icon-wrapper'>
                <input
                    id='input-2'
                    name={name}
                    type={type}
                    placeholder={placeholder}
                    onChange={this.props.onChange}
                    onKeyPress={this.onKeyPress}
                    value={value}
                    className='input-email'
                    maxLength={maxLength}
                />
                <img
                    src={targetIcon}
                    className='input-2-icon'
                    onClick={() => this.props.onSubmit && this.props.onSubmit()}
                    onMouseEnter={this.onIconMouseEnter}
                    onMouseLeave={this.onIconMouseLeave}
                    alt='send-icon'
                />
            </div>
        );
    };
}

export default Input;