import React from 'react';
import ButtonArrowBlue from '../../../../../assets/button-arrow-blue.png';
import ButtonArrowGold from '../../../../../assets/button-arrow-yellow.png';

class ShowCaseStudyButton extends React.Component {

    state = {
        isHover: false,
    };

    onMouseEnter = () => this.setState({isHover: true});

    onMouseLeave = () => this.setState({isHover: false})

    getTargetArrow = () => {
        if (!this.state.isHover) {
            return ButtonArrowBlue;
        }
        return ButtonArrowGold;
    };

    render() {
        return (
            <p
                className='text-66 cursor-pointer'
                onClick={this.props.onClick}
                onMouseEnter={this.onMouseEnter}
                onMouseLeave={this.onMouseLeave}
            >
                {this.props.t('exampleSolution')}
                <img src={this.getTargetArrow()} className='ml-3 mb-1' alt=''/>
            </p>
        );
    }
}

export default ShowCaseStudyButton;