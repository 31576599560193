import React from 'react';
import {useNode, useEditor} from '@craftjs/core';
import {NumberedParagraphSettings} from './numberedParagraphSettings';
import ContentEditable from 'react-contenteditable';

export const NumberedParagraph
    = ({
           fontSize,
           textAlign,
           fontWeight,
           color,
           shadow,
           text,
           number,
           margin,
       }: any) => {
    const {
        connectors: {connect},
        setProp,
    } = useNode();
    const {enabled} = useEditor((state) => ({
        enabled: state.options.enabled,
    }));
    return (
        <div style={{
            flexDirection: 'row',
            display: 'flex',
        }}>
            <ContentEditable
                innerRef={connect}
                html={number}
                disabled={!enabled}
                onChange={(e) => {
                    setProp((prop) => (prop.number = e.target.value), 500);
                }}
                tagName='h2'
                style={{
                    width: '30px',
                    height: '30px',
                    color: '#283269',
                    margin: `${margin[0]}px ${margin[1]}px ${margin[2]}px ${margin[3]}px`,
                    fontSize: `15px`,
                    fontFamily: 'WorkSans-Regular',
                    border: 'solid 2px #e5a73a',
                    borderRadius: '100px',
                    fontWeight: 'bold',
                    paddingTop: '3.2px',
                    textAlign: 'center',
                }}
            />
            <ContentEditable
                innerRef={connect}
                html={text}
                disabled={!enabled}
                onChange={(e) => {
                    setProp((prop) => (prop.text = e.target.value), 500);
                }}
                tagName='p'
                style={{
                    width: '100%',
                    paddingLeft: '15px',
                    margin: `${margin[0]}px ${margin[1]}px ${margin[2]}px ${margin[3]}px`,
                    color: `rgba(${Object.values(color)})`,
                    fontSize: `${fontSize}px`,
                    textShadow: `0px 0px 2px rgba(0,0,0,${(shadow || 0) / 100})`,
                    fontFamily: 'WorkSans-Regular',
                    fontWeight,
                    textAlign,
                }}
            />
        </div>
    );
};

NumberedParagraph.craft = {
    displayName: 'NumberedParagraph',
    props: {
        fontSize: 17,
        textAlign: 'left',
        fontWeight: 'normal',
        color: {r: 40, g: 50, b: 105, a: 1},
        margin: [0, 0, 0, 0],
        shadow: 0,
        text: 'Vítr skoro nefouká a tak by se na první pohled mohlo zdát, ' +
            'že se balónky snad vůbec nepohybují. Jenom tak klidně levitují ve vzduchu. ' +
            'Jelikož slunce jasně září a na obloze byste od východu k západu.',
        number: '1',
    },
    related: {
        toolbar: NumberedParagraphSettings,
    },
};
