import React, {useEffect, useState} from 'react';
import {useEditor} from '@craftjs/core';
import styled from 'styled-components';
import {Redirect} from 'react-router';
import lz from "lzutf8";

const HeaderDiv = styled.div<{ enabled: boolean }>`
  width: ${(props) => (props.enabled ? '100%' : '800px')};
  z-index: 99999;
  position: fixed;
  transform: translateX(-50%);
  left: 50%;

  ${(props) =>
          !props.enabled
                  ? `
    backdrop-filter: blur(12px);
    background: #ccccccc2;
    color:#2d2d2d;
  `
                  : ''}
`;

const Btn = styled.a`
  display: flex;
  align-items: center;
  padding: 5px 15px;
  border-radius: 3px;
  color: #fff;
  font-size: 13px;

  svg {
    margin-right: 6px;
    width: 12px;
    height: 12px;
    fill: #fff;
    opacity: 0.9;
  }
`;

export const Header = (props) => {
    const {
        query,
        enabled,
        actions: {deserialize},
    } = useEditor((state) => ({
        enabled: state.options.enabled,
    }));
    const [redirect] = useState(false);
    const [second, setSecond] = useState(props.autoSaveSeconds);
    useEffect(() => {
        const timer = setTimeout(() => {
            calculateSecond();
        }, 1000);
        return () => clearTimeout(timer);
    });
    const calculateSecond = () => {
        let newSecond = second - 1;
        if (second === 0) {
            newSecond = props.autoSaveSeconds;
            props.onSave(lz.encodeBase64(lz.compress(query.serialize())), true);
        }
        setSecond(newSecond);
    };
    if (redirect) {
        return <Redirect to='/blog/admin'/>;
    }
    return (
        <HeaderDiv
            enabled={enabled}
            className='header bg-light-gray-1 text-white transition w-full'
        >
            <div className='items-center flex w-full px-4' style={{height: "50px"}}>
                <div className='flex-1'>
                    {props.infoText}
                </div>
                <div className='flex' style={{height: '30px'}}>
                    {props.isUsedForBlog
                    && props.autoSaveStatus()}
                    {props.isUsedForBlog
                    && <p style={{height: '9px'}} className='mr-2 mt-1'>{`Autosave in ${second} seconds.`}</p>}
                    {!props.isDialogOpen
                    && props.languageSelector(deserialize, () => lz.encodeBase64(lz.compress(query.serialize())))}
                    <Btn
                        style={{
                            textDecoration: 'none',
                            color: '#ffffff',
                            backgroundColor: '#cf3939',
                            cursor: 'pointer'
                        }}
                        className='mr-3'
                        onClick={() => {
                            props.onCancel(lz.encodeBase64(lz.compress(query.serialize())))
                        }}
                    >
                        Close
                    </Btn>
                    {!props.isDialogOpen && <Btn
                        style={{
                            textDecoration: 'none',
                            color: '#283269',
                            backgroundColor: '#ffffff',
                            cursor: 'pointer'
                        }}
                        onClick={() => {
                            props.onEdit(lz.encodeBase64(lz.compress(query.serialize())));
                        }}
                    >
                        Metadata
                    </Btn>}
                    {!props.isDialogOpen && <Btn
                        style={{
                            textDecoration: 'none',
                            color: '#ffffff',
                            backgroundColor: '#3b9d31',
                            cursor: 'pointer'
                        }}
                        className='ml-3'
                        onClick={() => {
                            props.onSave(lz.encodeBase64(lz.compress(query.serialize())))
                        }}
                    >
                        Save
                    </Btn>}
                </div>
            </div>
        </HeaderDiv>
    );
};