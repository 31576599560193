import React, {Suspense} from 'react';
import {createArticle, fetchAllArticles, removeArticle} from '../../../api/blog/blogApi';
import BlogAdmin from '../../../screens/blog/admin/blogAdmin';
import LoadingScreen from '../../../components/emptyScreen/loadingScreen';
import {Redirect} from 'react-router-dom';
import {getFormData} from '../../../components/blog/shared/blogShared';

class BlogAdminProvider extends React.Component {

    state = {
        fetched: false,
        articleRedirectUrl: null,
        articles: [],
        apiError: {},
    };

    editArticleUrl = '/blog/admin/editor/';
    previewArticleUrl = '/blog/article/';

    onCreateArticle = async (article, cb) => {
        const data = getFormData(article);
        await createArticle(data)
            .then(async (response) => {
                if (response) {
                    if (response.success) {
                        const content = response.data;
                        this.setState({articleRedirectUrl: `${content.article}/${content.language}`});
                        return;
                    }
                    if (!response.success || response.error) {
                        const apiError = {
                            error: true,
                            message: response.message.includes('ERROR')
                                ? response.message
                                : `ERROR: ${response.message}`,
                        };
                        this.setState({apiError});
                    }
                }
                cb && cb();
            });
    };

    onRemoveArticle = async (id) => {
        await removeArticle(id)
            .then((response) => {
                if (response.success) {
                    window.location.reload();
                }
                if (!response.success || response.error) {
                    const apiError = {
                        error: true,
                        message: response.message.includes('ERROR')
                            ? response.message
                            : `ERROR: ${response.message}`,
                    };
                    this.setState({apiError});
                }
            });
    };

    render() {
        const {fetched, articles, apiError, articleRedirectUrl} = this.state;
        if (fetched) {
            if (articleRedirectUrl) {
                return <Redirect exact to={`${this.editArticleUrl}${articleRedirectUrl}`}/>;
            }
            return (
                <Suspense fallback={<LoadingScreen/>}>
                    <BlogAdmin
                        articles={articles}
                        renderApiError={this.renderApiError()}
                        isApiEnabled={!apiError.fatalError}
                        onCreateArticle={this.onCreateArticle}
                        onRemoveArticle={this.onRemoveArticle}
                        editArticleUrl={this.editArticleUrl}
                        previewArticleUrl={this.previewArticleUrl}
                        {...this.props}
                    />
                </Suspense>
            );
        }
        return <LoadingScreen/>;
    }

    renderApiError = () => {
        const {apiError} = this.state;
        if (apiError && apiError.error && apiError.message) {
            return <p className='text-error'>{apiError.message}</p>;
        }
        return null;
    };

    async componentDidMount() {
        await this.setState({fetched: false}, async () => {
            const {location} = this.props;
            const response = await fetchAllArticles();
            let articles = [];
            let apiError = {};
            if (response && response.data && response.data.length > 0) {
                articles = response.data;
            }
            if (location && location.state) {
                apiError = location.state.apiError;
            }
            if (response && response.error) {
                apiError = response;
            }
            this.setState({articles, apiError, fetched: true});
        });
    }
}

export default BlogAdminProvider;
