import React from 'react';
import {Grid} from '@material-ui/core';
import {InquiryForm} from '../../../components/inquiry/form/inquiryForm';
import InquiryInput from '../../../components/inquiry/input/inquiryInput';
import ContinueButton from '../../../components/inquiry/continueButton/continueButton';
import Dropdown from '../../../components/dropdown/Dropdown';
import aWSIcon from '../../../assets/AWS.svg';
import googleIcon from '../../../assets/Google.svg';
import azureIcon from '../../../assets/Azure.svg';
import redHatIcon from '../../../assets/RedHat.svg';
import privateCloudIcon from '../../../assets/Cloud.svg';
import otherIcon from '../../../assets/Jine.svg';
import {withTranslation} from 'react-i18next';


class CloudSolutionInternal extends React.Component {
    title = `2. ${this.props.t('cloudSolution.title')}`;
    solutions = [
        {
            key: 'amazon',
            name: this.props.t('cloudSolution.solutions.aws'),
            src: aWSIcon,
        },
        {
            key: 'google',
            name: this.props.t('cloudSolution.solutions.google'),
            src: googleIcon,

        },
        {
            key: 'microsoft',
            name: this.props.t('cloudSolution.solutions.microsoft'),
            src: azureIcon,
        },
        {
            key: 'redhat',
            name: this.props.t('cloudSolution.solutions.redhat'),
            src: redHatIcon,
        },
        {
            key: 'own cloud',
            name: this.props.t('cloudSolution.solutions.own'),
            src: privateCloudIcon,
        },
        {
            key: 'other',
            name: this.props.t('cloudSolution.solutions.other'),
            src: otherIcon,
        },
    ];

    constructor(props) {
        super(props);
        this.state = {
            selectedSolution: props.selectedSolution || this.solutions[0],
            inputOtherValue: props.inputOtherValue,
        };
    }

    onChangeSolution = (selectedSolution) => {
        this.setState({
            selectedSolution,
        });
    };

    onChangeOtherInput = (target) => {
        const inputOtherValue = target.value;
        this.setState({
            inputOtherValue,
        });
    };

    render() {
        const {sliderSettings, onNextClick, onPreviousClick, t} = this.props;
        return (
            <InquiryForm
                title={this.title}
                sliderSettings={sliderSettings}
                onNextClick={onNextClick}
                onPreviousClick={onPreviousClick}
            >
                <Grid container item className='my-4' xs={12}>
                    <Dropdown
                        selectedItem={this.state.selectedSolution}
                        items={this.solutions}
                        onChange={this.onChangeSolution}
                    />
                    <Grid item xs={12} lg={6} className='mt-3 mt-xl-0 ml-xl-3'>
                        <ContinueButton t={t} onClick={() => onNextClick(this.state)}/>
                    </Grid>
                    {this.renderInquiryInput()}
                </Grid>
            </InquiryForm>
        );
    }

    renderInquiryInput = () => {
        if (this.state.selectedSolution.key === this.solutions[5].key) {
            return (
                <Grid item xs={12} lg={6} className='mt-3'>
                    <InquiryInput
                        className='inquiry-input-3'
                        placeholder={this.props.t('inputPlaceholder')}
                        onChange={this.onChangeOtherInput}
                        value={this.state.inputOtherValue}
                    />
                </Grid>
            );
        }
        return null;
    };

    componentWillUnmount() {
        const {selectedSolution, inputOtherValue} = this.state;
        this.props.saveSelectedSolution(selectedSolution);
        this.props.saveInputOtherValue(inputOtherValue);
    }
}

export const CloudSolution = withTranslation('inquiry')(CloudSolutionInternal);