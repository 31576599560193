import emailjs from 'emailjs-com';

export function sendEmail(params, cb) {
    const userId = 'user_RSU8o5z1Bc90TXprYGm1s';
    const serviceId = 'service_zl17fat';
    const templateId = 'template_jiqlp9r';
    emailjs.send(serviceId, templateId, params, userId)
        .then(() => cb && cb())
        .catch((error) => {
            console.error(error);
            cb && cb(error);
        });
}