import React, {useState} from 'react';
import {Layers} from '@craftjs/layers';
import {Toolbar} from '../../toolbar/toolbar';
import {SidebarItem} from './sidebarItem';
import {ReactComponent as CustomizeIcon} from '../../../../../../assets/editor/customize.svg';
import {ReactComponent as LayerIcon} from '../../../../../../assets/editor/layers.svg';
import {useEditor} from '@craftjs/core';
import styled from 'styled-components';

export const SidebarDiv = styled.div<{ enabled: boolean }>`
  width: ${(props) => (props.enabled ? 280 : 0)}px;
  opacity: ${(props) => (props.enabled ? 1 : 0)};
  background: #fff;
`;

export const Sidebar = () => {
    const [layersVisible, setLayerVisible] = useState(true);
    const [toolbarVisible, setToolbarVisible] = useState(true);
    const {enabled} = useEditor((state) => ({
        enabled: state.options.enabled,
    }));

    return (
        <SidebarDiv enabled={enabled} className='sidebar transition bg-white'>
            <div className='flex flex-col h-full'>
                <SidebarItem
                    icon={<CustomizeIcon/>}
                    title='Customize'
                    height={!layersVisible ? 'full' : '55%'}
                    visible={toolbarVisible}
                    onChange={(val) => setToolbarVisible(val)}
                >
                    <Toolbar/>
                </SidebarItem>
                <SidebarItem
                    icon={<LayerIcon/>}
                    title='Layers'
                    height={!toolbarVisible ? 'full' : '45%'}
                    visible={layersVisible}
                    onChange={(val) => setLayerVisible(val)}
                >
                    <div className=''>
                        <Layers expandRootOnLoad={true}/>
                    </div>
                </SidebarItem>
            </div>
        </SidebarDiv>
    );
};
