import lz from 'lzutf8';
import DefaultFirstImage from '../assets/blog/blog-default-1.png';
import DefaultSecondImage from '../assets/blog/blog-default-2.png';
import DefaultThirdImage from '../assets/blog/blog-default-3.png';
import base64Img from 'base64-img';

export function formatUTCDate(rawDate) {
    const clientTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return new Date(rawDate).toLocaleString('cs-CZ', {timeZone: clientTimezone});
}

export function getBase64InJSON(base64) {
    const uint8array = lz.decodeBase64(base64);
    return lz.decompress(uint8array);
}

function getDataUrl(src, callback) {
    base64Img.requestBase64(src, (err, res, body) => {
        callback(body)
    });
}

export function getBlogPreviewImageSource(key, callback) {
    // INFO:
    // default image and first image
    const cb = (url) => {
        callback(dataURLtoFile(url, 'image.png'));
    }

    if (key === 'third' || key === 2) {
        getDataUrl(DefaultThirdImage, cb);
    } else if (key === 'second' || key === 1) {
        getDataUrl(DefaultSecondImage, cb);
    } else {
        getDataUrl(DefaultFirstImage, cb);
    }
}

export function dataURLtoFile(dataurl, filename) {
    let arr = dataurl.split(',');
    if (arr[0] && arr[1]) {
        let mime = arr[0].match(/:(.*?);/)[1];
        let bstr = atob(arr[1]);
        let n = bstr.length;
        let u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, {type: mime});
    }
    return null;
}

export function getFileFromBinaryData(binaryData) {
    const arrayBufferView = new Uint8Array(binaryData.data.data);
    const blob = new Blob([arrayBufferView], {type: binaryData.format});
    return new File([blob], 'file', {lastModified: new Date().getTime(), type: blob.type});
}

export function chunkArray(array, size) {
    const chunked_arr = [];
    for (let i = 0; i < array.length; i++) {
        const last = chunked_arr[chunked_arr.length - 1];
        !last || last.length === size
            ? chunked_arr.push([array[i]])
            : last.push(array[i]);
    }
    return chunked_arr;
}

export function isEmailValid(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}