import React from 'react';
import {Grid} from '@material-ui/core';
import {InquiryForm} from '../../../components/inquiry/form/inquiryForm';
import Dropdown from '../../../components/dropdown/Dropdown';
import ContinueButton from '../../../components/inquiry/continueButton/continueButton';
import {withTranslation} from 'react-i18next';

class CloudHostingInternal extends React.Component {
    title = `5. ${this.props.t('cloudHosting.title')}`;
    cloudHosting = [
        {
            key: 'own hardware in company',
            name: this.props.t('cloudHosting.hosting.ownHardware'),
        },
        {
            key: 'leased hardware in company',
            name: this.props.t('cloudHosting.hosting.leasedHardware'),
        },
        {
            key: 'datacenter',
            name: this.props.t('cloudHosting.hosting.datacenter'),
        },
        {
            key: 'take advice',
            name: this.props.t('cloudHosting.hosting.takeAdvice'),
        },
    ];

    constructor(props) {
        super(props);
        this.state = {
            selectedCloudHosting: props.cloudHosting || this.cloudHosting[0],
        };
    }

    onChangeCloudHosting = (selectedCloudHosting) => this.setState({selectedCloudHosting});

    render() {
        const {sliderSettings, onNextClick, onPreviousClick, t} = this.props;
        return (
            <InquiryForm
                title={this.title}
                sliderSettings={sliderSettings}
                onNextClick={onNextClick}
                onPreviousClick={onPreviousClick}
            >
                <Grid
                    container
                    item
                    className='my-4'
                    xs={12}
                >
                    <Dropdown
                        selectedItem={this.state.selectedCloudHosting}
                        items={this.cloudHosting}
                        onChange={this.onChangeCloudHosting}
                    />
                    <Grid item xs={12} lg={6} className='mt-3 mt-xl-0 ml-xl-3'>
                        <ContinueButton t={t} onClick={onNextClick}/>
                    </Grid>
                </Grid>
            </InquiryForm>
        );
    }

    componentWillUnmount() {
        this.props.saveCloudHosting(this.state.selectedCloudHosting);
    }
}

export const CloudHosting = withTranslation('inquiry')(CloudHostingInternal);