import React from 'react';
import {Link} from 'react-router-dom';
import {getFileFromBinaryData} from '../../../../utils/utils';
import BlueArrow from '../../../../assets/button-arrow-blue.png';

// INFO:
// this component is imported, if preview image from server is null
const RandomDefaultImage = React.lazy(() => import('./randomDefaultImage/randomDefaultImage'));

class ArticlePreviewUI extends React.Component {

    getPreviewImage = () => {
        const {articlePreview} = this.props;
        if (articlePreview.contentBinaryData && articlePreview.contentBinaryData.data) {
            return articlePreview.contentBinaryData.data.name
            && typeof articlePreview.contentBinaryData.data.name == 'string'
                ? articlePreview.contentBinaryData.data
                : getFileFromBinaryData(articlePreview.contentBinaryData);
        }
        return null;
    };

    getDate = () => {
        const date = this.props.articlePreview.date;
        const splitDate = date.split(' ');
        return `${splitDate[0]} ${splitDate[1]} ${splitDate[2]}`;
    };

    render() {
        const {t, articlePreview} = this.props;
        return (
            <div className='article-preview-item'>
                <div className='text-center blog-img-container'>
                    {this.renderPreviewImage()}
                </div>
                <Link to={`blog/article/${articlePreview.slug}`} className='text-58'>
                    {articlePreview.title}
                </Link>
                {this.renderDateReadShareRow()}
                <p className='text-60'>
                    {articlePreview.previewText}
                </p>
                <Link to={`blog/article/${articlePreview.slug}`}>
                    <button className='article-preview-item-button'>
                        <span className='text-61'>{t('readArticleButton')}</span>
                        <img className='mr-3' src={BlueArrow} alt=''/>
                    </button>
                </Link>
            </div>
        );
    }

    renderDateReadShareRow = () => {
        const {t, articlePreview} = this.props;
        const dateText = this.getDate();
        let readTimeText;
        if (articlePreview.readTime) {
            readTimeText = `${articlePreview.readTime} ${t('readTime')}`;
            return <p className='text-59'>{`${dateText} • ${readTimeText}`}</p>;
        }
        return <p className='text-59'>{`${dateText}`}</p>;
    };

    renderPreviewImage = () => {
        const previewImage = this.getPreviewImage();
        if (previewImage) {
            return <img src={URL.createObjectURL(previewImage)} className='blog-preview-img' alt=''/>;
        } else {
            return <RandomDefaultImage/>;
        }
    };
}

export default ArticlePreviewUI;
