import {getBlogPreviewImageSource, getFileFromBinaryData} from '../../../utils/utils';
import {web} from '../../../config/web';
import {config} from '../../../config/config';
import {defaultTemplateBase64} from './defaultTemplate';

export const autoSaveSeconds = web.api.blogAutoSave;

export function getRandomDefaultImage(callback) {
    const interval = [0, 2];
    const randomKey = Math.floor(Math.random() * (interval[1] - interval[0] + 1)) + interval[0];
    return getBlogPreviewImageSource(randomKey, callback);
}

// INFO:
// default object of article for creating new instance
export const defaultArticle = {
    article: null,
    title: null,
    language: config.allowedBlogLanguages[0],
    content: defaultTemplateBase64,
    contentMetadata: {
        category: null,
    },
    previewText: null,
};

export const getFormData = (rawData) => {
    const data = new FormData();
    rawData.article && data.append('article', rawData.article);
    if (rawData.contentBinaryData && rawData.contentBinaryData.data) {
        if (rawData.contentBinaryData.data.name && typeof rawData.contentBinaryData.data.name == 'string') {
            data.append('previewImage', rawData.contentBinaryData.data);
        } else {
            data.append('previewImage', getFileFromBinaryData(rawData.contentBinaryData));
        }
    }
    rawData.language && data.append('language', rawData.language);
    rawData.title && data.append('title', rawData.title);
    rawData.content && data.append('content', rawData.content);
    rawData.contentMetadata &&
    rawData.contentMetadata.category && data.append('category', rawData.contentMetadata.category);
    rawData.previewText && data.append('previewText', rawData.previewText);
    return data;
};
