import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import LanguageSelector from '../languageSelector/languageSelector';

class LanguageSelectDialog extends React.Component {

    state = {
        selectedLanguage: this.props.languages[0],
    };

    title = 'Add new language';

    render() {
        const {show, onClose} = this.props;
        return (
            <Dialog
                open={show}
                onClose={onClose}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
            >
                <DialogTitle id='alert-dialog-title'>
                    <p className='text-table-head'>{this.title}</p>
                </DialogTitle>
                {this.renderContent()}
                {this.renderAction()}
            </Dialog>
        );
    }

    renderContent = () => {
        const {selectedLanguage} = this.state;
        return (
            <div className='ml-4 h-25'>
                <p className='text-24 mb-1'>Select language</p>
                <LanguageSelector
                    selectedLanguage={selectedLanguage}
                    languages={this.props.languages}
                    onChange={(lng) => this.setState({selectedLanguage: lng})}
                    style={{marginTop: '6px', height: '100px'}}
                    dropdownMenuStyle={{transform: 'none', top: 'unset'}}
                />
            </div>
        );
    };

    renderAction = () => {
        return (
            <DialogActions>
                <Button onClick={this.props.onClose} color='inherit'>
                    <p className='text-24'>Cancel</p>
                </Button>
                <Button color='inherit' onClick={() => this.props.onAddClick(this.state.selectedLanguage)}>
                    <p className='text-24'>Add</p>
                </Button>
            </DialogActions>
        );
    };
}

export default LanguageSelectDialog;