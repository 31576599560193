import React from 'react';
import {Grid, Slider, RadioGroup} from '@material-ui/core';
import {useNode} from '@craftjs/core';
import {ToolbarTextInput} from './toolbarTextInput';
import {ToolbarDropdown} from "./toolbarDropdown";
import {withStyles} from '@material-ui/styles';

const iOSBoxShadow =
    '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)';

const SliderStyled = withStyles({
    root: {
        color: '#3880ff',
        height: 2,
        padding: '5px 0',
        width: '100%',
    },
    thumb: {
        height: 14,
        width: 14,
        backgroundColor: '#fff',
        boxShadow: iOSBoxShadow,
        marginTop: -7,
        marginLeft: -7,
        '&:focus,&:hover,&$active': {
            boxShadow:
                '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',
            '@media (hover: none)': {
                boxShadow: iOSBoxShadow,
            },
        },
    },
    active: {},
    valueLabel: {
        left: 'calc(-50% + 11px)',
        top: -22,
        '& *': {
            background: 'transparent',
            color: '#000',
        },
    },
    track: {
        height: 2,
    },
    rail: {
        height: 2,
        opacity: 0.5,
        backgroundColor: '#bfbfbf',
    },
    mark: {
        backgroundColor: '#bfbfbf',
        height: 8,
        width: 1,
        marginTop: -3,
    },
    markActive: {
        opacity: 1,
        backgroundColor: 'currentColor',
    },
})(Slider);

export const ToolbarItem = ({full = false, propKey, type, onChange, index, ...props}: any) => {
    const {
        actions: {setProp},
        propValue,
    } = useNode((node) => ({
        propValue: node.data.props[propKey],
    }));
    const value = Array.isArray(propValue) ? propValue[index] : propValue;
    return (
        <Grid item xs={full ? 12 : 6}>
            <div className='mb-2'>
                {['text', 'color', 'bg', 'number'].includes(type) ? (
                    <ToolbarTextInput
                        {...props}
                        type={type}
                        value={value}
                        onChange={(value) => {
                            setProp((props: any) => {
                                if (Array.isArray(propValue)) {
                                    props[propKey][index] = onChange ? onChange(value) : value;
                                } else {
                                    props[propKey] = onChange ? onChange(value) : value;
                                }
                            }, 500);
                        }}
                    />
                ) : type === 'slider' ? (
                    <React.Fragment>
                        {props.label ? (
                            <h4 className='text-sm text-light-gray-2'>{props.label}</h4>
                        ) : null}
                        <SliderStyled
                            value={parseInt(value) || 0}
                            onChange={
                                ((_, value: number) => {
                                    setProp((props: any) => {
                                        if (Array.isArray(propValue)) {
                                            props[propKey][index] = onChange
                                                ? onChange(value)
                                                : value;
                                        } else {
                                            props[propKey] = onChange ? onChange(value) : value;
                                        }
                                    }, 1000);
                                }) as any
                            }
                        />
                    </React.Fragment>
                ) : type === 'radio' ? (
                    <React.Fragment>
                        {props.label ? (
                            <h4 className='text-sm text-light-gray-2'>{props.label}</h4>
                        ) : null}
                        <RadioGroup
                            value={value || 0}
                            onChange={(e) => {
                                const value = e.target.value;
                                setProp((props: any) => {
                                    props[propKey] = onChange ? onChange(value) : value;
                                });
                            }}
                        >
                            {props.children}
                        </RadioGroup>
                    </React.Fragment>
                ) : type === 'select' ? (
                    <ToolbarDropdown
                        value={value || ''}
                        onChange={(value) =>
                            setProp(
                                (props: any) =>
                                    (props[propKey] = onChange ? onChange(value) : value)
                            )
                        }
                        {...props}
                    />
                ) : null}
            </div>
        </Grid>
    );
};
