import React from 'react';
import CloudUse from '../shared/cloudUse/cloudUse';
import {withTranslation} from 'react-i18next';

class OptimizationCloudUseInternal extends React.Component {
    title = `3. ${this.props.t('cloudUse.title')}`;

    constructor(props) {
        super(props);
        this.state = {
            usages: props.usages || [
                {
                    key: 'infrastructure',
                    name: props.t('cloudUse.usages.infrastructure'),
                    active: false,
                },
                {
                    key: 'development',
                    name: props.t('cloudUse.usages.development'),
                    active: false,
                },
                {
                    key: 'power',
                    name: props.t('cloudUse.usages.power'),
                    active: false,
                },
                {
                    key: 'storage',
                    name: props.t('cloudUse.usages.storage'),
                    active: false,
                },
                {
                    key: 'backup',
                    name: props.t('cloudUse.usages.backup'),
                    active: false,
                },
                {
                    key: 'analysis',
                    name: props.t('cloudUse.usages.analysis'),
                    active: false,
                },
                {
                    key: 'service',
                    name: props.t('cloudUse.usages.service'),
                    active: false,
                },
                {
                    key: 'other',
                    name: props.t('cloudUse.usages.other'),
                    active: false,
                    others: [],
                },
            ],
        };
    }

    render() {
        return <CloudUse {...this.props} title={this.title} usages={this.state.usages}/>;
    }
}

export const OptimizationCloudUse = withTranslation('inquiry')(OptimizationCloudUseInternal);