import React from 'react';
import Grid from '@material-ui/core/Grid';

class Checkbox extends React.Component {

    onChange = (event) => this.props.onChange && this.props.onChange(event.target);

    render() {
        return (
            <Grid
                container
                className={this.props.className}
            >
                <label className='checkbox-1 mr-2'>
                    <input
                        type='checkbox'
                        name={this.props.name}
                        onChange={this.onChange}
                        checked={this.props.checked}
                    />
                    <span/>
                </label>
                {this.props.label}
            </Grid>
        );
    }
}

export default Checkbox;