import React from 'react';
import {InquiryForm} from '../../../components/inquiry/form/inquiryForm';
import {Grid} from '@material-ui/core';
import Dropdown from '../../../components/dropdown/Dropdown';
import ContinueButton from '../../../components/inquiry/continueButton/continueButton';
import {withTranslation} from 'react-i18next';

class CloudDataMigrationInternal extends React.Component {
    title = this.props.t('cloudDataMigration.title');
    dataMigrationHelps = [
        {
            key: 'yes',
            name: this.props.t('cloudDataMigration.dataMigrationHelps.yes'),
        },
        {
            key: 'no',
            name: this.props.t('cloudDataMigration.dataMigrationHelps.no'),
        },
    ];

    constructor(props) {
        super(props);
        this.state = {
            selectedDataMigrationHelp: props.dataMigrationHelp || this.dataMigrationHelps[0],
        };
    }

    onChangeDataMigrationHelp = (selectedDataMigrationHelp) => {
        this.setState({selectedDataMigrationHelp});
    };

    render() {
        const {sliderSettings, onNextClick, onPreviousClick, t} = this.props;
        return (
            <InquiryForm
                title={this.title}
                sliderSettings={sliderSettings}
                onNextClick={onNextClick}
                onPreviousClick={onPreviousClick}
            >
                <Grid
                    container
                    item
                    className='my-4'
                    xs={12}
                >
                    <Dropdown
                        selectedItem={this.state.selectedDataMigrationHelp}
                        items={this.dataMigrationHelps}
                        onChange={this.onChangeDataMigrationHelp}
                    />
                    <Grid item xs={12} lg={6} className='mt-3 mt-xl-0 ml-xl-3'>
                        <ContinueButton t={t} onClick={onNextClick}/>
                    </Grid>
                </Grid>
            </InquiryForm>
        );
    }

    componentWillUnmount() {
        this.props.saveDataMigrationHelp(this.state.selectedDataMigrationHelp);
    }
}

export const CloudDataMigration = withTranslation('inquiry')(CloudDataMigrationInternal);