import React from 'react';

// INFO:
//when choosing a service, it shows information about it
//
// Properties:
// name - name of service
// text- info about service
class ServiceDescription extends React.Component {
    render() {
        const {selectedService} = this.props;
        return (
            <div className='col'>
                <p className='text-42-bold'>{selectedService.name}</p>
                <p className='text-42'>{selectedService.text}</p>
            </div>
        );
    }
}

export default ServiceDescription;