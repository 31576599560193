import React from 'react';
import Grid from '@material-ui/core/Grid';
import SaidAboutUs from '../../../components/saidAboutUs/saidAboutUs';
import Button from '../../../components/button/button';
import ReferenceMultipleCarousel from '../../../components/referenceSlider/referenceMultipleCarousel';
import CeskaSporitelnaImage from '../../../assets/Logo ČS.png';
import AvastImage from '../../../assets/Logo Avast.png';
import CeskaPojistovnaImage from '../../../assets/PCS.png';
import MiniBeamsImage from '../../../assets/Logo minibems.png';
import LogoBankOfEnglandImage from '../../../assets/Logo bank-of-england.png';
import MonetImage from '../../../assets/Logo M+.png';
import LogoDuofertilityImage from '../../../assets/Logo duoferitility.png';
import LogoImontImage from '../../../assets/Logo imont.png';
import LogoJumpshop from '../../../assets/Logo jumpshot.png';
import LogoMHdent from '../../../assets/Logo mhdent.png';
import {withTranslation} from 'react-i18next';
import {getFileFromBinaryData} from '../../../utils/utils';
import {Link} from 'react-router-dom';

const referencesImages = [CeskaSporitelnaImage, AvastImage,
    CeskaPojistovnaImage, MiniBeamsImage, MonetImage, LogoBankOfEnglandImage, LogoDuofertilityImage,
    LogoImontImage, LogoJumpshop, LogoMHdent,
];

class FourthBlockInternal extends React.Component {

    getCompanyLogo = () => {
        const {reference} = this.props;
        const isCompanyLogoExist = reference && reference.referenceCompanyLogo;
        let targetCompanyLogo;
        if (isCompanyLogoExist && reference.referenceCompanyLogo.data) {
            const logo = reference.referenceCompanyLogo;
            if (logo.data.name && typeof logo.data.name === 'string') {
                targetCompanyLogo = logo.data;
            } else {
                const logoFromBinary = getFileFromBinaryData(logo);
                targetCompanyLogo = logoFromBinary ? logoFromBinary : null;
            }
        }
        return targetCompanyLogo;
    };

    render() {
        const {showReference} = this.props;
        return (
            <Grid id='fourth-block'container item className='background-2 pt-5 p-4 fourth-block' xs={12}>
                {this.renderTitle()}
                {showReference && this.renderBlock()}
                {showReference && this.renderText()}
                {showReference && this.renderReferenceButton()}
                {this.renderReferenceCarousel()}
            </Grid>
        );
    }

    renderTitle = () => {
        return (
            <Grid container item xs={12} className='pb-5 mt-5'>
                <Grid item xs={12} className='pb-5'>
                    <p className='title-2 m-auto'>{this.props.t('fourthBlock.title')}</p>
                </Grid>
            </Grid>
        );
    };

    renderBlock = () => {
        const {t, reference} = this.props;
        const logo = this.getCompanyLogo();
        const caseStudy = reference.referenceCaseStudy;
        return (
            <SaidAboutUs
                t={t}
                logo={logo}
                companyName={reference.companyName}
                referenceText={reference.referenceText}
                clientName={reference.clientName}
                clientRole={reference.clientRole}
                showCaseStudyButton={reference && caseStudy}
                caseStudyId={caseStudy ? caseStudy.id : null}
            />
        );
    };

    renderText = () => (
        <Grid container item xs={12} justify='center'>
            <p className='text-13'>{this.props.t('fourthBlock.firstText')}</p>
        </Grid>
    );

    renderReferenceButton = () => {
        return (
            <Grid container item xs={12} justify='center' className='mt-4'>
                <Link to='/references'>
                    <Button type='blue' text={this.props.t('fourthBlock.referenceText')} bigger/>
                </Link>
            </Grid>
        );
    };

    renderReferenceCarousel = () => {
        return (
            <ReferenceMultipleCarousel items={referencesImages}/>
        );
    };
}

export const FourthBlock = withTranslation('ourService')(FourthBlockInternal);
