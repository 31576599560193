import React from 'react';
import SliderLeft from '../../../assets/ikona-slider-left.png';

class LeftArrow extends React.Component {
    render() {
        const {onClick} = this.props;
        return (
            <div className='reference-arrow reference-arrow-left'>
                <img src={SliderLeft} alt='left' onClick={onClick}/>
            </div>
        );
    }
}

export default LeftArrow;