import React from 'react';
import Dropdown from '../../../../dropdown/Dropdown';
import {getBlogPreviewImageSource} from '../../../../../utils/utils';

class PreviewImageSelect extends React.Component {

    inputAcceptFormat = '.png, .jpeg, .jpg, .gif, .svg, .mp4';
    items = [
        {src: null, key: 'first', name: 'default_image_1'},
        {src: null, key: 'second', name: 'default_image_2'},
        {src: null, key: 'third', name: 'default_image_3'},
        {src: null, key: 'custom', name: 'custom'},
    ];

    state = {
        selectedItem: this.items[3],
    };

    getImage = () => {
        const {image} = this.props;
        if (image) {
            return URL.createObjectURL(image);
        }
        return null;
    };

    onChangeElement = (image) => {
        this.props.onChangeElement('previewImage', image);
    };

    onChangeDropdown = (selectedItem) => {
        this.setState({selectedItem});
        if (selectedItem.key !== 'custom') {
            getBlogPreviewImageSource(selectedItem.key, (image) => {
                this.onChangeElement(image);
            });
        } else {
            this.onChangeElement(null);
        }
    };

    render() {
        const {selectedItem} = this.state;
        return (
            <div>
                <Dropdown
                    selectedItem={selectedItem}
                    items={this.items}
                    onChange={this.onChangeDropdown}
                    className='blog-image-container mb-1'
                />
                {this.renderUploadInput()}
                {this.renderImage()}
            </div>
        );
    }

    renderUploadInput = () => {
        const {selectedItem} = this.state;
        if (selectedItem.key === 'custom') {
            return (
                <input
                    className='input-2 blog-image-container mb-2 pt-2'
                    type='file'
                    onChange={(event) => this.onChangeElement(event.target.files[0])}
                    accept={this.inputAcceptFormat}
                />
            );
        }
        return null;
    };

    renderImage = () => {
        const targetImage = this.getImage();
        if (targetImage) {
            return <img src={targetImage} className='w-25 my-5' alt=''/>;
        }
        return null;
    };
}

export default PreviewImageSelect;