import React from 'react';
import Grid from '@material-ui/core/Grid';
import FooterSocialMedia from '../footerSocialMedia/footerSocialMedia';

class SecondBlock extends React.Component {
    render() {
        return (
            <Grid container item xs={12} lg={5}>
                {this.renderWhoWeAreText()}
                <Grid container item xs={12} justify='center'>
                    {this.renderAddress()}
                    {this.renderContact()}
                </Grid>
            </Grid>
        );
    }

    renderWhoWeAreText = () => {
        const {isDividerVisible, whoWeAreText} = this.props;
        return (
            <Grid
                container
                item
                justify={!isDividerVisible ? 'center' : 'flex-start'}
                className='mb-3'
            >
                <p className='text-22'>{whoWeAreText}</p>
            </Grid>
        );
    };

    renderAddress = () => {
        const {isMobileWidth, addressText, icText, dicText} = this.props;
        return (
            <Grid
                container
                item
                direction='column'
                className='footer-address'
                alignItems='flex-start'
                xs={12}
                sm={6}
            >
                <p className='text-23 pb-2'>{addressText.key}:</p>
                <p className='text-24'>{addressText.companyName}</p>
                <p className='text-24'>{addressText.street}</p>
                <p className='text-24'>{addressText.city}</p>
                <p className='text-24 pb-4'>{addressText.country}</p>
                <p className='text-24'>{icText.key}: {icText.value}</p>
                <p className='text-24'>{dicText.key}: {dicText.value}</p>
                {!isMobileWidth && this.renderSocialMedia('footer-social-media-lg')}
            </Grid>
        );
    };

    renderContact = () => {
        const {isMobileWidth, contactsText} = this.props;
        return (
            <Grid
                container
                item
                className='footer-contact'
                direction='column'
                xs={12}
                sm={6}
            >
                <p className='text-23 pb-2'>{contactsText.key}:</p>
                <a
                    href={`https://${contactsText.web}`}
                    className='text-27 text-underline'
                    target='_blank'
                    rel='noreferrer'
                >
                    {contactsText.web}
                </a>
                <a href={`mailto:${contactsText.email}`} className='text-27 text-underline pb-3'>
                    {contactsText.email}
                </a>
                <p className='text-25'>{contactsText.phone}</p>
                {isMobileWidth && this.renderSocialMedia('social-media-xs')}
                {this.renderCurrentYear()}
            </Grid>
        );
    };

    renderSocialMedia = (className) => {
        const {links} = this.props;
        return (
            <FooterSocialMedia className={className} links={links}/>
        );
    };

    renderCurrentYear = () => {
        const {companyNameText} = this.props;
        const currentYear = new Date().getFullYear().toString();
        return (
            <p className='text-21'>© {currentYear} {companyNameText}</p>
        );
    };
}

export default SecondBlock;
