import React, {Fragment} from 'react';
import ArticleUI from '../articleUI/articleUI';
import LanguageSelector from '../../../../languageSelector/languageSelector';
import AddIcon from '@material-ui/icons/Add';
import {config} from '../../../../../config/config';

class ArticleBlock extends React.Component {
    state = {
        selectedArticleLanguageIndex: 0,
    };

    getArticleLanguages = () => {
        return this.props.articles.map((item) => item.language);
    }

    // INFO:
    // We get all languages from config, which are not use
    getAllowedLanguages = () => {
        const allLanguages = this.getArticleLanguages();
        if (allLanguages && allLanguages.length) {
            return config.allowedBlogLanguages.filter((lng) => !allLanguages.includes(lng));
        }
        return config.allowedBlogLanguages;
    };

    onChangeLanguageSelector = (selectedLanguage) => {
        const articleLanguages = this.getArticleLanguages();
        this.setState({
            selectedArticleLanguageIndex: articleLanguages.findIndex((item) => item === selectedLanguage),
        });
    };

    render() {
        const {articles} = this.props;
        return (
            <Fragment>
                {articles.map(this.renderArticleUI).filter((a) => !!a)}
            </Fragment>
        );
    }

    renderArticleUI = (item, index) => {
        if (this.state.selectedArticleLanguageIndex === index) {
            return (
                <ArticleUI
                    key={index.toString()}
                    article={item}
                    languagesSelector={this.renderLanguagesSelector}
                    {...this.props}
                />
            );
        }
        return null;
    };

    renderLanguagesSelector = (article) => {
        const {selectedArticleLanguageIndex} = this.state;
        const articleLanguages = this.getArticleLanguages();
        const allowedLanguages = this.getAllowedLanguages();
        const showAddIcon = allowedLanguages.length > 0;
        return (
            <div>
                <LanguageSelector
                    selectedLanguage={articleLanguages[selectedArticleLanguageIndex]}
                    languages={articleLanguages.filter((item, index) => index !== selectedArticleLanguageIndex)}
                    onChange={this.onChangeLanguageSelector}
                />
                {showAddIcon && <AddIcon
                    className='blue-button-2 cursor-pointer ml-2 mb-2'
                    onClick={() => this.props.onAddNewLanguage(allowedLanguages, article)}
                />}
            </div>
        );
    };
}

export default ArticleBlock;
