import React from 'react';
import {useNode, useEditor} from '@craftjs/core';
import ContentEditable from 'react-contenteditable';
import {TextSettings} from './textSettings';

export const TextBold
    = ({
           fontSize,
           textAlign,
           fontWeight,
           color,
           shadow,
           text,
           margin,
       }: any) => {
    const {
        connectors: {connect},
        setProp,
    } = useNode();
    const {enabled} = useEditor((state) => ({
        enabled: state.options.enabled,
    }));
    return (
        <ContentEditable
            innerRef={connect}
            html={text}
            disabled={!enabled}
            onChange={(e) => {
                setProp((prop) => (prop.text = e.target.value), 500);
            }}
            tagName='p'
            className='editor-text'
            style={{
                width: 'auto',
                margin: `${margin[0]}px ${margin[1]}px ${margin[2]}px ${margin[3]}px`,
                color: `rgba(${Object.values(color)})`,
                fontSize: `${fontSize}px`,
                textShadow: `0px 0px 2px rgba(0,0,0,${(shadow || 0) / 100})`,
                fontFamily: 'WorkSans-SemiBold',
                fontWeight,
                textAlign,
            }}
        />
    );
};

TextBold.craft = {
    displayName: 'Text',
    props: {
        fontSize: 22,
        textAlign: 'left',
        fontWeight: 'normal',
        color: {r: 40, g: 50, b: 105, a: 1},
        margin: [0, 0, 0, 0],
        shadow: 0,
        text: 'Vítr skoro nefouká a tak by se na první pohled mohlo zdát, ' +
            'že se balónky snad vůbec nepohybují. Jenom tak klidně levitují ve vzduchu. ' +
            'Jelikož slunce jasně září a na obloze byste od východu k západu.',
    },
    related: {
        toolbar: TextSettings,
    },
};
