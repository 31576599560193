import React from 'react';
import {Grid} from '@material-ui/core';
import {InquiryForm} from '../../../components/inquiry/form/inquiryForm';
import ContinueButton from '../../../components/inquiry/continueButton/continueButton';
import InquiryInput from '../../../components/inquiry/input/inquiryInput';
import ErasingFrame from '../../../components/inquiry/erasingFrame/erasingFrame';
import {withTranslation} from 'react-i18next';

class CloudParameterInternal extends React.Component {
    title = `3. ${this.props.t('cloudParameter.title')}`;

    constructor(props) {
        super(props);
        this.state = {
            parameters: props.parameters || [],
            parameterInput: props.parameterInput || '',
        };
    }

    onChangeParameterInput = (target) => {
        this.setState({parameterInput: target.value});
    };

    onAddParameter = () => {
        const {parameters, parameterInput} = this.state;
        if (parameterInput) {
            let newParameters = parameters;
            const key = parameters.length !== 0
                ? parameters[parameters.length - 1].key + 1
                : 0;
            newParameters.push({key, value: parameterInput});
            this.setState({parameters: newParameters, parameterInput: ''});
        }
    };

    onRemoveParameter = (key) => {
        const newParameters = this.state.parameters.filter((parameter) => parameter.key !== key);
        this.setState({parameters: newParameters});
    };

    render() {
        const {sliderSettings, onNextClick, onPreviousClick, t} = this.props;
        return (
            <InquiryForm
                title={this.title}
                sliderSettings={sliderSettings}
                onNextClick={onNextClick}
                onPreviousClick={onPreviousClick}
            >
                <Grid container item className='my-3'>
                    <Grid item xs={12} md={5} lg={4}>
                        {this.renderAddParameterInput()}
                    </Grid>
                    <Grid item className='mt-3 mt-lg-0 ml-lg-3' xs={12} md={5} lg={4}>
                        <ContinueButton t={t} onClick={onNextClick}/>
                    </Grid>
                </Grid>
            </InquiryForm>
        );
    }

    renderAddParameterInput = () => {
        const {t} = this.props;
        return (
            <Grid container direction='row'>
                {this.renderParameters()}
                <Grid item xs={12} className='mt-2 mt-md-0'>
                    <InquiryInput
                        placeholder={t('inputPlaceholder')}
                        onChange={this.onChangeParameterInput}
                        onIconClick={this.onAddParameter}
                        value={this.state.parameterInput}
                        showIcon
                    />
                </Grid>
            </Grid>
        );
    };

    renderParameters = () => {
        const {parameters} = this.state;
        if (parameters.length > 0) {
            return parameters.map((parameter, index) => {
                return (
                    <ErasingFrame
                        key={index}
                        text={parameter.value}
                        onClick={() => this.onRemoveParameter(parameter.key)}
                        className='ml-0'
                    />
                );
            });
        }
    };

    componentWillUnmount() {
        const {parameters, parameterInput} = this.state;
        this.props.saveParameters(parameters);
        this.props.saveParameterInput(parameterInput);
    }
}

export const CloudParameter = withTranslation('inquiry')(CloudParameterInternal);