import React from 'react';
import Grid from '@material-ui/core/Grid';
import Picture2 from '../../../assets/Illustrace 2.png';
import TextBlock from '../../../components/textBlock/textBlock';
import {withTranslation} from 'react-i18next';

class SecondBlockInternal extends React.Component {
    state = {
        screenWidth: window.innerWidth,
    };

    render() {
        return (
            <Grid container item className='background-2 pt-5 my-5 p-4' xs={12}>
                {this.renderGrids()}
            </Grid>
        );
    }

    renderGrids = () => {
        const {screenWidth} = this.state;
        const grids = [
            (
                <Grid key='0' item sm={12} md={6} className='second-picture-bg'>
                    <img src={Picture2} className='illustration m-auto' alt='second_picture'/>
                </Grid>
            ),
            (
                <Grid key='1' container item sm={12} md={6} className='m-auto'>
                    {this.renderFirstText()}
                    {this.renderFourGrid()}
                </Grid>
            ),
        ];
        return screenWidth > 935
            ? grids
            : grids.reverse();
    };

    renderFirstText = () => {
        const {t} = this.props;
        return (
            <Grid container item xs={12} className='mb-4 ml-5'>
                <p className='text-4'>{t('secondBlock.title')}</p>
            </Grid>
        );
    };

    renderFourGrid = () => {
        const {t} = this.props;
        const startWay = 'secondBlock.texts.';
        return (
            <Grid
                container
                item
                xs={12}
                className='mx-5 ml-5'
            >
                {}
                <TextBlock
                    title={t(startWay + 'first.title')}
                    text={t(startWay + 'first.text')}
                />
                <TextBlock
                    title={t(startWay + 'second.title')}
                    text={t(startWay + 'second.text')}
                />
                <TextBlock
                    title={t(startWay + 'third.title')}
                    text={t(startWay + 'third.text')}
                />
                <TextBlock
                    title={t(startWay + 'fourth.title')}
                    text={t(startWay + 'fourth.text')}
                />
            </Grid>
        );
    };

    componentDidMount() {
        window.addEventListener('resize',
            () => this.setState({screenWidth: window.innerWidth}));
    }
}

export const SecondBlock = withTranslation('ourService')(SecondBlockInternal);
