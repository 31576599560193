import React from 'react';
import Grid from '@material-ui/core/Grid';
import MoneyIcon from '../../../assets/ikona-levne.png';
import SafeIcon from '../../../assets/ikona-bezpecne.png';
import InHouseIcon from '../../../assets/ikona-inhouse.png';
import Picture1 from '../../../assets/Ilustrace 1.png';
import Button from '../../../components/button/button';
import TextIcon from '../../../components/textIcon/textIcon';
import VerticalSlider from '../../../components/verticalSlider/verticalSlider';
import HorizontalSlider from '../../../components/horizontalSlider/horizontalSlider';
import {withTranslation} from 'react-i18next';

class FirstBlockInternal extends React.Component {
    state = {
        screenWidth: window.innerWidth,
        isMouseOutOfIconsGrid: true,
        iconTextIndex: 0,
    };

    verticalSliderRange = [
        {min: 0, max: 734},
        {min: 959, max: 1371},
    ];
    timeout = 4000;
    timer = null;

    showVerticalSlider = () => {
        const {screenWidth} = this.state;
        const firstRange = this.verticalSliderRange[0];
        const secondRange = this.verticalSliderRange[1];
        return (firstRange.min < screenWidth && firstRange.max > screenWidth)
            || (secondRange.min < screenWidth && secondRange.max > screenWidth);
    };

    getTargetText = () => {
        const {t} = this.props;
        switch (this.state.iconTextIndex) {
            case 0:
                return t('firstBlock.icons.cheap.description');
            case 1:
                return t('firstBlock.icons.safely.description');
            case 2:
                return t('firstBlock.icons.inHouse.description');
            default:
                return '';
        }
    };

    onTick = () => {
        if (this.state.isMouseOutOfIconsGrid) {
            let iconTextIndex = this.state.iconTextIndex;
            this.setState({
                iconTextIndex: iconTextIndex !== 2 ? ++iconTextIndex : 0,
            });
        }
    };

    render() {
        const showVerticalSlider = this.showVerticalSlider();
        return (
            <Grid container item className='background-1 first-block' xs={12}>
                <Grid container item className='m-auto p-4 preview-first-block' justify='center' sm={12} md={6}>
                    {this.renderFirstText()}
                    {this.renderSecondText()}
                    {showVerticalSlider &&
                    <Grid container item>
                        {this.renderVerticalSlider()}
                        {this.renderIconsGrid()}
                    </Grid>}
                    {!showVerticalSlider && this.renderIconsGrid()}
                    {!showVerticalSlider && this.renderHorizontalSlider()}
                    {this.renderThirdText()}
                    {this.renderButtons()}
                </Grid>
                <Grid item sm={12} md={6} className='first-picture-bg'>
                    <img src={Picture1} className='illustration m-auto' alt='first_picture'/>
                </Grid>
            </Grid>
        );
    }

    renderFirstText = () => {
        const {t} = this.props;
        return (
            <Grid container item xs={12} className='first-block-item'>
                <p className='text-1'>{t('firstBlock.firstText')}</p>
            </Grid>
        );
    };

    renderSecondText = () => {
        const {t} = this.props;
        return (
            <Grid container item xs={12} className='first-block-item'>
                <p className='text-2'>{t('firstBlock.secondText')}</p>
            </Grid>
        );
    };

    renderIconsGrid = () => {
        const {t} = this.props;
        const showFullWidth = !this.showVerticalSlider();
        return (
            <Grid item container className='first-block-item' xs={showFullWidth ? 12 : 11}>
                <TextIcon
                    onHover={() => this.setState({iconTextIndex: 0})}
                    onMouseMove={(isMouseIn) => this.setState({isMouseOutOfIconsGrid: !isMouseIn})}
                    icon={MoneyIcon}
                    label={t('firstBlock.icons.cheap.label')}
                    text={t('firstBlock.icons.cheap.text')}
                    grid={{
                        all: {xs: 12, sm: 4, md: 12, lg: 4},
                        icon: {xs: 3, sm: 3, md: 3, lg: 3},
                        text: {xs: 7, sm: 5, md: 6, lg: 3},
                    }}
                />
                <TextIcon
                    onHover={() => this.setState({iconTextIndex: 1})}
                    onMouseMove={(isMouseIn) => this.setState({isMouseOutOfIconsGrid: !isMouseIn})}
                    icon={SafeIcon}
                    label={t('firstBlock.icons.safely.label')}
                    text={t('firstBlock.icons.safely.text')}
                    className='text-icon-middle'
                    grid={{
                        all: {xs: 12, sm: 4, md: 12, lg: 4},
                        icon: {xs: 3, sm: 3, md: 3, lg: 3},
                        text: {xs: 7, sm: 5, md: 6, lg: 3},
                    }}
                />
                <TextIcon
                    onHover={() => this.setState({iconTextIndex: 2})}
                    onMouseMove={(isMouseIn) => this.setState({isMouseOutOfIconsGrid: !isMouseIn})}
                    icon={InHouseIcon}
                    label={t('firstBlock.icons.inHouse.label')}
                    text={t('firstBlock.icons.inHouse.text')}
                    grid={{
                        all: {xs: 12, sm: 4, md: 12, lg: 4},
                        icon: {xs: 3, sm: 3, md: 3, lg: 3},
                        text: {xs: 7, sm: 5, md: 6, lg: 3},
                    }}
                />
            </Grid>
        );
    };

    renderHorizontalSlider = () => {
        const {iconTextIndex, screenWidth} = this.state;
        return (
            <Grid container item xs={12} className='pb-4'>
                <HorizontalSlider
                    onHover={(iconTextIndex) => this.setState({iconTextIndex})}
                    onMouseMove={(isMouseIn) => this.setState({isMouseOutOfIconsGrid: !isMouseIn})}
                    index={iconTextIndex}
                    screenWidth={screenWidth}
                />
            </Grid>
        );
    };

    renderVerticalSlider = () => {
        const {iconTextIndex} = this.state;
        return (
            <Grid container item direction='column' xs={1}>
                <VerticalSlider
                    onHover={(iconTextIndex) => this.setState({iconTextIndex})}
                    onMouseMove={(isMouseIn) => this.setState({isMouseOutOfIconsGrid: !isMouseIn})}
                    index={iconTextIndex}
                />
            </Grid>
        );
    };

    renderThirdText = () => {
        const text = this.getTargetText();
        return (
            <Grid container item xs={12} className='mb-5 mb-sm-0 mb-lg-2'>
                <p className='text-3'>{text}</p>
            </Grid>
        );
    };

    renderButtons = () => {
        const {t, showReference} = this.props;
        return (
            <Grid container item xs={12} className='pb-3 first-block-buttons'>
                <Button
                    type='blue'
                    className='mr-1 mr-sm-4'
                    text={t('firstBlock.detailButton')}
                    onClick={() => this.props.onScrollToBlock('second-block')}
                />
                {showReference &&
                <Grid item>
                    <Button
                        type='gold'
                        text='Reference'
                        onClick={() => this.props.onScrollToBlock('fourth-block')}
                    />
                </Grid>}
            </Grid>
        );
    };

    componentDidMount() {
        this.timer = setInterval(this.onTick, this.timeout);
        window.addEventListener('resize',
            () => this.setState({screenWidth: window.innerWidth}));
    }

    componentWillUnmount() {
        window.removeEventListener('resize', () => this.setState({screenWidth: window.innerWidth}));
    }
}

export const FirstBlock = withTranslation('ourService')(FirstBlockInternal);
