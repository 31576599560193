import React from 'react';
import {Grid} from '@material-ui/core';
import CaseStudyUI from '../../../../components/caseStudy/present/caseStudyUI/caseStudyUI';

class CaseStudyManager extends React.Component {

    render() {
        return (
            <Grid
                item
                container
                justify='center'
            >
                {this.renderCaseStudies()}
            </Grid>
        );
    }

    renderCaseStudies = () => {
        const {t, caseStudies} = this.props;
        if (caseStudies) {
            return caseStudies.map((caseStudy, index) => {
                const key = caseStudy.id.toString();
                return (
                    <div className='w-100' key={key} id={key}>
                        <CaseStudyUI
                            t={t}
                            isFirstItem={index === 0}
                            swipe={(index + 1) % 2 === 0}
                            caseStudy={caseStudy}
                        />
                    </div>
                );
            });
        }
        return null;
    };
}

export default CaseStudyManager;