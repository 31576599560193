import React, {Suspense} from 'react';
import {Redirect} from 'react-router-dom';
import CustomEditor from '../../../components/editor/editor';
import {
    createArticle,
    fetchAllArticlesPreviewsByPublicId,
    updateArticle,
} from '../../../api/blog/blogApi';
import LanguageSelector from '../../../components/languageSelector/languageSelector';
import AddIcon from '@material-ui/icons/Add';
import InfoText from '../../../components/blog/editor/infoText/InfoText';
import {getBase64InJSON} from '../../../utils/utils';
import BlogSaveDialog from '../../../components/blog/editor/saveDialog/blogSaveDialog';
import LanguageSelectDialog from '../../../components/languageSelectDialog/languageSelectDialog';
import {config} from '../../../config/config';
import LoadingScreen from '../../../components/emptyScreen/loadingScreen';
import {defaultArticle, getFormData} from '../../../components/blog/shared/blogShared';

class ArticleEditorProvider extends React.Component {

    state = {
        article: null,
        allArticles: [],
        fetched: true,
        redirect: false,
        apiError: {
            error: false,
        },
        showSaveDialog: false,
        showLanguageSelectDialog: false,
        isCancelButtonPressed: false,
        autoSaveStatus: {
            text: null,
            show: false,
        },
    };

    autoSaveTimeout = 3000;
    deserialize = null;
    actualEditorContent = null;
    saveDialogCb = null;

    // INFO:
    // We get all languages from config, which are not use
    getAllowedLanguages = () => {
        const {allArticles} = this.state;
        if (allArticles && allArticles.length > 0) {
            const allArticleLanguages = allArticles.map((item) => item.language);
            return config.allowedBlogLanguages.filter((lng) => !allArticleLanguages.includes(lng));
        }
        return config.allowedBlogLanguages;
    };

    // INFO:
    // Header - cancel button
    onCancel = (content) => {
        this.setState({isCancelButtonPressed: true}, () => {
            this.onSaveToServer(content);
        });
    };

    // INFO:
    // Header - edit button
    // We save base64 from articleEditor to target article content
    onEditArticleContent = (content, showSaveDialog = true, cb) => {
        if (content) {
            this.actualEditorContent = content;
            const {article, allArticles} = this.state;
            const newArticle = {...article, content};
            const newAllArticles = allArticles.map((item) => {
                if (item.language === newArticle.language) {
                    return newArticle;
                }
                return item;
            });
            if (showSaveDialog && cb) {
                this.saveDialogCb = cb;
            }
            this.setState({
                article: newArticle,
                allArticles: newAllArticles,
                showSaveDialog,
            }, () => !showSaveDialog && cb && cb());
        }
    };

    // INFO:
    // Header - save button
    onSaveToServer = (content) => {
        this.onEditArticleContent(content, this.state.showSaveDialog,
            async (newAllArticles) => {
                const {allArticles, isCancelButtonPressed} = this.state;
                let targetAllArticles;
                if (newAllArticles && newAllArticles.length) {
                    targetAllArticles = newAllArticles;
                } else {
                    targetAllArticles = allArticles;
                }
                if (targetAllArticles && targetAllArticles.length) {
                    targetAllArticles.forEach(async (article) => article.id && await this.onUpdateArticle(article));
                }
                isCancelButtonPressed && this.setState({redirect: true});
            });
    };

    // INFO:
    // fetch update article api endpoint
    onUpdateArticle = async (rawData) => {
        const data = getFormData(rawData);
        await updateArticle(rawData.id, data)
            .then(async (response) => {
                let apiError = {};
                let article = this.state.article;
                if (response.success) {
                    if (this.state.article.id === response.data.id) {
                        article = response.data;
                    }
                }
                if (!response.success || response.error) {
                    apiError = {
                        error: true,
                        message: response.message.includes('ERROR')
                            ? response.message
                            : `ERROR: ${response.message}`,
                    };
                }
                this.setState({
                    article,
                    apiError,
                    autoSaveStatus: {text: response.message, show: true},
                }, () => {
                    if (!apiError.error) {
                        setTimeout(
                            () => this.setState({autoSaveStatus: {show: false}}), this.autoSaveTimeout);
                    }
                });
            });
    };

    // INFO:
    // Save Dialog - save button
    onSaveArticleContent = async (dialogData) => {
        const {article, allArticles, isCancelButtonPressed} = this.state;
        const newArticle = {
            id: article.id || null,
            article: article.article || null,
            language: article.language,
            title: dialogData.title,
            content: article.content.toString(),
            previewText: dialogData.previewText,
            contentMetadata: {...article.contentMetadata, category: dialogData.category},
            contentBinaryData: {...article.contentBinaryData, data: dialogData.previewImage},
        };
        const newAllArticles = allArticles.map((article) => {
            if (article.language === newArticle.language) {
                return newArticle;
            }
            return article;
        });
        this.setState({article: newArticle, allArticles: newAllArticles});
        if (this.saveDialogCb) {
            await this.saveDialogCb(newAllArticles);
            this.saveDialogCb = null;
        }
        let targetAllArticles;
        if (newAllArticles && newAllArticles.length) {
            targetAllArticles = newAllArticles;
        } else {
            targetAllArticles = allArticles;
        }
        if (targetAllArticles && targetAllArticles.length) {
            targetAllArticles.forEach(async (article) => article.id && await this.onUpdateArticle(article));
        }
        isCancelButtonPressed && this.setState({redirect: true});
    };

    // INFO:
    // Header - Add language icon
    // Language selector - create new article language
    onAddNewArticle = async (language) => {
        const {allArticles} = this.state;
        if (allArticles.length > 0) {
            let newArticle = {
                ...defaultArticle,
                language,
                article: allArticles[0].article,
                content: allArticles[0].content,
            };
            const createdArticle = await this.onCreateArticle(newArticle);
            if (createdArticle && createdArticle.id) {
                const allArticles = [...this.state.allArticles, createdArticle];
                this.setState({allArticles, article: createdArticle, showLanguageSelectDialog: false});
            }
        }
    };

    // INFO:
    // fetch create article api endpoint
    onCreateArticle = async (rawData) => {
        const data = getFormData(rawData);
        let article = {};
        await createArticle(data)
            .then(async (response) => {
                if (response) {
                    if (response.success) {
                        article = response.data;
                    }
                    if (!response.success || response.error) {
                        const apiError = {
                            error: true,
                            message: response.message.includes('ERROR')
                                ? response.message
                                : `ERROR: ${response.message}`,
                        };
                        this.setState({apiError});
                    }
                }
            });
        return article;
    };

    // INFO:
    // change language in selector
    onChangeLanguage = (lng) => {
        this.onEditArticleContent(this.actualEditorContent, false,
            () => {
                const newArticle = this.state.allArticles.find((item) => item.language === lng);
                this.setState({article: newArticle});
                this.deserialize(getBase64InJSON(newArticle.content));
            });
    };

    onRedirect = (isLastItem) => {
        const {isCancelButtonPressed} = this.state;
        if (isCancelButtonPressed && isLastItem) {
            this.setState({redirect: true});
        }
    };

    render() {
        const {
            fetched, article, allArticles, redirect,
            apiError, showSaveDialog, showLanguageSelectDialog,
        } = this.state;
        if (redirect) {
            return <Redirect to={{pathname: '/blog/admin', state: {apiError}}}/>;
        }
        if (fetched && article && allArticles.length > 0) {
            return (
                <Suspense fallback={<LoadingScreen/>}>
                    <React.Fragment>
                        {showSaveDialog
                        && <BlogSaveDialog
                            show={showSaveDialog}
                            showFirstSaveText={!!this.saveDialogCb}
                            article={article}
                            onClose={() => this.setState({showSaveDialog: false})}
                            onSave={this.onSaveArticleContent}
                        />}
                        {showLanguageSelectDialog
                        && <LanguageSelectDialog
                            show={showLanguageSelectDialog}
                            languages={this.getAllowedLanguages()}
                            onClose={() => this.setState({showLanguageSelectDialog: false})}
                            onAddClick={this.onAddNewArticle}
                        />}
                        <CustomEditor
                            onCancel={this.onCancel}
                            onEdit={this.onEditArticleContent}
                            onSave={this.onSaveToServer}
                            content={getBase64InJSON(article.content)}
                            infoText={article && <InfoText article={article}/>}
                            languageSelector={this.renderLanguageSelector}
                            autoSaveStatus={this.autoSaveStatus}
                            isDialogOpen={showSaveDialog || showLanguageSelectDialog}
                            isUsedForBlog
                        />
                    </React.Fragment>
                </Suspense>
            );
        }
        return <LoadingScreen/>;
    }

    autoSaveStatus = () => {
        const {autoSaveStatus, apiError} = this.state;
        if (apiError.error) {
            return <p className='text-autoSave-failed'>{apiError.message}</p>;
        }
        if (autoSaveStatus.show) {
            return <p className='text-autoSave-success'>{autoSaveStatus.text}</p>;
        }
        return null;
    };

    renderLanguageSelector = (deserialize, getContent) => {
        const {article, allArticles} = this.state;
        this.deserialize = deserialize;
        if (article && allArticles.length > 0) {
            // INFO:
            // get all languages from articles
            const allLanguages = allArticles
                .map((article) => article.language)
                .filter((language) => language !== article.language);
            return (
                <div style={{marginRight: '50px'}}>
                    <LanguageSelector
                        selectedLanguage={article.language}
                        languages={allLanguages}
                        onChange={(lng) => {
                            this.actualEditorContent = getContent();
                            this.onChangeLanguage(lng);
                        }}
                        style={{marginTop: '6px'}}
                    />
                    <AddIcon
                        style={{marginLeft: '10px', marginTop: '3px', cursor: 'pointer'}}
                        onClick={() => {
                            this.actualEditorContent = getContent();
                            if (!!article.title) {
                                this.setState({showLanguageSelectDialog: true});
                            } else {
                                this.setState({showSaveDialog: true});
                                this.saveDialogCb = () => this.setState({showLanguageSelectDialog: true});
                            }
                        }}
                    />
                </div>
            );
        }
        return null;
    };

    async componentDidMount() {
        await this.setState({fetched: false}, async () => {
            window.scrollTo(0, 0);
            const {publicId, language} = this.props.match.params;
            let allArticles = [];
            if (publicId) {
                const response = await fetchAllArticlesPreviewsByPublicId(publicId);
                if (response && response.data && response.data[0].articles.length) {
                    allArticles = response.data[0].articles;
                    const firstArticle = allArticles.find((article) => article.language === language);
                    const targetArticle = firstArticle && firstArticle.id ? firstArticle : allArticles[0];
                    this.setState({
                        fetched: true,
                        allArticles,
                        article: targetArticle,
                        autoSaveStatus: {
                            text: response.message,
                            show: true,
                        },
                    }, () => {
                        setTimeout(() => {
                            this.setState({autoSaveStatus: {show: false}});
                        }, this.autoSaveTimeout);
                    });
                    return;
                }
            }
            this.setState({redirect: true});
        });
    }
}

export default ArticleEditorProvider;
