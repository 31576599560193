import React from 'react';
import {Grid} from '@material-ui/core';
import {InquiryForm} from '../../../../components/inquiry/form/inquiryForm';
import Checkbox from '../../../../components/form/checkbox/checkbox';
import InquiryInput from '../../../../components/inquiry/input/inquiryInput';
import ContinueButton from '../../../../components/inquiry/continueButton/continueButton';
import ErasingFrame from '../../../../components/inquiry/erasingFrame/erasingFrame';

class CloudUse extends React.Component {
    title = this.props.title;
    otherInputPlaceholder = this.props.t('inputPlaceholder');

    constructor(props) {
        super(props);
        this.state = {
            usages: props.usages,
            otherInput: props.otherInput || '',
        };
    }

    getOtherIndex = () => {
        return this.state.usages.findIndex((usage) => usage.key === 'other');
    };

    onChangeCheckbox = (key) => {
        let isOtherChecked = false;
        const usages = this.state.usages.map((item) => {
            if (item.key === key) {
                const newValue = !item.active;
                if (!newValue && item.key === 'other') {
                    isOtherChecked = true;
                    return {
                        ...item,
                        others: [],
                        active: !item.active,
                    };
                }
                return {
                    ...item,
                    active: !item.active,
                };
            }
            return item;
        });
        this.setState({
            usages,
            otherInput: isOtherChecked ? '' : this.props.otherInput,
        });
    };

    onChangeOtherInput = (target) => {
        const otherIndex = this.getOtherIndex();
        let usages = this.state.usages;
        if (!usages[otherIndex].active) {
            usages = usages.map((usage) => {
                if (usage.key === 'other') {
                    return {
                        ...usage,
                        active: true,
                    };
                }
                return usage;
            });
        }
        this.setState({
            usages,
            otherInput: target.value,
        });
    };

    onAddOther = () => {
        const {usages, otherInput} = this.state;
        const otherIndex = this.getOtherIndex();
        if (otherInput) {
            let otherUsage = usages[otherIndex];
            const key = otherUsage.others.length !== 0
                ? otherUsage.others[otherUsage.others.length - 1].key + 1
                : 0;
            otherUsage.others.push({key, value: otherInput});
            usages.pop();
            this.setState({
                usages: [...usages, otherUsage],
                otherInput: '',
            });
        }
    };

    onRemoveOther = (key) => {
        const {usages} = this.state;
        const otherIndex = this.getOtherIndex();
        const others = usages[otherIndex].others.filter((usage) => usage.key !== key);
        const newOtherUsage = {...usages[otherIndex], others};
        usages.pop();
        this.setState({usages: [...usages, newOtherUsage]});
    };

    render() {
        const {sliderSettings, onNextClick, onPreviousClick, t} = this.props;
        return (
            <InquiryForm
                title={this.title}
                sliderSettings={sliderSettings}
                onNextClick={onNextClick}
                onPreviousClick={onPreviousClick}
            >
                <Grid
                    container
                    item
                    className='my-3'
                >
                    <Grid item xs={12} md={6} lg={4}>
                        {this.renderCheckboxes()}
                    </Grid>
                    <Grid item>
                        <ContinueButton t={t} onClick={onNextClick}/>
                    </Grid>
                </Grid>
            </InquiryForm>
        );
    }

    renderCheckboxes = () => {
        return this.state.usages.map((usage, index) => {
            const label = <p className='text-16'>{usage.name}</p>;
            return (
                <Grid
                    key={index}
                    item
                    xs={12}
                    className='flex-nowrap'
                >
                    <Checkbox
                        label={label}
                        name={usage.name}
                        checked={usage.active}
                        className='flex-nowrap'
                        onChange={() => this.onChangeCheckbox(usage.key)}
                    />
                    {usage.key === 'other' && this.renderAddOtherInput()}
                </Grid>
            );
        });
    };

    renderAddOtherInput = () => {
        return (
            <Grid container direction='row'>
                <Grid item className='ml-4 mb-4' xs={12}>
                    <InquiryInput
                        placeholder={this.otherInputPlaceholder}
                        onChange={this.onChangeOtherInput}
                        onIconClick={this.onAddOther}
                        value={this.state.otherInput}
                        showIcon
                    />
                </Grid>
                {this.renderOthers()}
            </Grid>
        );
    };

    renderOthers = () => {
        const otherIndex = this.getOtherIndex();
        const others = this.state.usages[otherIndex].others;
        if (others.length > 0) {
            return others.map((other, index) => {
                return <ErasingFrame key={index.toString()} text={other.value}
                                     onClick={() => this.onRemoveOther(other.key)}/>;
            });
        }
    };

    componentWillUnmount() {
        const {usages, otherInput} = this.state;
        this.props.saveUsages(usages);
        this.props.saveOtherInput(otherInput);
    }
}

export default CloudUse;