import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import {DialogContent, DialogTitle} from '@material-ui/core';
import DialogContentText from '@material-ui/core/DialogContentText';
import {getFileFromBinaryData} from '../../../../utils/utils';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

class ReferenceRemoveDialog extends React.Component {
    title = 'Remove Reference';

    getCompanyLogo = () => {
        const reference = this.props.selectedReference;
        const isCompanyLogoExist = reference && reference.referenceCompanyLogo;
        let targetCompanyLogo;
        if (isCompanyLogoExist && reference.referenceCompanyLogo.data) {
            const logo = reference.referenceCompanyLogo;
            if (logo.data.name && typeof logo.data.name === 'string') {
                targetCompanyLogo = logo.data;
            } else {
                const logoFromBinary = getFileFromBinaryData(logo);
                targetCompanyLogo = logoFromBinary ? logoFromBinary : null;
            }
        }
        return targetCompanyLogo;
    };

    render() {
        const {show, onDisagree} = this.props;
        return (
            <Dialog
                open={show}
                onClose={onDisagree}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
            >
                <DialogTitle id='alert-dialog-title'>
                    <p className='text-table-head'>{this.title}</p>
                </DialogTitle>
                {this.renderDialogContent()}
                {this.renderDialogActions()}
            </Dialog>
        );
    }

    renderDialogContent = () => {
        const reference = this.props.selectedReference;
        const companyLogo = this.getCompanyLogo();
        if (reference && reference.id) {
            return (
                <DialogContent>
                    <DialogContentText id='alert-dialog-description'>
                        {reference.language && <div className='flex justify-between'>
                            <p className='text-23'>Language:</p>
                            <p className='text-24'>{reference.language}</p>
                        </div>}
                        {reference.language && <div className='border-bottom border-black'/>}
                        {reference.companyName &&
                        <div className='mt-3'>
                            <p className='text-23'>Company Name:</p>
                            <p className='text-24'>{reference.companyName}</p>
                        </div>}
                        {reference.companyWebUrl &&
                        <div className='mt-3'>
                            <p className='text-23'>Company Web Url:</p>
                            <p className='text-24'>{reference.companyWebUrl}</p>
                        </div>}
                        {reference.clientName &&
                        <div className='mt-3'>
                            <p className='text-23'>Client Name:</p>
                            <p className='text-24'>{reference.clientName}</p>
                        </div>}
                        {reference.clientRole &&
                        <div className='mt-3'>
                            <p className='text-23'>Client Role:</p>
                            <p className='text-24'>{reference.clientRole}</p>
                        </div>}
                        {reference.referenceText &&
                        <div className='mt-3'>
                            <p className='text-23'>Reference Text:</p>
                            <p className='text-24'>{reference.referenceText}</p>
                        </div>}
                        {companyLogo &&
                        <div className='company-logo-container'>
                            <p className='text-23'>Company Logo:</p>
                            <img src={URL.createObjectURL(companyLogo)} alt='' className='company-logo-img'/>
                        </div>}
                    </DialogContentText>
                </DialogContent>
            );
        }
        return null;
    };

    renderDialogActions = () => {
        const {onDisagree, onAgree, selectedReference} = this.props;
        return (
            <DialogActions>
                <Button onClick={onDisagree} autoFocus>
                    <p className='text-24'>Disagree</p>
                </Button>
                <Button onClick={() => onAgree(selectedReference.id)}>
                    <p className='text-24'>Agree</p>
                </Button>
            </DialogActions>
        );
    };
}

export default ReferenceRemoveDialog;