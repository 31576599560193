import React from 'react';
import ReferenceTableHeader from './header/referenceTableHeader';
import ReferenceTableBody from './body/referenceTableBody';

class ReferenceTable extends React.Component {
    columns = [
        {
            name: 'Company Logo',
        },
        {
            name: 'Client Name',
        },
        {
            name: 'Language',
        },
        {
            name: 'Created at',
        },
        {
            name: 'Last updated',
        },
        {
            name: 'Reference',
        },
        {
            name: 'Case study',
        },
        {
            name: '#',
        },
    ];

    render() {
        return (
            <table className='table table-striped w-100 border shadow'>
                <ReferenceTableHeader columns={this.columns}/>
                <ReferenceTableBody {...this.props}/>
            </table>
        );
    }
}

export default ReferenceTable;