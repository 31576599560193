import React from 'react';

class Submit extends React.Component {
    state = {
        isHover: false,
    };

    onMouseEnter = () => this.setState({isHover: true});

    onMouseLeave = () => this.setState({isHover: false});

    render() {
        const {type} = this.props;
        switch (type) {
            case 'contact-form':
                return this.renderContactFormSubmit();
            case 'contact-form-only-text':
                return this.renderContactFormOnlyTextSubmit();
            default:
                return <input type='submit'/>;
        }
    }

    renderContactFormSubmit = () => {
        const {text} = this.props;
        return (
            <input
                type='submit'
                className='submit-1'
                value={text}
                onMouseEnter={this.onMouseEnter}
                onMouseLeave={this.onMouseLeave}
            />
        );
    };

    renderContactFormOnlyTextSubmit = () => {
        const {text} = this.props;
        return (
            <input
                type='submit'
                className='submit-2'
                value={text}
                onMouseEnter={this.onMouseEnter}
                onMouseLeave={this.onMouseLeave}
            />
        );
    };
}

export default Submit;