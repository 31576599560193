import React from 'react';
import {InquiryForm} from '../../../components/inquiry/form/inquiryForm';
import Grid from '@material-ui/core/Grid';
import Dropdown from '../../../components/dropdown/Dropdown';
import ContinueButton from '../../../components/inquiry/continueButton/continueButton';
import {withTranslation} from 'react-i18next';

class ProposalMigrationInternal extends React.Component {
    title = `6. ${this.props.t('proposalMigration.title')}`;
    migrationProposals = [
        {
            key: 'full service',
            name: this.props.t('proposalMigration.migrationProposals.fullService'),
        },
        {
            key: 'only migration',
            name: this.props.t('proposalMigration.migrationProposals.onlyMigration'),
        },
        {
            key: 'help',
            name: this.props.t('proposalMigration.migrationProposals.help'),
        },
    ];

    constructor(props) {
        super(props);
        this.state = {
            selectedMigrationProposal: props.selectedMigrationProposal || this.migrationProposals[0],
        };
    }

    onChangeMigrationProposal = (selectedMigrationProposal) => this.setState({selectedMigrationProposal});

    render() {
        const {sliderSettings, onNextClick, onPreviousClick, t} = this.props;
        return (
            <InquiryForm
                title={this.title}
                sliderSettings={sliderSettings}
                onNextClick={onNextClick}
                onPreviousClick={onPreviousClick}
            >
                <Grid
                    container
                    item
                    className='my-4'
                    xs={12}
                >
                    <Dropdown
                        selectedItem={this.state.selectedMigrationProposal}
                        items={this.migrationProposals}
                        onChange={this.onChangeMigrationProposal}
                    />
                    <Grid item xs={12} lg={6} className='mt-3 mt-xl-0 ml-xl-3'>
                        <ContinueButton t={t} onClick={onNextClick}/>
                    </Grid>
                </Grid>
            </InquiryForm>
        );
    }

    componentWillUnmount() {
        const {selectedMigrationProposal} = this.state;
        this.props.saveSelectedMigrationProposal(selectedMigrationProposal);
    }
}

export const ProposalMigration = withTranslation('inquiry')(ProposalMigrationInternal);