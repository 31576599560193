import React, {Fragment} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {stylesXL} from './stylesXL';
import {stylesLG} from './stylesLG';
import {stylesMD} from './stylesMD';

const widthBreakpoint = {xl: 2000, lg: 1780, md: 1370};
const activeOpacity = '0.97';
const noActiveOpacity = '0.2';

const useXLStyles = makeStyles(stylesXL);
const useLGStyles = makeStyles(stylesLG);
const useMDStyles = makeStyles(stylesMD);

// INFO:
// simple horizontalSlider which contains 3 values
//
// Properties:
// classes - style for values
// index - current slide
const HorizontalSlider = ({index, screenWidth, onHover, onMouseMove}) => {
    const XLStyles = useXLStyles();
    const LGStyles = useLGStyles();
    const MDStyles = useMDStyles();
    let classNames = LGStyles;
    const onMouseEnter = (index) => {
        onHover(index);
        onMouseMove(true);
    };
    const onMouseLeave = () => {
        onMouseMove(false);
    };
    // XL
    if (screenWidth > widthBreakpoint.xl) {
        classNames = XLStyles;
    }
    // MD
    if (screenWidth < widthBreakpoint.lg && screenWidth > widthBreakpoint.md) {
        classNames = MDStyles;
    }
    const firstOpacity = index === 0
        ? activeOpacity
        : noActiveOpacity;
    const secondOpacity = index === 1
        ? activeOpacity
        : noActiveOpacity;
    const thirdOpacity = index === 2
        ? activeOpacity
        : noActiveOpacity;
    return (
        <Fragment>
            <div
                onMouseEnter={() => onMouseEnter(0)}
                onMouseLeave={onMouseLeave}
                className={classNames.first}
                style={{opacity: firstOpacity}}
            />
            <div
                onMouseEnter={() => onMouseEnter(1)}
                onMouseLeave={onMouseLeave}
                className={classNames.second}
                style={{opacity: secondOpacity}}
            />
            <div
                onMouseEnter={() => onMouseEnter(2)}
                onMouseLeave={onMouseLeave}
                className={classNames.third}
                style={{opacity: thirdOpacity}}
            />
        </Fragment>
    );
};

export default HorizontalSlider;
