import React from 'react';
import CloudUse from '../shared/cloudUse/cloudUse';
import {withTranslation} from 'react-i18next';

class DesignCloudUseInternal extends React.Component {
    title = `2. ${this.props.t('designCloudUse.title')}`;

    constructor(props) {
        super(props);
        this.state = {
            usages: props.usages || [
                {
                    key: 'development',
                    name: props.t('designCloudUse.usages.development'),
                    active: false,
                },
                {
                    key: 'power',
                    name: props.t('designCloudUse.usages.power'),
                    active: false,
                },
                {
                    key: 'storage',
                    name: props.t('designCloudUse.usages.storage'),
                    active: false,
                },
                {
                    key: 'backup',
                    name: props.t('designCloudUse.usages.backup'),
                    active: false,
                },
                {
                    key: 'analysis',
                    name: props.t('designCloudUse.usages.analysis'),
                    active: false,
                },
                {
                    key: 'service',
                    name: props.t('designCloudUse.usages.service'),
                    active: false,
                },
                {
                    key: 'other',
                    name: props.t('designCloudUse.usages.other'),
                    active: false,
                    others: [],
                },
            ],
        };
    }

    render() {
        return <CloudUse {...this.props} title={this.title} usages={this.state.usages}/>;
    }
}

export const DesignCloudUse = withTranslation('inquiry')(DesignCloudUseInternal);