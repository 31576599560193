import React from 'react';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import {formatUTCDate} from '../../../../../utils/utils';
import {Link} from 'react-router-dom';

class ArticleUI extends React.Component {

    onRemoveArticle = () => {
        this.props.onRemoveArticle && this.props.onRemoveArticle(this.props.article);
    };

    getArticleLanguage = () => {
        const {article} = this.props;
        if (article && article.language) {
            return article.language;
        }
        return 'any';
    };

    getPreviewArticleUrl = () => {
        const {article, previewArticleUrl} = this.props;
        if (article.contentMetadata && article.contentMetadata.slug) {
            return `${previewArticleUrl}${article.contentMetadata.slug}`;
        }
        return '/';
    };

    render() {
        const {article, languagesSelector, editArticleUrl} = this.props;
        const language = this.getArticleLanguage();
        return (
            <tr className='text-table-row'>
                <td>
                    <a
                        className='text-table-row text-table-row-2'
                        href={this.getPreviewArticleUrl()}
                        target='_blank'
                        rel='noreferrer'
                    >
                        {article.title}
                    </a>
                </td>
                <td>{languagesSelector(article)}</td>
                <td>{article.contentMetadata.category}</td>
                <td>{formatUTCDate(article.contentMetadata.createdAt)}</td>
                <td>{formatUTCDate(article.contentMetadata.lastUpdated)}</td>
                <td>
                    <Link to={`${editArticleUrl}${this.props.publicId}/${language}`} className='blue-button-2'>
                        <EditIcon className='mr-3'/>
                    </Link>
                    <DeleteIcon className='mt-3 mt-sm-0 blue-button-2 cursor-pointer' onClick={this.onRemoveArticle}/>
                </td>
            </tr>
        );
    }
}

export default ArticleUI;
