import React from 'react';
import expandIcon from '../../assets/Expand.png';

// INFO:
// simple selector
//
// Properties:
// selectedLanguage - current selected language
// languages - list of all languages
// onChange - function, is trigger when select item in dropdown
class LanguageSelector extends React.Component {
    render() {
        const {selectedLanguage, languages, style} = this.props;
        return (
            <div
                className='btn-group'
                style={style}
            >
                <button
                    className='btn btn-secondary btn-sm dropdown-toggle'
                    type='button'
                    data-toggle='dropdown'
                    aria-haspopup='true'
                    aria-expanded='false'
                    style={{
                        maxWidth: '60px',
                        maxHeight: '28px',
                        marginTop: '-5px',
                        border: '1px solid red',
                        fontFamily: 'WorkSans-Regular',
                        fontSize: '15px',
                        fontWeight: 300,
                        lineHeight: 1.25,
                        color: '#283269',
                    }}
                >
                    <div className='d-flex'>
                        <p>{selectedLanguage}</p>
                        <img
                            src={expandIcon}
                            alt='expand'
                            style={{
                                height: '7px',
                                marginTop: '7px',
                                marginLeft: '10px',
                            }}
                        />
                    </div>
                </button>
                <div className='dropdown-menu'>
                    {languages.map((lng, index) => {
                        return (
                            <a
                                key={index.toString()}
                                className='dropdown-item'
                                style={{
                                    maxWidth: '60px',
                                    maxHeight: '28px',
                                    marginTop: '5px',
                                    fontFamily: 'WorkSans-Regular',
                                    fontSize: '15px',
                                    fontWeight: 300,
                                    lineHeight: 1.25,
                                    color: '#283269',
                                }}
                                onClick={() => this.props.onChange(lng)}
                                href
                            >
                                {lng}
                            </a>
                        );
                    })}
                </div>
            </div>
        );
    }
}

export default LanguageSelector;