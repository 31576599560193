import React from 'react';
import {Grid} from '@material-ui/core';
import {useNode} from '@craftjs/core';
import Button from "@material-ui/core/Button";
import {convertFileToBase64} from "../../../../../utils/base64/base64";

export type ToolbarFileInputProps = {
    propKey?: string;
    onChange?: (value: any) => any;
};
export const ToolbarFileInput = ({propKey, onChange}: ToolbarFileInputProps) => {
    const {
        actions: {setProp},
        propValue,
    } = useNode((node) => ({
        propValue: node.data.props[propKey],
    }));
    const value = propValue;
    return (
        <Grid item xs={12}>
            <div className='mb-2'>
                <Button
                    className='mt-1'
                    variant='contained'
                    component='label'
                    onChange={(event) => {
                        convertFileToBase64(event.target.files[0], (result) => {
                            setProp((props: any) => {
                                props[propKey] = onChange ? onChange(result) : result;
                            }, 500);
                        });
                    }}
                >
                    Browse
                    <input
                        type='file'
                        style={{display: "none"}}
                        accept='.png, .jpeg, .jpg, .gif, .svg'
                    />
                </Button>
                {value && <p>{value.name}</p>}
            </div>
        </Grid>
    );
};
