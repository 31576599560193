import React from 'react';
import lz from 'lzutf8';
import {Redirect} from 'react-router-dom';
import ArticlePage from '../../../screens/blog/page/articlePage';
import {fetchArticleContent} from '../../../api/blog/blogApi';
import LoadingScreen from '../../../components/emptyScreen/loadingScreen';
import {withTranslation} from 'react-i18next';
import {formatUTCDate} from '../../../utils/utils';

class ArticlePageProviderInternal extends React.Component {

    state = {
        fetched: false,
        redirect: false,
        base64: null,
        article: null,
    };

    formatDate = (date) => {
        const splitDate = date.split(' ');
        return `${splitDate[0]} ${splitDate[1]} ${splitDate[2]}`;
    };

    render() {
        const {redirect, base64, article} = this.state;
        if (redirect) {
            return <Redirect to='/blog'/>;
        }
        if (base64) {
            const date = this.formatDate(formatUTCDate(article.date));
            return (
                <ArticlePage
                    t={this.props.t}
                    data={base64}
                    title={article.title}
                    date={date}
                    readTime={article.readTime}
                />
            );
        }
        return <LoadingScreen/>;
    }

    async componentDidMount() {
        await this.setState({fetched: false}, async () => {
            window.scrollTo(0, 0);
            const {slug} = this.props.match.params;
            let base64;
            if (slug) {
                const response = await fetchArticleContent(slug);
                if (response && response.data) {
                    base64 = lz.decompress(lz.decodeBase64(response.data.content));
                    const article = {
                        title: response.data.title,
                        date: response.data.contentMetadata ? response.data.contentMetadata.createdAt : null,
                        readTime: response.data.contentMetadata ? response.data.contentMetadata.readTime : null,
                    };
                    this.setState({base64, article});
                } else {
                    this.setState({redirect: true});
                }
            }
        });
    }
}

export const ArticlePageProvider = withTranslation('blog')(ArticlePageProviderInternal);
