import defaultImage from '../../../assets/default.png';

export const examplePreviewArticles = [
    {
        image: defaultImage,
        title: 'Titulek článku blogu',
        date: '15. 3. 2020',
        previewText: 'Vítr skoro nefouká a tak by se na první pohled mohlo zdát, že se balónky snad…',
    },
    {
        image: defaultImage,
        title: 'Titulek článku blogu',
        date: '15. 3. 2020',
        previewText: 'Vítr skoro nefouká a tak by se na první pohled mohlo zdát, že se balónky snad…',
    },
    {
        image: defaultImage,
        title: 'Titulek článku blogu',
        date: '15. 3. 2020',
        previewText: 'Vítr skoro nefouká a tak by se na první pohled mohlo zdát, že se balónky snad…',
    },
    {
        image: defaultImage,
        title: 'Titulek článku blogu',
        date: '15. 3. 2020',
        previewText: 'Vítr skoro nefouká a tak by se na první pohled mohlo zdát, že se balónky snad…',
    },
    {
        image: defaultImage,
        title: 'Titulek článku blogu',
        date: '15. 3. 2020',
        previewText: 'Vítr skoro nefouká a tak by se na první pohled mohlo zdát, že se balónky snad…',
    },
    {
        image: defaultImage,
        title: 'Titulek článku blogu',
        date: '15. 3. 2020',
        previewText: 'Vítr skoro nefouká a tak by se na první pohled mohlo zdát, že se balónky snad…',
    },
    {
        image: defaultImage,
        title: 'Titulek článku blogu',
        date: '15. 3. 2020',
        previewText: 'Vítr skoro nefouká a tak by se na první pohled mohlo zdát, že se balónky snad…',
    },
    {
        image: defaultImage,
        title: 'Titulek článku blogu',
        date: '15. 3. 2020',
        previewText: 'Vítr skoro nefouká a tak by se na první pohled mohlo zdát, že se balónky snad…',
    },
    {
        image: defaultImage,
        title: 'Titulek článku blogu',
        date: '15. 3. 2020',
        previewText: 'Vítr skoro nefouká a tak by se na první pohled mohlo zdát, že se balónky snad…',
    },
    {
        image: defaultImage,
        title: 'Titulek článku blogu',
        date: '15. 3. 2020',
        previewText: 'Vítr skoro nefouká a tak by se na první pohled mohlo zdát, že se balónky snad…',
    },
    {
        image: defaultImage,
        title: 'Titulek článku blogu',
        date: '15. 3. 2020',
        previewText: 'Vítr skoro nefouká a tak by se na první pohled mohlo zdát, že se balónky snad…',
    },
    {
        image: defaultImage,
        title: 'Titulek článku blogu',
        date: '15. 3. 2020',
        previewText: 'Vítr skoro nefouká a tak by se na první pohled mohlo zdát, že se balónky snad…',
    },
    {
        image: defaultImage,
        title: 'Titulek článku blogu',
        date: '15. 3. 2020',
        previewText: 'Vítr skoro nefouká a tak by se na první pohled mohlo zdát, že se balónky snad…',
    },
    {
        image: defaultImage,
        title: 'Titulek článku blogu',
        date: '15. 3. 2020',
        previewText: 'Vítr skoro nefouká a tak by se na první pohled mohlo zdát, že se balónky snad…',
    },
    {
        image: defaultImage,
        title: 'Titulek článku blogu',
        date: '15. 3. 2020',
        previewText: 'Vítr skoro nefouká a tak by se na první pohled mohlo zdát, že se balónky snad…',
    },
    {
        image: defaultImage,
        title: 'Titulek článku blogu',
        date: '15. 3. 2020',
        previewText: 'Vítr skoro nefouká a tak by se na první pohled mohlo zdát, že se balónky snad…',
    }, {
        image: defaultImage,
        title: 'Titulek článku blogu',
        date: '15. 3. 2020',
        previewText: 'Vítr skoro nefouká a tak by se na první pohled mohlo zdát, že se balónky snad…',
    },
    {
        image: defaultImage,
        title: 'Titulek článku blogu',
        date: '15. 3. 2020',
        previewText: 'Vítr skoro nefouká a tak by se na první pohled mohlo zdát, že se balónky snad…',
    },
];