import React from 'react';

class LoadingScreen extends React.Component {
    render() {
        return (
            <div className='background-full-resolution position-relative'>
                <div className='spinner-border text-primary position-absolute' role='status'/>
            </div>
        );
    }
}

export default LoadingScreen;
