import React from 'react';
import {Editor, Frame} from '@craftjs/core';
import {editorResolvers} from '../../../components/editor/editor';
import LoadingScreen from '../../../components/emptyScreen/loadingScreen';
import ArticleHead from '../../../components/blog/articlePage/articleHead';

class ArticlePage extends React.Component {

    state = {
        show: false,
    };

    render() {
        const {t, data, date, title, readTime} = this.props;
        const {show} = this.state;
        const readTimeText = readTime ? `${readTime} ${t('readTime')}` : null;
        return (
            <React.Fragment>
                {!show && <LoadingScreen/>}
                <div className={show ? 'opacity-100' : 'opacity-0'}>
                    <Editor resolver={editorResolvers} enabled={false}>
                        <div className='editor-container'>
                            <ArticleHead title={title} date={date} readTime={readTimeText}/>
                            <Frame data={data}/>
                        </div>
                    </Editor>
                </div>
            </React.Fragment>
        );
    }

    componentDidMount() {
        setTimeout(() => this.setState({show: true}), 50);
    }
}

export default ArticlePage;
