import React from 'react';
import Grid from '@material-ui/core/Grid';

class PrivacyPolicyCS extends React.Component {
    render() {
        return (
            <Grid
                item
                container
                direction='column'
                alignContent='center'
                xs={12}
            >
                {this.renderFirst()}
                {this.renderSecond()}
                {this.renderThird()}
                {this.renderFourth()}
                {this.renderFifth()}
                {this.renderSixth()}
                {this.renderSeventh()}
                {this.renderEight()}
            </Grid>
        );
    }

    renderFirst = () => {
        const {t} = this.props;
        return (
            <React.Fragment>
                <h1 className='text-51'>{t('I.title')}</h1>
                <p className='text-52 pt-4'>{t('I.first')}</p>
                <h2 className='text-53 pt-4'>{t('I.second.title')}</h2>
                <p className='text-54 pt-2 pl-3'>{t('I.second.contact.name')}</p>
                <p className='text-54 pt-2 pl-3'>{t('I.second.contact.address')}</p>
                <p className='text-54 pt-2 pl-3'>{t('I.second.contact.email')}</p>
                <p className='text-54 pt-2 pl-3'>{t('I.second.contact.phone')}</p>
                <p className='text-52 pt-4'>{t('I.third')}</p>
            </React.Fragment>
        );
    };

    renderSecond = () => {
        const {t} = this.props;
        return (
            <React.Fragment>
                <h1 className='text-51'>{t('II.title')}</h1>
                <h2 className='text-53 pt-4'>{t('II.first.title')}</h2>
                <p className='text-54 pt-2 pl-3'>{t('II.first.name')}</p>
                <p className='text-54 pt-2 pl-3'>{t('II.first.email')}</p>
                <p className='text-54 pt-2 pl-3'>{t('II.first.postalAddress')}</p>
                <p className='text-54 pt-2 pl-3'>{t('II.first.phone')}</p>
                <p className='text-54 pt-2 pl-3'>{t('II.first.bank')}</p>
            </React.Fragment>
        );
    };

    renderThird = () => {
        const {t} = this.props;
        return (
            <React.Fragment>
                <h1 className='text-51'>{t('III.title')}</h1>
                <h2 className='text-53 pt-4'>{t('III.first.title')}</h2>
                <p className='text-54 pt-2 pl-3'>{t('III.first.first')}</p>
                <p className='text-54 pt-2 pl-3'>{t('III.first.second')}</p>
                <p className='text-54 pt-2 pl-3'>{t('III.first.third')}</p>
                <p className='text-54 pt-2 pl-3'>{t('III.first.fourth')}</p>
                <h2 className='text-53 pt-4'>{t('III.second.title')}</h2>
                <p className='text-54 pt-2 pl-3'>{t('III.second.first')}</p>
                <p className='text-54 pt-2 pl-3'>{t('III.second.second')}</p>
                <p className='text-54 pt-2 pl-3'>{t('III.second.third')}</p>
                <h2 className='text-53 pt-4'>{t('III.third.title')}</h2>
            </React.Fragment>
        );
    };

    renderFourth = () => {
        const {t} = this.props;
        return (
            <React.Fragment>
                <h1 className='text-51'>{t('IV.title')}</h1>
                <h2 className='text-53 pt-4'>{t('IV.first.title')}</h2>
                <p className='text-54 pt-2 pl-3'>{t('IV.first.first')}</p>
                <p className='text-54 pt-2 pl-3'>{t('IV.first.second')}</p>
                <p className='text-54 pt-2 pl-3'>{t('IV.first.third')}</p>
            </React.Fragment>
        );
    };

    renderFifth = () => {
        const {t} = this.props;
        return (
            <React.Fragment>
                <h1 className='text-51'>{t('V.title')}</h1>
                <h2 className='text-53 pt-4'>{t('V.first.title')}</h2>
                <p className='text-54 pt-2 pl-3'>{t('V.first.first')}</p>
                <p className='text-54 pt-2 pl-3'>{t('V.first.second')}</p>
                <h2 className='text-53 pt-4'>{t('V.second.title')}</h2>
            </React.Fragment>
        );
    };

    renderSixth = () => {
        const {t} = this.props;
        return (
            <React.Fragment>
                <h1 className='text-51'>{t('VI.title')}</h1>
                <h2 className='text-53 pt-4'>{t('VI.first.title')}</h2>
                <p className='text-54 pt-2 pl-3'>{t('VI.first.first')}</p>
                <p className='text-54 pt-2 pl-3'>{t('VI.first.second')}</p>
                <p className='text-54 pt-2 pl-3'>{t('VI.first.third')}</p>
                <p className='text-54 pt-2 pl-3'>{t('VI.first.fourth')}</p>
                <p className='text-54 pt-2 pl-3'>{t('VI.first.fifth')}</p>
                <p className='text-54 pt-2 pl-3'>{t('VI.first.sixth')}</p>
            </React.Fragment>
        );
    };

    renderSeventh = () => {
        const {t} = this.props;
        return (
            <React.Fragment>
                <h1 className='text-51'>{t('VII.title')}</h1>
                <p className='text-54 pt-2 pl-3'>{t('VII.first')}</p>
                <p className='text-54 pt-2 pl-3'>{t('VII.second')}</p>
                <p className='text-54 pt-2 pl-3'>{t('VII.third')}</p>
            </React.Fragment>
        );
    };

    renderEight = () => {
        const {t} = this.props;
        return (
            <React.Fragment>
                <h1 className='text-51'>{t('VIII.title')}</h1>
                <p className='text-54 pt-2 pl-3'>{t('VIII.first')}</p>
                <p className='text-54 pt-2 pl-3'>{t('VIII.second')}</p>
            </React.Fragment>
        );
    };
}

export default PrivacyPolicyCS;