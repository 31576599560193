import React from 'react';

class LoadMoreButton extends React.Component {
    render() {
        return (
            <button className='article-preview-next-button' onClick={this.props.onClick}>
                {this.props.t('loadMoreButton')}
            </button>
        );
    }
}

export default LoadMoreButton;