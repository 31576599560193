import React from 'react';

// INFO:
// simple html form element
//
// Properties:
// onSubmit - function, trigger when click on submit button
class Form extends React.Component {

    onSubmit = (event) => {
        event.preventDefault();
        this.props.onSubmit && this.props.onSubmit();
    };

    render() {
        return (
            <form onSubmit={this.onSubmit} className='w-100'>
                {this.props.children}
            </form>
        );
    }
}

export default Form;