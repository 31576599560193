import React from 'react';
import {getFileFromBinaryData} from '../../../../utils/utils';
import {Dialog, DialogContent, DialogTitle} from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Dropdown from '../../../dropdown/Dropdown';
import {withTranslation} from 'react-i18next';

class CaseStudySaveDialogInternal extends React.Component {

    constructor(props) {
        super(props);
        const caseStudy = props.caseStudy;
        const isPreviewImageExist = caseStudy && caseStudy.caseStudyPreviewImage;
        const isBlogArticleUrlExist = caseStudy && caseStudy.blogArticleUrl;
        const isInquiryTypeExist = caseStudy && caseStudy.inquiryType && caseStudy.inquiryType !== 'none';
        let targetPreviewImage;
        if (isPreviewImageExist && caseStudy.caseStudyPreviewImage.data) {
            const logo = caseStudy.caseStudyPreviewImage;
            if (logo.data.name && typeof logo.data.name === 'string') {
                targetPreviewImage = logo.data;
            } else {
                const logoFromBinary = getFileFromBinaryData(logo);
                if (logoFromBinary) {
                    targetPreviewImage = logoFromBinary;
                }
            }
        }
        const inquiryTypes = this.inquiryTypes();
        let inquiryType = inquiryTypes[0];
        if (isInquiryTypeExist) {
            inquiryType = inquiryTypes
                .find((item) => item.key === caseStudy.inquiryType);
        }
        this.state = {
            previewImage: targetPreviewImage,
            blogArticleUrl: isBlogArticleUrlExist
                ? caseStudy.blogArticleUrl
                : '',
            inquiryType,
        };
    }

    inquiryTypes = () => {
        const {t} = this.props;
        return [
            {
                key: 'none',
                name: t('inquiryType.none'),
            },
            {
                key: 'optimization',
                name: t('inquiryType.optimization'),
            },
            {
                key: 'migration',
                name: t('inquiryType.migration'),
            },
            {
                key: 'design',
                name: t('inquiryType.design'),
            },
            {
                key: 'advice',
                name: t('inquiryType.advice'),
            },
        ];
    };

    title = 'Case Study';

    onSave = async () => {
        const {previewImage, blogArticleUrl, inquiryType} = this.state;
        this.props.onClose && this.props.onClose();
        const saveData = {
            previewImage,
            blogArticleUrl,
            inquiryType: inquiryType.key,
        };
        this.props.onSave && this.props.onSave(saveData);
    };

    onChangeElement = (name, value) => this.setState({[name]: value});

    render() {
        const {show, onClose} = this.props;
        return (
            <Dialog
                open={show}
                onClose={onClose}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
            >
                <DialogTitle id='alert-dialog-title'>
                    <p className='text-table-head'>{this.title}</p>
                </DialogTitle>
                {this.renderDialogContent()}
                {this.renderDialogActions()}
            </Dialog>
        );
    }

    renderDialogContent = () => {
        const {previewImage, blogArticleUrl, inquiryType} = this.state;
        const logoSize = {
            width: '7em',
        };
        return (
            <DialogContent>
                <p className='text-24 mt-3 mb-1'>Preview Image</p>
                <input
                    className='input-4 mb-2'
                    type='file'
                    onChange={event => this.onChangeElement('previewImage', event.target.files[0])}
                    style={{paddingTop: '8px'}}
                    accept='.png, .jpeg, .jpg, .gif, .svg, .mp4'
                />
                {previewImage &&
                <img src={URL.createObjectURL(previewImage)} alt='' style={logoSize}/>}
                <p className='text-24 mt-3 mb-1'>Blog Article Url</p>
                <input
                    className='input-4'
                    type='text'
                    value={blogArticleUrl}
                    onChange={event => this.onChangeElement('blogArticleUrl', event.target.value)}
                />
                <p className='text-24 mt-3 mb-1'>Inquiry Type</p>
                <Dropdown
                    selectedItem={inquiryType}
                    items={this.inquiryTypes()}
                    onChange={item => this.onChangeElement('inquiryType', item)}
                    className='w-100'
                />
            </DialogContent>
        );
    };

    renderDialogActions = () => {
        return (
            <DialogActions>
                <Button onClick={() => this.props.onClose && this.props.onClose()} color='inherit'>
                    <p className='text-24'>Cancel</p>
                </Button>
                <Button onClick={this.onSave} color='inherit'>
                    <p className='text-24'>Save</p>
                </Button>
            </DialogActions>
        );
    };
}

export const CaseStudySaveDialog = withTranslation('global')(CaseStudySaveDialogInternal);