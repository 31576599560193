import React from 'react';
import {Grid} from '@material-ui/core';
import {InquiryForm} from '../../../components/inquiry/form/inquiryForm';
import Dropdown from '../../../components/dropdown/Dropdown';
import ContinueButton from '../../../components/inquiry/continueButton/continueButton';
import {withTranslation} from 'react-i18next';

class WhenceMigrationInternal extends React.Component {
    title = `2. ${this.props.t('whenceMigration.title')}`;
    migrationPlaces = [
        {
            key: 'public cloud',
            name: this.props.t('whenceMigration.migrationPlaces.publicCloud'),
        },
        {
            key: 'dedicated structures',
            name: this.props.t('whenceMigration.migrationPlaces.dedicatedStructures'),
        },
    ];

    constructor(props) {
        super(props);
        this.state = {
            selectedMigrationPlace: props.selectedMigrationPlace || this.migrationPlaces[0],
        };
    }

    onChangeMigrationPlace = (selectedMigrationPlace) => this.setState({selectedMigrationPlace});

    render() {
        const {sliderSettings, onNextClick, onPreviousClick, t} = this.props;
        return (
            <InquiryForm
                title={this.title}
                sliderSettings={sliderSettings}
                onNextClick={onNextClick}
                onPreviousClick={onPreviousClick}
            >
                <Grid
                    container
                    item
                    className='my-4'
                    xs={12}
                >
                    <Dropdown
                        selectedItem={this.state.selectedMigrationPlace}
                        items={this.migrationPlaces}
                        onChange={this.onChangeMigrationPlace}
                    />
                    <Grid item xs={12} lg={6} className='mt-3 mt-xl-0 ml-xl-3'>
                        <ContinueButton t={t} onClick={onNextClick}/>
                    </Grid>
                </Grid>
            </InquiryForm>
        );
    }

    componentWillUnmount() {
        this.props.saveSelectedMigrationPlace(this.state.selectedMigrationPlace);
    }
}

export const WhenceMigration = withTranslation('inquiry')(WhenceMigrationInternal);