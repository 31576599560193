import React from 'react';
import Grid from '@material-ui/core/Grid';
import {withTranslation} from 'react-i18next';
import CaseStudyManager from '../reference/manager/caseStudyManager/caseStudyManager';

class CaseStudyInternal extends React.Component {
    render() {
        return (
            <div className='case-studies-container'>
                {this.renderTitle()}
                <CaseStudyManager {...this.props}/>
            </div>
        );
    }

    renderTitle = () => (
        <Grid
            container
            item
            xs={12}
        >
            <p className='text-case-study-title'>{this.props.t('welcome.title')}</p>
        </Grid>
    );
}

export const CaseStudy = withTranslation('caseStudy')(CaseStudyInternal);