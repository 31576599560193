import React from 'react';
import {ToolbarItem} from "../../editor/toolbar/toolbarItem";
import {ToolbarSection} from "../../editor/toolbar/toolbarSection";

export const VideoSettings = () => {
    return (
        <React.Fragment>
            <ToolbarSection title='Youtube'>
                <ToolbarItem
                    full={true}
                    propKey='videoId'
                    type='text'
                    label='Video ID'
                />
            </ToolbarSection>
        </React.Fragment>
    );
};
