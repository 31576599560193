import React from 'react';
import Grid from '@material-ui/core/Grid';

class CookiesCS extends React.Component {
    render() {
        const {t} = this.props;
        return (
            <Grid item container direction='column' alignContent='center' xs={12}>
                <p className='text-52 pt-4' style={{marginTop: '100px'}}>{t('first')}</p>
                <p className='text-52 pt-4'>{t('second')}</p>
                <p className='text-52 pt-4'>{t('third')}</p>
                <p className='text-52 pt-4'>{t('fourth')}</p>
                <p className='text-52 pt-4'>{t('fifth')}</p>
            </Grid>
        );
    }
}

export default CookiesCS;