import React from 'react';
import Collapsible from 'react-collapsible';
import expandIcon from '../../assets/Expand@2x.png';
import collapseOnIcon from '../../assets/Collapse ON@2x.png';

// INFO:
// toggle visibility of content by clicking on header
//
// Properties:
// title - set the title to header
// text - set the text to hiding area
class Collapse extends React.Component {

    state = {
        isOpen: false,
    };

    render() {
        return (
            <Collapsible
                trigger={this.renderHeader()}
                triggerClassName='collapse-header'
                triggerOpenedClassName='collapse-header'
                onOpen={() => this.setState({isOpen: true})}
                onClose={() => this.setState({isOpen: false})}
            >
                {this.props.text}
            </Collapsible>
        );
    }

    renderHeader = () => {
        const {isOpen} = this.state;
        return (
            <React.Fragment>
                {this.props.title}
                <img
                    className='float-right collapse-arrow mt-4 mr-2'
                     src={isOpen ? collapseOnIcon : expandIcon} alt='expand'
                />
            </React.Fragment>
        );
    };
}

export default Collapse;
