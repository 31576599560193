import React from 'react';
import {Grid} from '@material-ui/core';
import ServiceDescription from '../../../components/inquiry/serviceDescription/serviceDescription';
import ContinueButton from '../../../components/inquiry/continueButton/continueButton';
import Dropdown from '../../../components/dropdown/Dropdown';
import {InquiryForm} from '../../../components/inquiry/form/inquiryForm';
import optimizationIcon from '../../../assets/form-optimalizace.png';
import migrationIcon from '../../../assets/form-migrace.png';
import designIcon from '../../../assets/form-navrh.png';
import adviceIcon from '../../../assets/form-poradenstvi.png';
import {withTranslation} from 'react-i18next';

class ServiceSelectionInternal extends React.Component {
    title = `1. ${this.props.t('serviceSelection.title')}`;

    services = [
        {
            key: 'optimization',
            name: this.props.t('serviceSelection.services.optimization.title'),
            src: optimizationIcon,
            text: this.props.t('serviceSelection.services.optimization.text'),
        },
        {
            key: 'migration',
            name: this.props.t('serviceSelection.services.migration.title'),
            src: migrationIcon,
            text: this.props.t('serviceSelection.services.migration.text'),
        },
        {
            key: 'design',
            name: this.props.t('serviceSelection.services.design.title'),
            src: designIcon,
            text: this.props.t('serviceSelection.services.design.text'),
        },
        {
            key: 'advice',
            name: this.props.t('serviceSelection.services.advice.title'),
            src: adviceIcon,
            text: this.props.t('serviceSelection.services.advice.text'),
        },
    ];

    getSelectedService = () => this.services.find((service) => service.key === this.props.selectedService);

    onNextClick = () => {
        const selectedService = this.getSelectedService();
        this.props.onServiceSelect && this.props.onServiceSelect(selectedService);
    };

    render() {
        const selectedService = this.getSelectedService();
        return (
            <InquiryForm
                title={this.title}
                sliderSettings={this.props.sliderSettings}
                onNextClick={this.onNextClick}
            >
                <Grid container item className='my-4' xs={12}>
                    <Dropdown
                        selectedItem={selectedService || this.services[0]}
                        items={this.services}
                        onChange={(service) => this.props.onChange(service.key)}
                    />
                    <Grid item xs={12} lg={6} className='mt-3 mt-xl-0 ml-xl-3'>
                        <ContinueButton t={this.props.t} onClick={this.onNextClick}/>
                    </Grid>
                </Grid>
                <Grid item xs={12} className='my-3'>
                    <ServiceDescription selectedService={selectedService}/>
                </Grid>
            </InquiryForm>
        );
    }
}

export const ServiceSelection = withTranslation('inquiry')(ServiceSelectionInternal);