import React from 'react';
import {Grid} from '@material-ui/core';
import ContactCard from '../../../components/contactCard/contactCard';
import InfoCard from '../../../components/infoCard/infoCard';
import {mirek, radim, sandra} from '../../../config/contactsInfo';

class ContactCards extends React.Component {

    largeBreakpointWidth = 1370;
    smallBreakpointWidth = 750;

    state = {
        screenWidth: window.innerWidth,
    };

    get targetContent() {
        const {screenWidth} = this.state;
        if (screenWidth > this.largeBreakpointWidth) {
            return this.renderLargeSize();
        }
        if (screenWidth < this.largeBreakpointWidth && screenWidth > this.smallBreakpointWidth) {
            return this.renderOtherSize();
        }
        if (screenWidth < this.smallBreakpointWidth) {
            return this.renderOtherSize(true);
        }
        return this.renderOtherSize();
    }

    render() {
        const {t} = this.props;
        return (
            <Grid container item className='background-1 contact-card-block p-4'>
                <Grid container item>
                    <Grid item xs={12}>
                        <p className='text-31'>{t('contactCards.title')}</p>
                        <p className='text-31-sub-title'>{t('contactCards.subTitle')}</p>
                    </Grid>
                    {this.targetContent}
                </Grid>
            </Grid>
        );
    }


    renderLargeSize = () => {
        const {t} = this.props;
        return (
            <React.Fragment>
                <div className='row col-12 justify-content-center'>
                    <InfoCard
                        title={t('contactCards.firstBox.title')}
                        text={t('contactCards.firstBox.text')}
                        className='card-top-left'
                    />
                    <ContactCard
                        image={mirek.image}
                        name={t('contactCards.mirek.name')}
                        position={t('contactCards.mirek.position')}
                        info={t('contactCards.mirek.info')}
                        socialMedia={mirek.socialMedia}
                        className='card-horizontal-margin'
                    />
                </div>
                <div className='row col-12 justify-content-center'>
                    <ContactCard
                        image={sandra.image}
                        name={t('contactCards.sandra.name')}
                        position={t('contactCards.sandra.position')}
                        info={t('contactCards.sandra.info')}
                        socialMedia={sandra.socialMedia}
                        className='card-middle'
                        swipe
                    />
                </div>
                <div className='row col-12 justify-content-center'>
                    <InfoCard
                        title={t('contactCards.secondBox.title')}
                        text={t('contactCards.secondBox.text')}
                        className='card-bottom-left'
                    />
                    <ContactCard
                        image={radim.image}
                        name={t('contactCards.radim.name')}
                        position={t('contactCards.radim.position')}
                        info={t('contactCards.radim.info')}
                        socialMedia={radim.socialMedia}
                        className='card-horizontal-margin'
                    />
                </div>
            </React.Fragment>
        );
    };

    renderOtherSize = (small) => {
        const {t} = this.props;
        return (
            <Grid container item>
                <Grid item xs={12} className='mb-5'>
                    <ContactCard
                        image={mirek.image}
                        name={t('contactCards.mirek.name')}
                        position={t('contactCards.mirek.position')}
                        info={t('contactCards.mirek.info')}
                        socialMedia={mirek.socialMedia}
                        className='m-auto'
                        small={small}
                    />
                </Grid>
                <Grid item xs={12} className='mb-5'>
                    <InfoCard
                        title={t('contactCards.firstBox.title')}
                        text={t('contactCards.firstBox.text')}
                        className='m-auto'
                        small={small}
                    />
                </Grid>
                <Grid item xs={12} className='mb-5'>
                    <ContactCard
                        image={sandra.image}
                        name={t('contactCards.sandra.name')}
                        position={t('contactCards.sandra.position')}
                        info={t('contactCards.sandra.info')}
                        socialMedia={sandra.socialMedia}
                        className='m-auto'
                        small={small}
                    />
                </Grid>
                <Grid item xs={12} className='mb-5'>
                    <InfoCard
                        title={t('contactCards.secondBox.title')}
                        text={t('contactCards.secondBox.text')}
                        className='m-auto'
                        small={small}
                    />
                </Grid>
                <Grid item xs={12} className='mb-5'>
                    <ContactCard
                        image={radim.image}
                        name={t('contactCards.radim.name')}
                        position={t('contactCards.radim.position')}
                        info={t('contactCards.radim.info')}
                        socialMedia={radim.socialMedia}
                        className='m-auto'
                        small={small}
                    />
                </Grid>
            </Grid>
        );
    };

    componentDidMount() {
        window.addEventListener('resize',
            () => this.setState({screenWidth: window.innerWidth}));
    }

    componentWillUnmount() {
        window.removeEventListener('resize', () => this.setState({screenWidth: window.innerWidth}));
    }
}

export default ContactCards;
