import * as React from 'react';
import {Editor, Frame} from '@craftjs/core';
import {Image} from './components/selectors/image/image';
import {NumberedParagraph} from './components/selectors/numberedParagraph/numberedParagraph';
import {Date as DateSelector} from './components/selectors/date/date';
import {Text} from './components/selectors/text';
import {TextBold} from './components/selectors/textBold';
import {Container} from './components/selectors/container/container';
import {Title} from './components/selectors/title/title';
import {Custom1, OnlyButtons} from './components/selectors/custom1/custom1';
import {Custom2, Custom2VideoDrop} from './components/selectors/custom2/custom2';
import {Custom3, Custom3BtnDrop} from './components/selectors/custom3/custom3';
import {Button} from './components/selectors/button/button';
import {Video} from './components/selectors/video/video';
import {Viewport} from './components/editor/viewport/viewport';
import {RenderNode} from './components/editor/renderNode';
import {CheckmarkText} from "./components/selectors/checkmarkText/checkmarkText";

export const editorResolvers = {
    Container,
    Text,
    TextBold,
    Title,
    DateSelector,
    NumberedParagraph,
    CheckmarkText,
    Custom1,
    Custom2,
    Custom2VideoDrop,
    Custom3,
    Custom3BtnDrop,
    OnlyButtons,
    Button,
    Video,
    Image,
}

class CustomEditor extends React.Component<any, {}> {

    get resolvers() {
        return editorResolvers;
    }

    render() {
        const {content} = this.props;
        return (
            <div className='h-full h-screen'>
                <Editor
                    enabled
                    resolver={this.resolvers}
                    onRender={RenderNode}
                >
                    <Viewport {...this.props}>
                        <Frame data={content}>
                        </Frame>
                    </Viewport>
                </Editor>
            </div>
        );
    }
}

export default CustomEditor;