import React from 'react';

class ReferenceTableHeader extends React.Component {
    render() {
        return (
            <thead>
            <tr>
                {this.props.columns.map((item, index) => {
                    return (
                        <th key={index.toString()} className='text-table-head' scope='col'>
                            {item.name}
                        </th>
                    );
                })}
            </tr>
            </thead>
        );
    }
}

export default ReferenceTableHeader;